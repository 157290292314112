import styled from 'styled-components'

export const StyledNewCreditFlow = styled.div`
  position: relative;
  width: 100%;
  max-width: var(--wd-layout);
`

export const StyledStepForm = styled.form`
  position: relative;
  width: 100%;
  max-width: var(--wd-layout);
`
