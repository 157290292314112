import React, { Fragment, useState } from 'react'
import Step from '@mui/material/Step'
import { StyledNewCreditFlow } from './styled'
import {
  StyledStepper,
  StyledStepButton,
} from '../../components/styled/Stepper'
import BankCredits from './sections/BankCredits'
import OtherDocuments from './sections/OtherDocuments'
import Completed from './sections/Completed'
import { useForm } from '../../providers/Form'
import { useParams } from 'react-router-dom'
import { getClient } from '../../services/clients'
import { ClientResponse } from '../../types/clients'
import { useQuery } from 'react-query'
import Heading from '../../components/atoms/Heading'
import Container from '../../components/atoms/Container'
import Spinner from '../../components/atoms/Spinner'
import { StyledDescription } from '../../components/styled/Description'

type StepElement = {
  text: string
  component: React.FC<any>
}

type NewCreditFlowProps = {
  headline?: string
}

const NewCreditFlow: React.FC<NewCreditFlowProps> = () => {
  const { clientCode } = useParams()
  const { newCreditForm, setNewCreditForm } = useForm()
  const [activeStep, setStep] = useState(0)
  const [flowCompleted, setFlowCompleted] = useState(false)
  const [completed, setCompleted] = React.useState<{
    [s: number]: boolean
  }>({})

  const { data: client, isLoading } = useQuery<ClientResponse>(
    ['getClient', clientCode],
    () => getClient(clientCode!),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setNewCreditForm!({
          ...newCreditForm,
          clientInformation: data,
        })
      },
    }
  )

  const steps: StepElement[] = [
    { text: 'Solicitudes de Banco', component: BankCredits },
    { text: 'Otros Documentos', component: OtherDocuments },
  ]

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true

    if (newCreditForm?.bankCredits && newCreditForm?.analisisDocuments) {
      setFlowCompleted(true)
      setCompleted(newCompleted)
    }
  }

  const handleChangeStep = (step: number) => {
    setStep(step)
  }

  const saveData = (data: any) => {
    setNewCreditForm!({
      ...(newCreditForm ? newCreditForm : {}),
      ...data,
    })
  }

  const handleNextStep = (data: any) => {
    saveData(data)
    if (activeStep === steps.length - 1) {
      handleComplete()
    } else if (activeStep < steps.length - 1) {
      setStep(activeStep + 1)
    }
  }

  const handlePrevStep = (data: any) => {
    saveData(data)
    if (activeStep > 0) {
      handleChangeStep(activeStep - 1)
    }
  }

  const CurrentStep = steps[activeStep].component

  if (isLoading || !client) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando información de cliente.</StyledDescription>
      </Container>
    )
  }

  return (
    <StyledNewCreditFlow>
      {flowCompleted ? (
        <Completed />
      ) : (
        <Fragment>
          <Heading type="2">{`${client!.name}`}</Heading>
          <StyledStepper activeStep={activeStep}>
            {steps.map((stepData: StepElement, index: number) => {
              return (
                <Step key={stepData.text} completed={completed[index]}>
                  <StyledStepButton onClick={() => handleChangeStep(index)}>
                    {stepData.text}
                  </StyledStepButton>
                </Step>
              )
            })}
          </StyledStepper>
          <CurrentStep
            continue={handleNextStep}
            back={handlePrevStep}
            save={saveData}
          />
        </Fragment>
      )}
    </StyledNewCreditFlow>
  )
}

export default NewCreditFlow
