import styled from 'styled-components'
import Stepper from '@mui/material/Stepper'
// import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'

export const StyledStepper = styled(Stepper)`
  margin-bottom: var(--spc-sm);
`

export const StyledStepButton = styled(StepButton)`
  .Mui-active {
    svg {
      color: var(--color-red-2);
    }
  }

  .Mui-completed {
    svg {
      color: var(--color-green-2);
    }
  }

  .Mui-active.Mui-completed {
    &.MuiStepLabel-label {
      font-weight: bold;
      padding-bottom: 3px;
      border-bottom: 2px solid var(--color-gray-2);
    }

    svg {
      color: var(--color-green-1);
    }
  }
`
