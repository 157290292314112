import Spanish from './es.json'
import flatten from 'flat'

export type LanguagesMap = {
  [language: string]: {
    [key: string]: string
  }
}

const Languages: LanguagesMap = {
  es: flatten(Spanish),
}

export default Languages
