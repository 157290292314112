import styled from 'styled-components'
import { Dialog } from '@mui/material'

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    overflow: initial;
  }
`

export const StyledDialogContent = styled.div`
  padding: 20px;
  min-width: 360px;
`
