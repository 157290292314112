const FilePlus: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M7 10.5a1 1 0 00-1-1H4.75a.25.25 0 01-.25-.25V8a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H1a1 1 0 000 2h1.25a.25.25 0 01.25.25V13a1 1 0 002 0v-1.25a.25.25 0 01.25-.25H6a1 1 0 001-1z"></path>
      <path d="M13.78 4L10 .22A.75.75 0 009.5 0H3.75A1.76 1.76 0 002 1.75v3.8a.24.24 0 00.12.21.23.23 0 00.24 0 2.58 2.58 0 01.91-.26.25.25 0 00.23-.25v-3.5a.25.25 0 01.25-.25h5.34a.26.26 0 01.17.07l3.17 3.17a.26.26 0 01.07.17v7.34a.25.25 0 01-.25.25H7.58a.24.24 0 00-.14.05 2.57 2.57 0 01-1.22.45.26.26 0 00-.23.23 2.33 2.33 0 01-.08.46.22.22 0 000 .22.25.25 0 00.2.1h6.1A1.76 1.76 0 0014 12.25V4.5a.75.75 0 00-.22-.5z"></path>
    </svg>
  )
}

export default FilePlus
