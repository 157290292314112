import PillCell from '../components/organisms/Table/Cells/Pill'

export const BasicAllocationColumns = [
  {
    name: 'Private Step',
    key: 'step',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Is Completed',
    key: 'privateIsCompleted',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private ID',
    key: 'id',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Estatus',
    key: 'isCompleted',
    component: PillCell,
  },
  {
    name: 'ID',
    key: 'visualId',
    width: '6rem',
  },
  {
    name: 'Asesora',
    key: 'user',
    width: '10rem',
  },
  {
    name: 'Cliente',
    key: 'client',
    width: '12rem',
  },
  {
    name: 'Monto',
    key: 'allocated',
    width: '10rem',
    sort: true,
    sortFunction: (a: any, b: any) => {
      const allocatedA = parseFloat(a.allocated.replace(/[$|,]/g, ''))
      const allocatedB = parseFloat(b.allocated.replace(/[$|,]/g, ''))
      return allocatedA > allocatedB ? 1 : -1
    },
  },
  {
    name: 'Banco',
    key: 'bank',
    width: '6rem',
  },
  {
    name: 'Forma de Contacto',
    key: 'contactForm',
    width: '8rem',
  },
  {
    name: 'Fecha de Firma',
    key: 'signatureDate',
    width: '8rem',
  },
  {
    name: 'Fecha Fondeo',
    key: 'fondeoDate',
    width: '8rem',
  },
  {
    name: 'Fecha Documentos',
    key: 'documentsDate',
    width: '8rem',
    omit: true,
  },
  {
    name: 'Notaria',
    key: 'notaria',
    width: '8rem',
  },
]
