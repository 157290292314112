import styled from 'styled-components'

export const StyledListContainer = styled.div`
  width: 100%;
  border: 1px solid var(--color-gray-5);
  border-radius: 10px;
  border-bottom: 4px solid var(--color-gray-5);
  margin-bottom: 0.5rem;
`

export const StyledList = styled.ul`
  position: relative;
  background-color: var(--color-white);
  list-style: none;
  border-radius: 10px;
  padding: var(--spc-xs) var(--spc-sm);
`

export const StyledListItem = styled.li`
  display: flex;
  justify-content: flex-start;
`
