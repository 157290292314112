import Config from '../config/environment'
import {
  Credentials,
  Passwords,
  UserLoginResponse,
  UserLogoutResponse,
} from '../types/auth'

const { REST_API_URL } = Config

export const changeUserPassword = async function (payload: Passwords) {
  const changePasswordResponse = await fetch(`${REST_API_URL}/auth/password`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  if (changePasswordResponse.status !== 204) {
    const data = await changePasswordResponse.json()
    if ('error' in data) {
      throw new Error(data.error.message)
    }
  }
  return {
    passwordChanged: true,
  }
}

export const loginUser = async function (payload: Credentials) {
  const loginResponse = await fetch(`${REST_API_URL}/auth/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = (await loginResponse.json()) as UserLoginResponse
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const logoutUser = async function () {
  const logoutResponse = await fetch(`${REST_API_URL}/auth/logout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const data = (await logoutResponse.json()) as UserLogoutResponse
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}
