import Config from '../config/environment'

const { REST_API_URL } = Config

export const getFollowUp = async function ({ showAll }: { showAll?: boolean }) {
  const filter = {
    isCompleted: 'false',
    isActive: 'true',
    showAll: showAll !== undefined ? showAll.toString() : 'false',
  }

  const queryParams = new URLSearchParams(filter).toString()
  const creditsReponse = await fetch(
    `${REST_API_URL}/credits/?${queryParams}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await creditsReponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}
