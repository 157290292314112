import React from 'react'
import { BarLoader, FadeLoader, DotLoader } from 'react-spinners'
import { StyledSpinner } from './styled'

export type SpinnerProps = {
  color?: string
  type: 'fade' | 'bar' | 'dot'
}

const Spinners = {
  fade: FadeLoader,
  bar: BarLoader,
  dot: DotLoader,
}

const Spinner: React.FC<SpinnerProps> = (props) => {
  const Spinner = Spinners[props.type]

  return (
    <StyledSpinner>
      <Spinner color={props.color || `var(--color-red-2)`} />
    </StyledSpinner>
  )
}

export default Spinner
