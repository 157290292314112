import { useLayoutEffect, useRef, useState } from 'react'
import Container from '../../components/atoms/Container'
import Header from '../../components/organisms/Header'
import Config from '../../config/environment'
import { StyledLayout, StyledContent, StyledLayoutBody } from './styled'
import { ReactContainerElement } from '../../types/react'
import SideBar from '../../components/organisms/SideBar'
import { Icons } from '../../types/enums'
import { getRolePages } from '../../utils/pages'
import LogoutPage from '../../pages/Logout'
import { useUser } from '../../providers/User'
import { useSearchParams } from 'react-router-dom'

const { INTERNAL_URL } = Config

const Layout = (props: ReactContainerElement) => {
  const { user } = useUser()
  const [searchParams] = useSearchParams()

  const showLayout = searchParams.get('layout')

  const headerRef = useRef<HTMLDivElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  useLayoutEffect(() => {
    setHeaderHeight(headerRef.current?.clientHeight || 100)
  })

  const pagesConfig = []
  if (user) {
    pagesConfig.push(...getRolePages(user.role))
  }

  const bottomItems = [
    {
      icon: Icons.LOGOUT_CIRCLE,
      label: 'Cerrar Sesión',
      path: '/logout',
      component: LogoutPage,
    },
  ]

  if (showLayout === 'false') {
    return <StyledLayout>{props.children}</StyledLayout>
  }

  return (
    <StyledLayout>
      <div ref={headerRef}>
        <Header
          profileWidget={{
            name: user ? user.name : '...',
            avatar: user?.avatar || '',
            link: '/perfil',
          }}
          logo={{
            link: '/',
            src: `${INTERNAL_URL}/assets/logo.svg`,
          }}
        />
      </div>
      <StyledContent header={headerHeight}>
        <SideBar items={pagesConfig} bottomItems={bottomItems} />
        <StyledLayoutBody>
          <Container align="left" padding>
            {props.children}
          </Container>
        </StyledLayoutBody>
      </StyledContent>
    </StyledLayout>
  )
}

export default Layout
