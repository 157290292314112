import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import html2canvas from 'html2canvas'
import { exportComponentAsPNG } from 'react-component-export-image'
import {
  StyledAuthorizationCard,
  StyledAuthorizationClientName,
  StyledAuthorizationContainer,
  StyledAuthorizationData,
  StyledAuthorizationFooter,
  StyledAuthorizationMonto,
  StyledAuthorizationText,
  StyledAuthorizationTitle,
  StyledAuthorizedIcon,
} from './styled'
import { ReactBaseElement } from '../../types/react'
import Container from '../../components/atoms/Container'
import { useQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { getCredit, sendCreditAuthorization } from '../../services/credits'
import Spinner from '../../components/atoms/Spinner'
import { StyledDescription } from '../../components/styled/Description'
import Config from '../../config/environment'
import { BankCreditDetail } from '../../types/credits'
import PrintContainer from '../../components/atoms/PrintContainer'
import autorizationSign from '../../design/assets/images/autorization-sign.png'
import logoIcon from '../../design/assets/images/logo.svg'
import { formatCurrency } from '../../utils/currency'
import Button from '../../components/molecules/Button'
import { useAlert } from '../../providers/Alert'

type AuthorizationCardProps = ReactBaseElement
const { SITE_ADDRESS, SITE_CONTACT_INFO } = Config

const AuthorizationCard: React.FC<AuthorizationCardProps> = () => {
  const { code } = useParams()
  const [searchParams] = useSearchParams()
  const { setAlert } = useAlert()
  const printableComponent = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => printableComponent.current,
  })

  const { data: credit, isLoading } = useQuery<BankCreditDetail>(
    ['getCredit', code],
    () => getCredit(code!),
    {
      refetchOnWindowFocus: false,
    }
  )

  const fileName = `carta-autorizacion-${credit?.bank.name}-${credit?.client.name}`
  const shouldHideActions = searchParams.get('hideActions') !== 'true'

  const handleExportToImg = () => {
    exportComponentAsPNG(printableComponent, {
      fileName: fileName,
    })
  }

  const handleSend = () => {
    const html = document.getElementsByTagName('HTML')[0] as HTMLElement
    const body = document.getElementsByTagName('BODY')[0] as HTMLBodyElement
    let htmlWidth = html.clientWidth
    let bodyWidth = body.clientWidth
    const data = printableComponent.current!
    const newWidth = data.scrollWidth - data.clientWidth
    if (newWidth > data.clientWidth) {
      htmlWidth += newWidth
      bodyWidth += newWidth
    }
    html.style.width = htmlWidth + 'px'
    body.style.width = bodyWidth + 'px'
    html2canvas(data).then((canvas) => {
      const image = canvas.toDataURL('image/png', 1.0)
      sendCreditAuthorization(credit!.code, image)
      setAlert!({
        severity: 'success',
        title: 'Enviada',
        description: 'Carta de Autorización ha sido enviada',
      })
    })
  }

  if (isLoading || !credit) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando información de credito.</StyledDescription>
      </Container>
    )
  }

  const expDate = new Date(credit.meta.authorizedExpDate)
  expDate.setDate(expDate.getDate() + 1)

  let creditType
  if (credit.meta.authorizedCreditType) {
    creditType = credit.meta.authorizedCreditType.name
  } else {
    creditType = credit.meta.creditType.name
  }

  let productType
  if (credit.meta.authorizedProductType) {
    productType = credit.meta.authorizedProductType.name
  } else {
    productType = credit.meta.productType.name
  }

  let subProducto
  if (credit.meta.authorizedProductSubtype) {
    subProducto = credit.meta.authorizedProductSubtype
  }

  return (
    <StyledAuthorizationCard>
      {shouldHideActions && (
        <Container direction="row" padding align="center">
          <Button onClick={handlePrint}>IMPRIMIR / PDF</Button>
          <Button onClick={handleExportToImg}>DESCARGAR IMAGEN</Button>
          <Button onClick={handleSend}>ENVIAR POR CORREO</Button>
        </Container>
      )}
      <Container align="center">
        <StyledAuthorizationContainer ref={printableComponent}>
          <PrintContainer>
            <Container align="center">
              <StyledAuthorizedIcon src={logoIcon} />
              <StyledAuthorizationTitle>
                CRÉDITO AUTORIZADO
              </StyledAuthorizationTitle>
              <StyledAuthorizationClientName>
                {credit.client.name}
              </StyledAuthorizationClientName>
              <StyledAuthorizationText>
                MORE CONSULTORES LO FELICITA POR LA AUTORIZACIÓN DE SU CRÉDITO
                HIPOTECARIO Y SE COMPLACE EN SER PARTE IMPORTANTE DE LA
                CONSOLIDACIÓN DE SU PATRIMONIO FAMILIAR
              </StyledAuthorizationText>
              <StyledAuthorizationText>
                A CONTINUACIÓN LE PROPORCIONAMOS INFORMACIÓN IMPORTANTE RELATIVA
                A SU CRÉDITO
              </StyledAuthorizationText>

              <StyledAuthorizationData>
                <tbody>
                  <tr>
                    <td>INSTITUCIÓN</td>
                    <td>{credit.bank.name.toUpperCase()}</td>
                  </tr>
                  <tr>
                    <td>TIPO DE CRÉDITO</td>
                    <td>{creditType.toUpperCase()}</td>
                  </tr>
                  <tr>
                    <td>PLAZO EN AÑOS</td>
                    <td>{credit.meta.authorizedTerms}</td>
                  </tr>
                  <tr>
                    <td>TASA</td>
                    <td>{credit.meta.authorizedTasa}</td>
                  </tr>
                  <tr>
                    <td>PRODUCTO</td>
                    <td>{productType.toUpperCase()}</td>
                  </tr>
                  {subProducto && (
                    <tr>
                      <td>SUB PRODUCTO</td>
                      <td>{subProducto.toUpperCase()}</td>
                    </tr>
                  )}
                  {credit.meta.authorizedPromotion === 'Si' && (
                    <tr>
                      <td>PROMOCIÓN</td>
                      <td>
                        {credit.meta.authorizedPromotionType.toUpperCase()}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>FINANCIAMIENTO</td>
                    <td>{credit.meta.authorizedAforo} %</td>
                  </tr>
                  <tr>
                    <td>VIGENCÍA</td>
                    <td>
                      {expDate
                        .toLocaleDateString('es-MX', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })
                        .toUpperCase()}
                    </td>
                  </tr>
                </tbody>
              </StyledAuthorizationData>

              <StyledAuthorizationMonto>
                MONTO AUTORIZADO
              </StyledAuthorizationMonto>
              <StyledAuthorizationMonto>
                {formatCurrency(credit.meta.authorizedCredit)}
              </StyledAuthorizationMonto>
              <StyledAuthorizedIcon src={autorizationSign} />
              <StyledAuthorizationFooter>
                <p>{SITE_ADDRESS.toUpperCase()}</p>
                <p>{SITE_CONTACT_INFO.toUpperCase()}</p>
                <span>
                  <a href="https://www.moreconsultores.com.mx" target="_blank">
                    MoreConsultores.com.mx
                  </a>
                </span>
              </StyledAuthorizationFooter>
            </Container>
          </PrintContainer>
        </StyledAuthorizationContainer>
      </Container>
    </StyledAuthorizationCard>
  )
}

export default AuthorizationCard
