import styled from 'styled-components'

export type LogoProps = {
  src: string
}

export const StyledLogo = styled.div<LogoProps>`
  width: 3rem;
  height: 3rem;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`
