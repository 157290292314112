import React from 'react'
import { StyledIcon } from './styled'
import { IconKeys } from './Icons'
import { ReactBaseElement } from '../../../types/react'

export type IconProps = {
  name: string
  color?: string
  title?: string
  onClick?: () => void
} & ReactBaseElement

const Icon: React.FC<IconProps> = (props) => {
  const SVGIcon = IconKeys[props.name]
  return (
    <StyledIcon
      color={props.color}
      className={props.className}
      onClick={props.onClick}
      actionable={Boolean(props.onClick)}
      title={props.title}
    >
      <SVGIcon />
    </StyledIcon>
  )
}

export default Icon
