import { Alert, AlertTitle } from '@mui/material'
import React, { createContext, useContext, useEffect, useState } from 'react'

type Alert = {
  title: string
  description: React.ReactNode
  severity: 'error' | 'warning' | 'info' | 'success'
}

type AlertContextValue = {
  setAlert: (state: React.SetStateAction<Alert | null>) => void
}

export const AlertContext = createContext<Partial<AlertContextValue>>({})

type ProviderProps = {
  children: React.ReactNode
}

const AlertProvider: React.FC<ProviderProps> = ({ children }) => {
  const [alert, setAlert] = useState<Alert | null>(null)

  const AlertValue: AlertContextValue = {
    setAlert,
  }

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null)
      }, 3000)
    }
  }, [alert])

  return (
    <AlertContext.Provider value={AlertValue}>
      {children}
      {alert && (
        <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <Alert severity={alert?.severity}>
            <AlertTitle>{alert?.title}</AlertTitle>
            {alert?.description}
          </Alert>
        </div>
      )}
    </AlertContext.Provider>
  )
}

export const useAlert = () => {
  return useContext(AlertContext)
}

export default AlertProvider
