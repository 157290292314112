import styled from 'styled-components'

export const StyledSelectContainer = styled.div<{
  disabled?: boolean
}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spc-sm);
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;

  opacity: ${(props) => (props.disabled ? '0.2' : 1)};
`

export const StyledDropdownContainer = styled.div<{
  open: boolean
}>`
  position: relative;
  flex: 2;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: var(--spc-xs);
  outline: none;

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    width: 0.5rem;
    height: 0.5rem;
    transition: all ease 0.5s;
    top: ${(props) => (props.open ? '15px' : '10px')};
    transform: ${(props) => (props.open ? 'rotate(135deg)' : 'rotate(315deg)')};
    border-bottom: 3px solid;
    border-left: 3px solid;
    border-color: var(--color-gray-2);
    cursor: pointer;
  }
`

export const StyledDropdownSelected = styled.input<{
  empty: boolean
}>`
  padding: var(--spc-xs) var(--spc-sm);
  font-size: var(--fs-sm);
  min-height: var(--spc-xs);
  border: 1px solid;
  border-color: var(--color-gray-5);
  border-radius: 3px;
  outline: none;
  border-bottom: 3px solid;
  border-bottom-color: var(--color-gray-5);
  cursor: pointer;

  ${(props) => {
    return !props.empty
      ? `
      border-left: 2px solid;
      border-left-color: var(--color-green-2);
    `
      : `
      &:required {
        border-left: 2px solid;
        border-left-color: var(--color-red-2);
      }
      `
  }}

  &:focus {
    border-bottom-color: var(--color-gray-2);
  }
`

export const StyledDropdownList = styled.ul`
  position: relative;
  list-style: none;
  position: absolute;
  top: 2rem;
  background-color: white;
  width: calc(100%);
  border-radius: 5px;
  border: 1px solid;
  border-color: var(--color-gray-5);
  border-bottom: 5px solid;
  border-bottom-color: var(--color-gray-2);
  max-height: 250px;
  overflow: scroll;
  z-index: 100;

  li {
    font-size: var(--fs-sm);
    padding: 0.5rem;

    &:hover {
      background-color: var(--color-red-5);
      cursor: pointer;
    }
  }
`

export const StyledLabel = styled.span`
  padding: var(--spc-xs) 0;
  line-height: var(--spc-sm);
  font-size: var(--fs-sm);
  font-weight: bold;
  flex: 1;
`
