import { AllocationRow } from '../types/allocations'
import { BankCreditListItem } from '../types/credits'

export function parseAllocationForTable(
  credits: BankCreditListItem[]
): AllocationRow[] {
  const allocations = credits.map((credit) => {
    const pesosFormatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })

    let creditAmount
    if (credit.meta.finalCreditAmount) {
      creditAmount = parseFloat(credit.meta.finalCreditAmount)
    } else if (credit.meta.formalizacionMontoCredito) {
      creditAmount = credit.meta.formalizacionMontoCredito
    } else if (credit.meta.authorizedCredit) {
      creditAmount = credit.meta.authorizedCredit
    } else {
      creditAmount = credit.meta.creditRequested
    }

    return {
      id: `${credit.code}`,
      step: `${credit.phase} / ${credit.step}`,
      visualId: `CR-${credit.code}`,
      client: credit.client.name.toUpperCase(),
      allocated: pesosFormatter.format(creditAmount),
      user: credit.owners.advisor.displayName,
      bank: credit.bank.name,
      contactForm: credit.client.meta.contactForm.name,
      notaria: credit.meta.formalizacionNotaria.name,
      privateIsCompleted: credit.isCompleted,
      isCompleted: {
        text: credit.isCompleted ? 'Completado' : 'Pendiente',
        color: credit.isCompleted
          ? 'var(--color-green-2)'
          : 'var(--color-red-2)',
      },
      fondeoDate: credit.meta.fundingDate
        ? new Date(`${credit.meta.fundingDate} 12:00:00`).toLocaleDateString(
            'es-MX'
          )
        : '--',
      signatureDate: credit.meta.finalSignatureDate
        ? new Date(
            `${credit.meta.finalSignatureDate} 12:00:00`
          ).toLocaleDateString('es-MX')
        : '--',
      documentsDate: credit.meta.documentsDate
        ? new Date(`${credit.meta.documentsDate} 12:00:00`).toLocaleDateString(
            'es-MX'
          )
        : '--',
    }
  })
  return allocations
}

export function getConditionalStyles() {
  return [
    {
      when: (row: AllocationRow) => !row.privateIsCompleted,
      style: {
        backgroundColor: 'var(--color-red-5)',
      },
    },
  ]
}
