import { StyledActiveCell } from './styled'

type CellProps = {
  data: {
    text: string
    active: boolean
  }
}

const ActiveCell = (props: CellProps) => {
  if (!props.data) {
    return <span>--</span>
  }

  return (
    <StyledActiveCell active={props.data.active}>
      {props.data.text}
    </StyledActiveCell>
  )
}

export default ActiveCell
