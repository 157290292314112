import React from 'react'
import LoginForm from '../../templates/LoginForm'
import BackgroundLayout from '../../templates/BackgroundLayout'
import { StyledSpace } from '../../components/styled/Space'

const AuthPage: React.FC = () => {
  return (
    <BackgroundLayout>
      <StyledSpace />
      <LoginForm />
    </BackgroundLayout>
  )
}

export default AuthPage
