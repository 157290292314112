import { CreditSteps, Owners } from '../types/enums'

export const STEPS_OWNERS = {
  [CreditSteps.PrequalificationRequest]: Owners.PrequalificationOperator,
  [CreditSteps.PrequalificationDocuments]: Owners.Advisor,
  [CreditSteps.Phase1Assignation]: Owners.Phase1Operator,
  [CreditSteps.Phase1Analysis]: Owners.Phase1Operator,
  [CreditSteps.Phase1Inconsistency]: Owners.Advisor,
  [CreditSteps.Phase1Rejected]: Owners.Advisor,
  [CreditSteps.Phase1Authorized]: Owners.Phase1Operator,
  [CreditSteps.Phase1AppraisalDocuments]: Owners.Advisor,
  [CreditSteps.Phase1MissingOriginales]: Owners.Advisor,
  [CreditSteps.Phase2AppraisalRequest]: Owners.Phase2Operator,
  [CreditSteps.Phase2AppraisalInProgress]: Owners.Phase2Operator,
  [CreditSteps.Phase2AppraisalClosed]: Owners.Phase2Operator,
  [CreditSteps.Phase3FormalizationRequest]: Owners.Advisor,
  [CreditSteps.Phase3ClosingValues]: Owners.Phase3Operator,
  [CreditSteps.Phase3SignatureDate]: Owners.Advisor,
  [CreditSteps.Phase3SignatureKit]: Owners.Phase3Operator,
  [CreditSteps.Phase3SignatureReady]: Owners.Advisor,
  [CreditSteps.Phase3Fondeo]: Owners.Phase3Operator,
  [CreditSteps.Phase3Escriturado]: Owners.Phase3Operator,
  [CreditSteps.Phase3Completed]: Owners.Phase3Operator,
}
