import Config from '../config/environment'
import {
  BankCreditPayload,
  BankCreditDocument,
  BankCreditUpdate,
  BankCreditHistoryPayload,
} from '../types/credits'

const { REST_API_URL } = Config

export const getCredit = async function (code: string) {
  const creditResponse = await fetch(`${REST_API_URL}/credits/${code}`, {
    method: 'GET',
    credentials: 'include',
  })
  const data = await creditResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const addCredit = async function (credit: BankCreditPayload) {
  const creditResponse = await fetch(`${REST_API_URL}/credits`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credit),
  })
  const data = await creditResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const updateCredit = async function (
  credit: number,
  payload: BankCreditUpdate
) {
  const updatedCredit = await fetch(`${REST_API_URL}/credits/${credit}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })

  if (updatedCredit.status !== 204) {
    const data = await updatedCredit.json()
    if ('error' in data) {
      throw new Error(data.error.message)
    }
  }
  return {
    creditUpdated: true,
  }
}

export const addCreditDocument = async function (
  credit: number,
  document: BankCreditDocument
) {
  const creditDocument = await fetch(
    `${REST_API_URL}/credits/${credit}/documents`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(document),
    }
  )
  const data = await creditDocument.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const addCreditHistory = async function (
  credit: number,
  history: BankCreditHistoryPayload
) {
  const creditDocument = await fetch(
    `${REST_API_URL}/credits/${credit}/history`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(history),
    }
  )
  const data = await creditDocument.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const sendCreditAuthorization = async function (
  credit: number,
  authorization: string
) {
  const creditAuthorization = await fetch(
    `${REST_API_URL}/credits/${credit}/authorization-email`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        image: authorization,
      }),
    }
  )
  const data = await creditAuthorization.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}
