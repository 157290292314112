import { createContext, useContext, useState } from 'react'
import { useMutation } from 'react-query'
import { changeUserPassword, loginUser, logoutUser } from '../services/auth'
import {
  ChangePasswordFunction,
  LoginFunction,
  LogoutFunction,
} from '../types/auth'
import { LOCAL_AUTH_KEY } from '../utils/contants'
import { useUser } from './User'

type AuthContextValue = {
  isAuth?: boolean

  isTryingToLogIn?: boolean
  isChangingPassword?: boolean
  isLoggingOut?: boolean

  changePasswordFailed?: boolean
  loginFailed?: boolean

  passwordChanged?: boolean

  login: LoginFunction
  logout: LogoutFunction
  changePassword: ChangePasswordFunction
}

export const AuthContext = createContext<Partial<AuthContextValue>>({})

type ProviderProps = {
  children: React.ReactNode
}

const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
  const localAuthSession = window.localStorage.getItem(LOCAL_AUTH_KEY)
  const [isAuth, setIsAuth] = useState(Boolean(localAuthSession))
  const { fetchUser, resetUser } = useUser()

  const {
    isLoading: loginLoading,
    isError: loginError,
    mutate: loginHandler,
  } = useMutation(loginUser, {
    onSuccess: () => {
      window.localStorage.setItem(LOCAL_AUTH_KEY, 'true')
      setIsAuth(true)
      fetchUser!()
    },
  })

  const { isLoading: logoutLoading, mutate: logoutHandler } = useMutation(
    logoutUser,
    {
      onSuccess: () => {
        setIsAuth(false)
        resetUser!()
        window.localStorage.removeItem(LOCAL_AUTH_KEY)
      },
    }
  )

  const {
    isLoading: changePasswordLoading,
    isError: changePasswordError,
    isSuccess: passwordChanged,
    mutate: changePasswordHandler,
  } = useMutation(changeUserPassword)

  const authValue: AuthContextValue = {
    // Data
    isAuth: isAuth,

    // Loaders
    isTryingToLogIn: loginLoading,
    isLoggingOut: logoutLoading,
    isChangingPassword: changePasswordLoading,

    // Errors
    loginFailed: loginError,
    changePasswordFailed: changePasswordError,

    // Success:
    passwordChanged,

    // Actions
    login: loginHandler,
    logout: logoutHandler,
    changePassword: changePasswordHandler,
  }

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export default AuthProvider
