import styled from 'styled-components'

export const StyledHistory = styled.div`
  display: flex;
  width: 100%;
  overflow: scroll;
  max-height: 450px;
`

export const StyledTimeline = styled.ul`
  position: relative;
  list-style: none;
  flex: 1;
`

export const StyledEvent = styled.li<{
  type: string
}>`
  position: relative;
  padding-left: 20px;
  padding-bottom: 0.5rem;

  &::before {
    display: block;
    content: '';
    width: ${(props) => (props.type === 'primary' ? '15px' : '11px')};
    height: ${(props) => (props.type === 'primary' ? '15px' : '11px')};
    border-radius: 50%;
    background-color: white;

    ${(props) =>
      props.type === 'primary'
        ? `background-color: ${props.color}`
        : `border: 2px solid ${props.color}`};
    position: absolute;
    left: 0;
    z-index: 1;
  }

  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      background-color: silver;
      position: absolute;
      left: 7px;
      top: 0;
      z-index: 0;
    }
  }
`

export const StyledEventData = styled.div`
  display: inline-block;
`

export const StyledEventTitle = styled.div`
  font-size: var(--fs-md);
`

export const StyledEventDate = styled.div`
  font-size: var(--fs-sm);
  color: var(--color-gray-3);
`

export const StyledEventImage = styled.div`
  display: inline-block;
  margin-right: 1rem;
`
