const FileAttachment: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M9.5 14a2 2 0 01-2-2V8.75a3.25 3.25 0 016.5 0v3.5a.75.75 0 01-1.5 0v-3.5a1.75 1.75 0 00-3.5 0V12a.5.5 0 001 0V9.25a.75.75 0 011.5 0V12a2 2 0 01-2 2z"></path>
      <path d="M6.09 12.7a.26.26 0 00-.25-.2H1.75a.25.25 0 01-.25-.25V1.75a.25.25 0 01.25-.25h5.34a.26.26 0 01.17.07L9.69 4a.24.24 0 00.23.07 5.69 5.69 0 01.83-.07h.41a.23.23 0 00.25-.14.24.24 0 000-.28L8 .22A.75.75 0 007.5 0H1.75A1.76 1.76 0 000 1.75v10.5A1.76 1.76 0 001.75 14h4.44a.25.25 0 00.22-.37 3.3 3.3 0 01-.32-.93z"></path>
    </svg>
  )
}

export default FileAttachment
