import React from 'react'
import Heading from '../../components/atoms/Heading'
import ClientsTable from '../../templates/ClientsTable'
import Layout from '../../templates/Layout'

const ClientsPage = () => {
  return (
    <Layout>
      <Heading type="3" border color="var(--color-red-2)" margin>
        Clientes
      </Heading>
      <ClientsTable />
    </Layout>
  )
}

export default ClientsPage
