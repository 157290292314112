import { createContext, useContext, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import Config from '../config/environment'
import { getSignedUrls, putWithSignedUrl } from '../services/s3'
import { getUser, updateUser } from '../services/user'
import { User, ChangeAvatarFunction, GetUserFunction } from '../types/user'
import { LOCAL_AUTH_KEY } from '../utils/contants'

const { INTERNAL_URL } = Config

type UserContextValue = {
  user?: User
  isFetchingUser?: boolean
  errorFetchingUser?: boolean
  isUpdatingUser?: boolean
  changeAvatar: ChangeAvatarFunction
  fetchUser: GetUserFunction
  resetUser: () => void
}

export const UserContext = createContext<Partial<UserContextValue>>({})

type ProviderProps = {
  children: React.ReactNode
}

const UserProvider: React.FC<ProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>()
  const [updating, setUpdating] = useState<boolean>(false)
  const authSession = window.localStorage.getItem(LOCAL_AUTH_KEY)

  const {
    isLoading: userLoading,
    isError: userError,
    reset: resetUser,
    mutate: getUserHandler,
  } = useMutation(getUser, {
    onSuccess: (data) => {
      setUser(data)
    },
  })

  useEffect(() => {
    if (authSession) {
      getUserHandler()
    }
  }, [])

  const changeAvatarHandler = async (file: File) => {
    setUpdating(true)
    const { photos } = await getSignedUrls({ photos: true })
    const avatarUrl = photos.avatar.url
    putWithSignedUrl(avatarUrl, file).then(() => {
      if (user) {
        const newUser = { ...user }
        newUser.avatar = `${INTERNAL_URL}/${photos.avatar.path}`
        setUser(newUser)
      }

      updateUser({
        avatar: photos.avatar.path,
      })

      setUpdating(false)
    })
  }

  const authValue: UserContextValue = {
    // Data
    user,

    // Loaders
    isFetchingUser: userLoading,
    isUpdatingUser: updating,

    // Errors
    errorFetchingUser: userError,

    // Handlers
    changeAvatar: changeAvatarHandler,
    fetchUser: getUserHandler,
    resetUser,
  }

  return (
    <UserContext.Provider value={authValue}>{children}</UserContext.Provider>
  )
}

export const useUser = () => {
  return useContext(UserContext)
}

export default UserProvider
