const PersonCross: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <circle cx="4.75" cy="2.75" r="2.75"></circle>
      <path d="M7.16 11.66l.48-.48a.25.25 0 000-.36l-.48-.48a2.26 2.26 0 01-.57-2.22.24.24 0 00-.15-.3 4.12 4.12 0 00-1.22-.3A4.75 4.75 0 000 12.25V13a1 1 0 001 1h5.3a.22.22 0 00.19-.09.27.27 0 00.06-.21 2.22 2.22 0 01.61-2.04zM12.24 11.18a.25.25 0 010-.36l1.54-1.54a.75.75 0 00-1.06-1.06l-1.54 1.54a.25.25 0 01-.36 0L9.28 8.22a.75.75 0 00-1.06 1.06l1.54 1.54a.25.25 0 010 .36l-1.54 1.54a.75.75 0 000 1.06.75.75 0 001.06 0l1.54-1.54a.25.25 0 01.36 0l1.54 1.54a.75.75 0 001.06 0 .75.75 0 000-1.06z"></path>
    </svg>
  )
}

export default PersonCross
