import { ModalProps } from '@mui/material'
import { useRef } from 'react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import Input from '../../components/atoms/Input'
import Button from '../../components/molecules/Button'
import { StyledDescription } from '../../components/styled/Description'
import { useTranslation } from '../../providers/I18n'
import { StyledDialog, StyledDialogContent } from './styled'

type EditClientNameProps = {
  open: boolean
  onClose: ModalProps['onClose']
  onSubmit: (newName: string) => void
  field: string
}

const EditClientName = (props: EditClientNameProps) => {
  const { getTranslation } = useTranslation()
  const clientNameRef = useRef<HTMLInputElement>(null)
  const fieldText = getTranslation!(`editable.${props.field}.label`)
  const description = getTranslation!(`editable.${props.field}.description`)

  const handleChangeName = () => {
    const newName = clientNameRef.current?.value
    if (newName) {
      props.onSubmit(newName)
      if (props.onClose) props.onClose({}, 'backdropClick')
    }
  }

  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <StyledDialogContent>
        <Heading type="4" border>
          {`Editar ${fieldText}`}
        </Heading>
        <StyledDescription>{description}</StyledDescription>
        <Container>
          <Input ref={clientNameRef} type="text" label="Nombre" />
          <Button onClick={handleChangeName}>{`Cambiar ${fieldText}`}</Button>
        </Container>
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default EditClientName
