import React from 'react'
import Heading from '../../components/atoms/Heading'
import Layout from '../../templates/Layout'
import TrashTable from '../../templates/TrashTable'

const TrashPage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        Congeladora
      </Heading>
      <TrashTable />
    </Layout>
  )
}

export default TrashPage
