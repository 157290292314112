import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditDocument,
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import Spinner from '../../../../../components/atoms/Spinner'
import FileDropper, {
  FileWithBuffer,
} from '../../../../../components/molecules/FileDropper'
import { uploadDocuments } from '../../../../../utils/documents'
import { useAlert } from '../../../../../providers/Alert'

const Phase3SignatureKit: React.FC = () => {
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const [kitFirmaFile, setKitFirmaFile] = useState<FileWithBuffer[]>([])
  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const [loading, setLoading] = useState<boolean>(false)
  const { getTranslation } = useTranslation()
  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.phase3Operator?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const kitFirmaBuffer = kitFirmaFile
        ? kitFirmaFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'kitFirma'
            return fileWithBuffer.file
          })
        : []

      const handleSendToSignatureDate = async () => {
        setLoading(true)

        const hasComment = !!commentRef.current?.value
        const hasKitFirmaFile = kitFirmaFile.length > 0
        if (!hasComment || !hasKitFirmaFile) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase3,
            step: CreditSteps.Phase3SignatureReady,
          }).then(async () => {
            const documents: FileWithBuffer[] = []
            if (kitFirmaBuffer.length > 0) {
              documents.push(...kitFirmaFile)
            }
            const pushedCreditDocuments = await uploadDocuments(
              documents,
              `credit/${credit!.code.toString()}`
            )

            pushedCreditDocuments.forEach((document) => {
              addCreditDocument(credit.code, document!)
            })

            const comment = `Avanzando a Etapa 3 - Listo Para Escritura :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              comment: comment,
              documents: pushedCreditDocuments,
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  owner: credit.owners.phase3Operator._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: credit.owners.advisor._id,
                  phase: CreditPhases.Phase3,
                  step: CreditSteps.Phase3SignatureReady,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      const handleSendBack = async () => {
        setLoading(true)

        await updateCredit(credit!.code, {
          step: CreditSteps.Phase3SignatureDate,
        }).then(async () => {
          const comment = `Regresando a Etapa 3 - Programación de Firma :: ${commentRef.current?.value}`
          const creditHistoryComment = {
            comment: comment,
            type: 'status',
            start: new Date(),
            change: {
              from: {
                owner: credit.owners.phase3Operator._id,
                step: credit.step,
              },
              to: {
                owner: credit.owners.advisor._id,
                step: CreditSteps.Phase3SignatureDate,
              },
            },
          }

          await addCreditHistory(credit!.code, creditHistoryComment).then(
            () => {
              if (commentRef.current) {
                commentRef.current.value = ''
              }
              refetch().then(() => {
                setLoading(false)
              })
            }
          )
        })
      }

      content = (
        <Fragment>
          <StyledDescription>
            Solicita VoBo de proyecto, programación de firma, esperando kit de
            firma.
          </StyledDescription>
          <StyledDescription>
            Una vez que Banco lo rerese, subirlo y avanzar a Listo Para Firma
          </StyledDescription>
          <FileDropper
            label="Kit de Firma"
            onChange={setKitFirmaFile}
            files={kitFirmaBuffer}
          />
          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <>
              {user?.role !== Role.Advisor && (
                <Button
                  color={color}
                  priority="secondary"
                  onClick={handleSendBack}
                  loading={loading}
                >
                  Regresar a Programación de Firma
                </Button>
              )}
              <Button onClick={handleSendToSignatureDate} color={color}>
                Listo Para Firma
              </Button>
            </>
          )}
        </Container>
      )
    } else {
      content = (
        <CreditWaitAction
          owner={credit.owners.phase3Operator}
          text="Se estan generando tu kit de firma, gracias."
        />
      )
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase3SignatureKit
