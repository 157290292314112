import React, { useEffect, useRef, useState, KeyboardEvent } from 'react'
import { Icons } from '../../../types/enums'
import { SelectOption } from '../../../types/html'
import { ReactBaseElement } from '../../../types/react'
import { StyledError } from '../../styled/Error'
import Icon from '../Icon'
import Spinner from '../Spinner'
import {
  StyledDropdownSelected,
  StyledDropdownContainer,
  StyledSelectContainer,
  StyledLabel,
  StyledDropdownList,
} from './styled'

type SelectProps = {
  label?: string
  items?: SelectOption[]
  placeholder?: string
  disabled?: boolean
  required?: boolean
  loading?: boolean
  autocomplete?: string
  error?: string
  type?: 'text' | 'password' | 'email' | 'number'
  preIcon?: Icons
  afterIcon?: Icons
  value?: SelectOption | null
  onChange?: (value: SelectOption) => void
} & ReactBaseElement

const Select = React.forwardRef(
  (props: SelectProps, ref: React.Ref<HTMLInputElement>) => {
    const internalRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [selected, setSelected] = useState<SelectOption>(
      props.value || {
        label: '--',
        value: '--',
      }
    )

    useEffect(() => {
      setSelected(
        props.value || {
          label: '--',
          value: '--',
        }
      )
    }, [props.value])

    const handleSelected = (value: SelectOption) => {
      setSelected(value)
      setOpen(false)
      setSearch('')
      if (props.onChange) {
        props.onChange(value)
      }
    }

    const handleToggle = () => {
      if (props.disabled) {
        return
      }
      setSearch('')
      setOpen(!open)
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.keyCode >= 65 && event.keyCode <= 90) {
        const newValue = search + event.key
        setSearch(newValue)
      } else if (event.keyCode === 8) {
        const newValue = search.substring(0, search.length - 1)
        setSearch(newValue)
      }
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        internalRef &&
        internalRef.current &&
        !internalRef.current.contains(event.target as Node)
      ) {
        setOpen(false)
        setSearch('')
      }
    }

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true)
      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }, [])

    const inputValue =
      (selected?.label === '--' && search) || selected?.label || '--'

    if (props.loading) {
      return <Spinner type="bar" />
    }

    const filteredItems =
      props.items &&
      props.items.filter((item) => {
        return item.label.toLowerCase().indexOf(search.toLowerCase()) >= 0
      })

    return (
      <StyledSelectContainer ref={internalRef} disabled={props.disabled}>
        {props.preIcon && <Icon name={props.preIcon} />}
        <StyledLabel>{props.label}</StyledLabel>
        <StyledDropdownContainer
          open={open}
          onClick={handleToggle}
          tabIndex={-1}
          onKeyDown={handleKeyDown}
        >
          <StyledDropdownSelected
            type={props.type || 'text'}
            placeholder={search || props.placeholder || '...'}
            required={props.required}
            autoComplete={props.autocomplete}
            disabled
            empty={selected?.label === '--'}
            value={inputValue}
          />
          <input
            readOnly
            ref={ref}
            hidden
            value={selected.label === '--' ? '' : JSON.stringify(selected)}
          />
          {open && (
            <StyledDropdownList>
              <li
                onClick={() =>
                  handleSelected({
                    label: '--',
                    value: '--',
                  })
                }
                key="reset"
              >
                --
              </li>
              {filteredItems?.map((option) => {
                return (
                  <li onClick={() => handleSelected(option)} key={option.value}>
                    {option.label}
                  </li>
                )
              })}
            </StyledDropdownList>
          )}
          {props.error && <StyledError>{props.error}</StyledError>}
        </StyledDropdownContainer>
      </StyledSelectContainer>
    )
  }
)

export default Select
