export const MaritalStatus = [
  {
    value: 'single',
    label: 'Soltero',
  },
  {
    value: 'free',
    label: 'Union Libre',
  },
  {
    value: 'married',
    label: 'Casado',
  },
]

export const SecondParticipantRelation = [
  { value: 'conyuge', label: 'Conyuge' },
  { value: 'concubino', label: 'Concubin@' },
  { value: 'father', label: 'Padre' },
  { value: 'mother', label: 'Madre' },
  { value: 'children', label: 'Hijo' },
  { value: 'other', label: 'Otro' },
]

export const MaritalRegimen = [
  {
    value: 'bienes',
    label: 'Separación de Bienes',
  },
  {
    value: 'sociedad',
    label: 'Sociedad Conyugal',
  },
]

export const States = [
  {
    value: 'Aguascalientes',
    label: 'Aguascalientes',
  },
  {
    value: 'Baja California',
    label: 'Baja California',
  },
  {
    value: 'Baja California Sur',
    label: 'Baja California Sur',
  },
  {
    value: 'Campeche',
    label: 'Campeche',
  },
  {
    value: 'Chiapas',
    label: 'Chiapas',
  },
  {
    value: 'Chihuahua',
    label: 'Chihuahua',
  },
  {
    value: 'Ciudad de México',
    label: 'Ciudad de México',
  },
  {
    value: 'Coahuila',
    label: 'Coahuila',
  },
  {
    value: 'Colima',
    label: 'Colima',
  },
  {
    value: 'Durango',
    label: 'Durango',
  },
  {
    value: 'Estado de México',
    label: 'Estado de México',
  },
  {
    value: 'Guanajuato',
    label: 'Guanajuato',
  },
  {
    value: 'Guerrero',
    label: 'Guerrero',
  },
  {
    value: 'Hidalgo',
    label: 'Hidalgo',
  },
  {
    value: 'Jalisco',
    label: 'Jalisco',
  },
  {
    value: 'Michoacán',
    label: 'Michoacán',
  },
  {
    value: 'Monterrey',
    label: 'Monterrey',
  },
  {
    value: 'Morelos',
    label: 'Morelos',
  },
  {
    value: 'Nayarit',
    label: 'Nayarit',
  },
  {
    value: 'Nuevo Leon',
    label: 'Nuevo Leon',
  },
  {
    value: 'Oaxaca',
    label: 'Oaxaca',
  },
  {
    value: 'Puebla',
    label: 'Puebla',
  },
  {
    value: 'Queretaro',
    label: 'Queretaro',
  },
  {
    value: 'Quintana Roo',
    label: 'Quintana Roo',
  },
  {
    value: 'San Luis Potosi',
    label: 'San Luis Potosi',
  },
  {
    value: 'Sinaloa',
    label: 'Sinaloa',
  },
  {
    value: 'Sonora',
    label: 'Sonora',
  },
  {
    value: 'Tabasco',
    label: 'Tabasco',
  },
  {
    value: 'Tamaulipas',
    label: 'Tamaulipas',
  },
  {
    value: 'Tlaxcala',
    label: 'Tlaxcala',
  },
  {
    value: 'Veracruz',
    label: 'Veracruz',
  },
  {
    value: 'Yucátan',
    label: 'Yucátan',
  },
  {
    value: 'Zacatecas',
    label: 'Zacatecas',
  },
]

export const YesNo = [
  {
    value: '1',
    label: 'Si',
  },
  {
    value: '0',
    label: 'No',
  },
]
