import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import FileDropper, {
  FileWithBuffer,
} from '../../../../../components/molecules/FileDropper'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditDocument,
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import { Mappeable } from '../../../../../types/objects'
import FileItem from '../../../../../components/molecules/FileItem'
import { ClientDocument } from '../../../../../types/clients'
import { uploadDocuments } from '../../../../../utils/documents'
import { addClientDocument } from '../../../../../services/clients'
import { useAlert } from '../../../../../providers/Alert'
import Spinner from '../../../../../components/atoms/Spinner'

const PrequalificationDocuments: React.FC = () => {
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const [addressProofFile, setAddressProofFile] = useState<FileWithBuffer[]>([])
  const [maritalFile, setMaritalFile] = useState<FileWithBuffer[]>([])
  const [incomeProofFile, setIncomeProofFile] = useState<FileWithBuffer[]>([])
  const [paymentStubsFile, setPaymentStubsFile] = useState<FileWithBuffer[]>([])
  const [altaHaciendaFile, setAltaHaciendaFile] = useState<FileWithBuffer[]>([])
  const [otherFile, setOtherFile] = useState<FileWithBuffer[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const { getTranslation } = useTranslation()
  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.advisor?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    const uploadedDocuments = credit.client.documents.reduce<
      Mappeable<ClientDocument>
    >((map, document) => {
      map[document.name] = document
      return map
    }, {})

    const uploadedCreditDocuments = credit.documents.reduce<
      Mappeable<ClientDocument>
    >((map, document) => {
      map[document.name] = document
      return map
    }, {})

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const clientIsMarried = credit.client.meta.maritalStatus === 'Casado'
      const addressProofFileBuffer = addressProofFile
        ? addressProofFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'addressProof'
            return fileWithBuffer.file
          })
        : []

      const incomeProofFileBuffer = incomeProofFile
        ? incomeProofFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'incomeProof'
            return fileWithBuffer.file
          })
        : []

      const paymentStubsFileBuffer = paymentStubsFile
        ? paymentStubsFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'paymentStubs'
            return fileWithBuffer.file
          })
        : []

      const altaHaciendaFileBuffer = altaHaciendaFile
        ? altaHaciendaFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'altaHacienda'
            return fileWithBuffer.file
          })
        : []

      const maritalFileBuffer = maritalFile
        ? maritalFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'marital'
            return fileWithBuffer.file
          })
        : []

      const otherFileBuffer = otherFile
        ? otherFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'otherInitial'
            return fileWithBuffer.file
          })
        : []

      const handleSendBack = async () => {
        setLoading(true)

        await updateCredit(credit!.code, {
          step: CreditSteps.PrequalificationRequest,
        }).then(async () => {
          const comment = `Regresando a Precalificación - Asignación :: ${commentRef.current?.value}`
          const creditHistoryComment = {
            comment: comment,
            type: 'status',
            start: new Date(),
            change: {
              from: {
                owner: credit.owners.advisor._id,
                phase: credit.phase,
                step: credit.step,
              },
              to: {
                owner: credit.owners.prequalificationOperator._id,
                phase: CreditPhases.Prequalification,
                step: CreditSteps.PrequalificationRequest,
              },
            },
          }

          await addCreditHistory(credit!.code, creditHistoryComment).then(
            () => {
              if (commentRef.current) {
                commentRef.current.value = ''
              }
              refetch().then(() => {
                setLoading(false)
              })
            }
          )
        })
      }

      const handleSendToAssignation = async () => {
        setLoading(true)

        const clientDocuments: FileWithBuffer[] = []
        if (addressProofFileBuffer.length > 0) {
          clientDocuments.push(...addressProofFile)
        }

        if (maritalFileBuffer.length > 0) {
          clientDocuments.push(...maritalFile)
        }

        const pushedClientDocuments = await uploadDocuments(
          clientDocuments,
          `client/${credit!.client.code.toString()}`
        )

        pushedClientDocuments.forEach((document) => {
          addClientDocument(credit.client.code, document!)
        })

        const creditDocuments: FileWithBuffer[] = []
        if (incomeProofFileBuffer.length > 0) {
          creditDocuments.push(...incomeProofFile)
        }

        if (paymentStubsFileBuffer.length > 0) {
          creditDocuments.push(...paymentStubsFile)
        }

        if (altaHaciendaFileBuffer.length > 0) {
          creditDocuments.push(...altaHaciendaFile)
        }

        if (otherFileBuffer.length > 0) {
          creditDocuments.push(...otherFile)
        }

        const pushedCreditDocuments = await uploadDocuments(
          creditDocuments,
          `credit/${credit!.code.toString()}`
        )

        pushedCreditDocuments.forEach((document) => {
          addCreditDocument(credit.code, document!)
        })

        const hasComment = !!commentRef.current?.value
        const hasAddressProofDocuments =
          uploadedDocuments['addressProof'] || addressProofFile.length > 0
        const hasIncomeProofDocuments =
          uploadedCreditDocuments['incomeProof'] ||
          incomeProofFile.length > 0 ||
          uploadedCreditDocuments['paymentStubs'] ||
          paymentStubsFile.length > 0

        if (
          !hasComment ||
          !hasAddressProofDocuments ||
          !hasIncomeProofDocuments
        ) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase1,
            step: CreditSteps.Phase1Assignation,
            owners: {
              phase1Operator: credit.owners.prequalificationOperator._id,
            },
          }).then(async () => {
            const comment = `Avanzando a Etapa 1 - Revision Para Analisis :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              documents: [...pushedClientDocuments, ...pushedCreditDocuments],
              comment: comment,
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  owner: credit.owners.advisor._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: credit.owners.prequalificationOperator._id,
                  phase: CreditPhases.Phase1,
                  step: CreditSteps.Phase1Assignation,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      content = (
        <Fragment>
          <StyledDescription>
            Subir documentos para integrar expediente completo y poder enviar a
            Banco.
          </StyledDescription>

          <StyledDescription>Documentos de Cliente:</StyledDescription>

          {!uploadedDocuments['addressProof'] ? (
            <FileDropper
              label="Comprobante de Domicilio"
              onChange={setAddressProofFile}
              files={addressProofFileBuffer}
            />
          ) : (
            <FileItem
              id="addressProof"
              url={uploadedDocuments['addressProof'].url}
              uploadedAt={uploadedDocuments['addressProof'].uploadedAt}
              onChange={setAddressProofFile}
            />
          )}

          {clientIsMarried && !uploadedDocuments['marital'] && (
            <FileDropper
              label="Acta de Matrimonio"
              onChange={setMaritalFile}
              files={maritalFileBuffer}
            />
          )}

          {clientIsMarried && uploadedDocuments['marital'] && (
            <FileItem
              id="marital"
              url={uploadedDocuments['marital'].url}
              uploadedAt={uploadedDocuments['marital'].uploadedAt}
              onChange={setMaritalFile}
            />
          )}

          <StyledDescription>Documentos de Credito:</StyledDescription>

          {!uploadedCreditDocuments['incomeProof'] ? (
            <FileDropper
              label="Estados de Cuenta"
              onChange={setIncomeProofFile}
              files={incomeProofFileBuffer}
            />
          ) : (
            <FileItem
              id="incomeProof"
              url={uploadedCreditDocuments['incomeProof'].url}
              uploadedAt={uploadedCreditDocuments['incomeProof'].uploadedAt}
              onChange={setIncomeProofFile}
            />
          )}

          {!uploadedCreditDocuments['paymentStubs'] ? (
            <FileDropper
              label="Recibos de Nomina"
              onChange={setPaymentStubsFile}
              files={paymentStubsFileBuffer}
            />
          ) : (
            <FileItem
              id="paymentStubs"
              url={uploadedCreditDocuments['paymentStubs'].url}
              uploadedAt={uploadedCreditDocuments['paymentStubs'].uploadedAt}
              onChange={setPaymentStubsFile}
            />
          )}

          {!uploadedCreditDocuments['altaHacienda'] ? (
            <FileDropper
              label="Alta Hacienda"
              onChange={setAltaHaciendaFile}
              files={altaHaciendaFileBuffer}
            />
          ) : (
            <FileItem
              id="altaHacienda"
              url={uploadedCreditDocuments['altaHacienda'].url}
              uploadedAt={uploadedCreditDocuments['altaHacienda'].uploadedAt}
              onChange={setAltaHaciendaFile}
            />
          )}

          {!uploadedCreditDocuments['otherInitial'] ? (
            <FileDropper
              label="Otros"
              onChange={setOtherFile}
              files={otherFileBuffer}
            />
          ) : (
            <FileItem
              id="otherInitial"
              url={uploadedCreditDocuments['otherInitial'].url}
              uploadedAt={uploadedCreditDocuments['otherInitial'].uploadedAt}
              onChange={setOtherFile}
            />
          )}

          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <>
              {user?.role !== Role.Advisor && (
                <Button
                  color={color}
                  priority="secondary"
                  onClick={handleSendBack}
                  loading={loading}
                >
                  Regresar a Asignación Precalificación
                </Button>
              )}
              <Button
                color={color}
                onClick={handleSendToAssignation}
                loading={loading}
              >
                Mandar a Revisión para Analisis
              </Button>
            </>
          )}
        </Container>
      )
    } else {
      content = (
        <CreditWaitAction owner={credit.owners.prequalificationOperator} />
      )
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default PrequalificationDocuments
