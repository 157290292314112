const FileMinus: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M1 11h5a1 1 0 000-2H1a1 1 0 000 2z"></path>
      <path d="M13.78 4L10 .22A.75.75 0 009.5 0H3.75A1.76 1.76 0 002 1.75v5.5a.25.25 0 00.25.25h1a.25.25 0 00.25-.25v-5.5a.25.25 0 01.25-.25h5.34a.26.26 0 01.17.07l3.17 3.17a.26.26 0 01.07.17v7.34a.25.25 0 01-.25.25H2.34a.25.25 0 00-.24.33A1.75 1.75 0 003.75 14h8.5A1.76 1.76 0 0014 12.25V4.5a.75.75 0 00-.22-.5z"></path>
    </svg>
  )
}

export default FileMinus
