import styled from 'styled-components'

export const StyledPillCell = styled.div<{ color: string }>`
  border: 1px solid silver;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: white;
  font-weight: bold;
  color: ${(props) => props.color};
  width: 100%;
  text-align: left;
`
