import ActiveCell from '../components/organisms/Table/Cells/ActiveCell'
import PillCell from '../components/organisms/Table/Cells/Pill'

export const BasicFollowUpColumns = [
  {
    name: 'Private ID',
    key: 'id',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Current Operator',
    key: 'currentOperator',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Step',
    key: 'step',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Estatus',
    key: 'visualStep',
    component: PillCell,
  },
  {
    name: 'ID',
    key: 'visualId',
    width: '6rem',
  },
  {
    name: 'Cliente',
    key: 'client',
    width: '12rem',
  },
  {
    name: 'Asesora',
    key: 'advisor',
    width: '8rem',
    component: ActiveCell,
  },
  {
    name: 'Operadora',
    key: 'operator',
    width: '8rem',
    component: ActiveCell,
  },

  {
    name: 'Creado En',
    key: 'createdAt',
    width: '6rem',
  },

  {
    name: 'Monto',
    key: 'creditAmount',
    width: '10rem',
    sort: true,
    sortFunction: (a: any, b: any) => {
      const crediAmountA = parseFloat(a.creditAmount.replace(/[$|,]/g, ''))
      const creditAmountB = parseFloat(b.creditAmount.replace(/[$|,]/g, ''))
      return crediAmountA > creditAmountB ? 1 : -1
    },
  },
  {
    name: 'Banco',
    key: 'bank',
    width: '6rem',
  },
  {
    name: 'Expira En',
    key: 'expirationDate',
    width: '6rem',
  },
  {
    name: 'Tipo de Credito',
    key: 'productType',
    width: '10rem',
  },
  {
    name: 'Contacto',
    key: 'contactForm',
    width: '10rem',
  },
]
