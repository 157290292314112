import styled from 'styled-components'

export const StyledPill = styled.span<{ color: string }>`
  display: block;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  border: 4px solid;
  border-color: ${(props) => props.color};
  border-radius: 5px;
  padding: 0 10px;
  text-decoration: none;
  background-color: white;
`
