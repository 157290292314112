import React from 'react'
import { StyledDescription } from '../../styled/Description'
import { StyledCircle, StyledCircleImage } from './styled'

export type CircleImageProps = {
  src: string
  icon: string
  text: string
  color: string
  disabled?: boolean
}

const CircleImage: React.FC<CircleImageProps> = (props) => {
  return (
    <StyledCircleImage disabled={props.disabled || false}>
      <a href={props.src || undefined} target="_blank">
        <StyledCircle
          image={props.icon}
          color={props.color || `var(--color-red-2)`}
        />
        <StyledDescription>{props.text}</StyledDescription>
      </a>
    </StyledCircleImage>
  )
}

export default CircleImage
