const Trash: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M14 4.25a.76.76 0 00-.75-.75H10.5a.25.25 0 01-.25-.25 3.25 3.25 0 00-6.5 0 .25.25 0 01-.25.25H.75a.75.75 0 000 1.5h1a.25.25 0 01.25.25v7.25A1.5 1.5 0 003.5 14h7a1.5 1.5 0 001.5-1.5V5.25a.25.25 0 01.25-.25h1a.76.76 0 00.75-.75zm-8.75-1a1.75 1.75 0 013.5 0 .25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25zm.5 7.75a.75.75 0 01-1.5 0V7a.75.75 0 011.5 0zM10 11a.75.75 0 01-1.5 0V7A.75.75 0 0110 7z"></path>
    </svg>
  )
}

export default Trash
