import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Avatar from '../../atoms/Avatar'
import Spinner from '../../atoms/Spinner'
import { StyledAvatarInput } from './styled'

export type AvatarInputProps = {
  src: string
  loading?: boolean
  onChange: (file: File) => void
}

const AvatarInput: React.FC<AvatarInputProps> = (props) => {
  const [newAvatar, setNewAvatar] = useState(props.src)
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/*': [],
    },
    onDrop: async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      const buffer = await file.arrayBuffer()
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
        buffer,
      })
      setNewAvatar(newFile.preview)
      props.onChange(file)
    },
  })
  return (
    <StyledAvatarInput>
      {props.loading ? (
        <Spinner type="fade" />
      ) : (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Avatar src={newAvatar} />
        </div>
      )}
    </StyledAvatarInput>
  )
}

export default AvatarInput
