import styled from 'styled-components'
import { StyledIcon } from '../../atoms/Icon/styled'
import { ButtonStyles } from './index'

export const StyledButton = styled.button<ButtonStyles>`
  --button-background-color: ${(props) =>
    props.disabled
      ? 'var(--color-gray-5)'
      : props.color || 'var(--color-red-1)'};
  --button-hover-background-color: ${(props) =>
    props.disabled
      ? 'var(--color-gray-5)'
      : props.color || 'var(--color-red-2)'};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: var(--spc-xs) var(--spc-sm);
  font-size: var(--fs-sm);
  min-height: var(--spc-lg);
  min-width: 10rem;
  text-align: center;
  background-color: ${(props) =>
    props.priority === 'primary'
      ? 'var(--button-background-color)'
      : 'var(--color-white)'};
  color: ${(props) =>
    props.priority === 'primary'
      ? 'var(--color-white)'
      : 'var(--color-gray-2)'};
  border-radius: 3px;
  outline: none;
  border: ${(props) =>
    props.priority === 'primary' ? 'none' : '1px solid silver'};
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.priority === 'primary'
        ? 'var(--button-hover-background-color)'
        : 'var(--color-gray-5)'};
  }

  ${StyledIcon} {
    font-size: var(--fs-lg);
  }
`
