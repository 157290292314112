import styled from 'styled-components'

export const StyledFollowUpTiles = styled.div<{
  ghost?: boolean
}>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 48rem) {
    justify-content: space-between;
  }
`
