import styled from 'styled-components'
import { ContainerStyles } from '.'

export type ContainerInternalStyles = {
  justifyContent: string
  alignItems: string
  direction: string
} & ContainerStyles

export const StyledContainer = styled.div<ContainerInternalStyles>`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.direction};
  gap: 0.5rem;
  flex-wrap: wrap;

  ${(props) =>
    props.padding
      ? `
    width: calc(100% - var(--spc-sm) - var(--spc-sm));
    padding: var(--spc-xs) var(--spc-sm);
  `
      : `
    width: 100%;
  `};
`
