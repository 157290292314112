import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import { useAlert } from '../../../../../providers/Alert'

const Phase1Inconsistency: React.FC = () => {
  const commentRef = useRef<HTMLTextAreaElement>(null)

  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const [loading, setLoading] = useState<boolean>(false)
  const { getTranslation } = useTranslation()
  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.advisor?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const handleAssignForAnalysis = async () => {
        setLoading(true)

        const hasComment = !!commentRef.current?.value
        if (!hasComment) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase1,
            step: CreditSteps.Phase1Assignation,
          }).then(async () => {
            const comment = `Regresando a Etapa 1 - Asignación :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              comment: comment,
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  owner: credit.owners.advisor._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: credit.owners.phase1Operator._id,
                  phase: CreditPhases.Phase1,
                  step: CreditSteps.Phase1Assignation,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      content = (
        <Fragment>
          <StyledDescription>
            Hay una inconsistencia en el tramite, favor de resolver. El tramite
            se regresara a asignación.
          </StyledDescription>
          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          <Button
            onClick={handleAssignForAnalysis}
            color={color}
            loading={loading}
          >
            Regresar a Asignación
          </Button>
        </Container>
      )
    } else {
      content = <CreditWaitAction owner={credit.owners.phase1Operator} />
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase1Inconsistency
