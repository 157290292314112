const Search: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M13.71 13.71a1 1 0 000-1.42L11 9.62a.24.24 0 010-.31A6 6 0 109.31 11a.24.24 0 01.31 0l2.67 2.67a1 1 0 001.42.04zM2 6a4 4 0 114 4 4 4 0 01-4-4z"></path>
    </svg>
  )
}

export default Search
