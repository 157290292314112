import styled from 'styled-components'

export const StyledAuthorizationCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
`

export const StyledAuthorizedIcon = styled.img`
  margin: 1rem 0;
  max-width: 100px;
`

export const StyledAuthorizationTitle = styled.h2`
  font-size: var(--fs-xxxl);
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
  margin-bottom: 2rem;
`

export const StyledAuthorizationClientName = styled.h3`
  font-size: var(--fs-xxl);
  text-align: center;
  text-transform: uppercase;
`

export const StyledAuthorizationMonto = styled.h3`
  font-size: var(--fs-xxl);
  font-weight: lighter;
  text-align: center;
  margin-bottom: 1rem;
`

export const StyledAuthorizationText = styled.p`
  margin: 1rem 0;
  max-width: 500px;
  font-size: var(--fs-md);
  text-align: center;
  text-transform: uppercase;
`

export const StyledAuthorizationContainer = styled.div`
  min-width: 600px;
`

export const StyledAuthorizationData = styled.table`
  background-color: whitesmoke;
  border: 1px solid var(--color-red-1);
  border-radius: 5px;
  font-size: var(--fs-sm);
  padding: 0.5rem 1rem;
  width: 80%;
`

export const StyledAuthorizationFooter = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;

  p {
    font-size: var(--fs-xs);
    margin-bottom: 1rem;
  }

  a {
    color: var(--color-red-1);
    font-size: var(--fs-md);
  }
`
