import React from 'react'
import { Link } from 'react-router-dom'
import { ReactBaseElement } from '../../../types/react'
import Icon from '../../atoms/Icon'
import {
  StyledSideBar,
  StyledItems,
  StyledItem,
  StyledLabel,
  StyledBottomSection,
} from './styled'

type SideBarItem = {
  icon: string
  label: string
  path: string
}

type SideBarProps = {
  items: SideBarItem[]
  bottomItems: SideBarItem[]
} & ReactBaseElement

const SideBar: React.FC<SideBarProps> = (props) => {
  return (
    <StyledSideBar>
      <StyledItems>
        {props.items.map((item) => (
          <Link key={item.path} to={item.path}>
            <StyledItem>
              <Icon name={item.icon} />
              <StyledLabel>{item.label}</StyledLabel>
            </StyledItem>
          </Link>
        ))}
      </StyledItems>
      <StyledBottomSection>
        {props.bottomItems.map((item) => (
          <Link key={item.path} to={item.path}>
            <StyledItem>
              <Icon name={item.icon} />
              <StyledLabel>{item.label}</StyledLabel>
            </StyledItem>
          </Link>
        ))}
      </StyledBottomSection>
    </StyledSideBar>
  )
}

export default SideBar
