import React from 'react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import Layout from '../../templates/Layout'
import NewCreditFlow from '../../templates/NewCreditFlow'

const NewCreditPage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        Nuevo Credito
      </Heading>
      <Container align="center">
        <NewCreditFlow />
      </Container>
    </Layout>
  )
}

export default NewCreditPage
