import React from 'react'
import { ReactBaseElement } from '../../../types/react'
import Heading from '../../atoms/Heading'
import { StyledDescription } from '../../styled/Description'
import {
  StyledDc,
  StyledDd,
  StyledDescriptionList,
  StyledDl,
  StyledDt,
} from './styled'

type DescriptionListProps = {
  title?: string
  description?: string
  list: {
    label: string | JSX.Element
    value: string | JSX.Element
  }[]
} & ReactBaseElement

const DescriptionList: React.FC<DescriptionListProps> = (props) => {
  return (
    <StyledDescriptionList>
      {props.title && <Heading type="3">{props.title}</Heading>}
      {props.description && (
        <StyledDescription>{props.description}</StyledDescription>
      )}
      <StyledDl>
        {props.list.map((dataItem, index) => (
          <StyledDc key={index}>
            <StyledDt>{dataItem.label}</StyledDt>
            <StyledDd>{dataItem.value}</StyledDd>
          </StyledDc>
        ))}
      </StyledDl>
    </StyledDescriptionList>
  )
}

export default DescriptionList
