const PersonCrown: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M13.25 14a.74.74 0 00.55-.24.75.75 0 00.2-.55 9 9 0 00-1.11-4 .26.26 0 00-.26-.1.27.27 0 00-.19.21A5.5 5.5 0 017 14zM1.54 9.1a.25.25 0 00-.16-.21.27.27 0 00-.27.11C-.13 10.27 0 13 0 13.29a.75.75 0 00.76.71H7a5.51 5.51 0 01-5.46-4.9z"></path>
      <path d="M3.33 7.5a.26.26 0 00-.25.2 4.26 4.26 0 00-.08.8 4 4 0 008 0 3.56 3.56 0 00-.08-.8.24.24 0 00-.24-.2zM12.33 1.28a.48.48 0 00-.3-.11.39.39 0 00-.18 0l-2.19 1a.58.58 0 01-.19 0 .44.44 0 01-.33-.15l-1.8-2A.46.46 0 007 0a.44.44 0 00-.33.15l-1.8 2a.45.45 0 01-.34.15.58.58 0 01-.18 0l-2.19-1a.39.39 0 00-.18 0 .46.46 0 00-.3.11.56.56 0 00-.17.51l.7 3.79a.47.47 0 00.46.29h8.67a.48.48 0 00.46-.42l.69-3.79a.53.53 0 00-.16-.51z"></path>
    </svg>
  )
}

export default PersonCrown
