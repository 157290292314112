import styled from 'styled-components'

export const StyledOperatorsWorkload = styled.div<{
  ghost?: boolean
}>`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 48rem) {
    justify-content: space-between;
  }
`

export const StyledOperatorsList = styled.ul`
  list-style: none;
  margin-top: 1rem;
  padding-left: 24px;
`

export const StyledOperatorItem = styled.li`
  font-size: 14px;
  margin-bottom: 10px;
`
