const UploadCircle: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M7 0a7 7 0 107 7 7 7 0 00-7-7zm2.78 6.78a.75.75 0 01-1.06 0l-.54-.54a.26.26 0 00-.28-.06.25.25 0 00-.15.23V10a.75.75 0 01-1.5 0V6.41a.25.25 0 00-.15-.23.26.26 0 00-.28.06l-.54.54a.75.75 0 01-1.06-1.06l2.25-2.25a.75.75 0 011.06 0l2.25 2.25a.75.75 0 010 1.06z"></path>
    </svg>
  )
}

export default UploadCircle
