const Pencil: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
    >
      <path d="M13.56 2.23L11.77.44a1.51 1.51 0 00-2.14 0L1.4 8.67a.48.48 0 00-.12.22L0 13.36a.49.49 0 00.13.49.47.47 0 00.37.15h.13l4.48-1.26a.43.43 0 00.22-.12l8.23-8.23A1.54 1.54 0 0014 3.3a1.5 1.5 0 00-.44-1.07z"></path>
    </svg>
  )
}

export default Pencil
