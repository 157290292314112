import React from 'react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import Config from '../../config/environment'
import AuthExpiredCreditsTable from '../../templates/AuthExpiredCreditsTable'
import Layout from '../../templates/Layout'
import MonthAllocationsTable from '../../templates/MonthAllocationsTable'

const { SITE_NAME } = Config

const HomePage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        {`Bienvenida al CRM de ${SITE_NAME}`}
      </Heading>
      <Container align="left">
        <MonthAllocationsTable />
        <AuthExpiredCreditsTable />
      </Container>
    </Layout>
  )
}

export default HomePage
