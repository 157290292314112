import RolesConfig from '../config/role'
import { Mappeable } from '../types/objects'
import { ColumnConfig, ColumnTableRow } from '../types/tables'

export type Resources =
  | 'allocation'
  | 'followUp'
  | 'commissions'
  | 'clients'
  | 'celulasPerformance'
  | 'savingFunds'

export function getTableColumns(columns: ColumnConfig[]): ColumnTableRow[] {
  return columns.map((column) => {
    const ColumnComponent = column.component
    const contentKey = ColumnComponent
      ? { cell: (row: any) => <ColumnComponent data={row[column.key]} /> }
      : { selector: (row: any) => row[column.key] }

    return {
      name: column.name,
      key: column.key,
      omit: column.omit,
      sortable: column.sort,
      sortFunction: column.sortFunction,
      omitCSV: column.omitCSV,
      style: column.styles,
      width: column.width,
      ...contentKey,
    }
  })
}

export function getTableData(columns: ColumnTableRow[], rawData: any[]) {
  const tableData = rawData.map((row) => {
    const columnsData = columns.reduce<Mappeable<string>>((data, column) => {
      const columnKey = column.key
      const dataValue = row[columnKey]
      if (column.cell) {
        data[columnKey] = dataValue || undefined
      } else {
        const value =
          typeof dataValue === 'boolean' ? dataValue : dataValue || '--'
        data[columnKey] = value
      }
      return data
    }, {})
    return columnsData
  })
  return tableData
}

export function getRoleResourceColumns(resource: Resources, role?: string) {
  const config = RolesConfig[role || 'advisor']
  return getTableColumns(config[resource])
}
