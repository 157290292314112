export enum Icons {
  BANK = 'bank',
  CALENDAR = 'calendar',
  DEFAULT_AVATAR = 'defaultAvatar',
  SEARCH_CIRCLE = 'searchCircle',
  SEARCH = 'search',
  FILTER = 'filter',
  PENCIL = 'pencil',
  HOME = 'home',
  LIST = 'list',
  FILE_PLUS = 'filePlus',
  FILE_MINUS = 'fileMinus',
  FILE_ATTACHMENT = 'fileAttachment',
  FILE = 'file',
  DOWNLOAD_CIRCLE = 'downloadCircle',
  UPLOAD_CIRCLE = 'uploadCircle',
  PERSON_CROWN = 'personCrown',
  PERSON_SCREENING = 'personScreening',
  PERSON_PLUS = 'personPlus',
  PERSON_MINUS = 'personMinus',
  PERSON_CHECK = 'personCheck',
  PERSON_CROSS = 'personCross',
  PERSON_LOCK = 'personLock',
  PERSON_QUESTION = 'personQuestion',
  PERCENTAGE_CIRCLE = 'percentageCircle',
  PERCENTAGE = 'percentage',
  TRASH = 'trash',
  BOOKS = 'books',
  GRAPH = 'graph',
  MONEY_BAG = 'moneyBag',
  SETTINGS = 'settings',
  PLUS_CIRCLE = 'plusCircle',
  MINUS_CIRCLE = 'minusCircle',
  LOGIN_CIRCLE = 'loginCircle',
  LOGOUT_CIRCLE = 'logoutCircle',
  USERS = 'users',
  SAVINGS_BOX = 'savings-box',
  WARNING_BUBBLE = 'warningBubble',
}

export enum Role {
  Admin = 'admin',
  Advisor = 'advisor',
  Operator = 'operator',
  Manager = 'manager',
  Director = 'director',
  Developer = 'developer',
}

export enum Owners {
  Advisor = 'advisor',
  PrequalificationOperator = 'prequalificationOperator',
  Phase1Operator = 'phase1Operator',
  Phase2Operator = 'phase2Operator',
  Phase3Operator = 'phase3Operator',
}

export enum CreditPhases {
  Prequalification = 'prequalification',
  Phase1 = 'phase-1',
  Phase2 = 'phase-2',
  Phase3 = 'phase-3',
}

export enum CreditSteps {
  PrequalificationRequest = 'prequalification-request',
  PrequalificationDocuments = 'prequalification-documents',

  Phase1Assignation = 'credit-assignation',
  Phase1Analysis = 'credit-analysis',
  Phase1Inconsistency = 'credit-inconsistency',
  Phase1Rejected = 'credit-rejected',
  Phase1Authorized = 'credit-authorized',
  Phase1MissingOriginales = 'credit-missing-originales',
  Phase1AppraisalDocuments = 'credit-appraisal-documents',

  Phase2AppraisalRequest = 'appraisal-request',
  Phase2AppraisalInProgress = 'appraisal-in-progress',
  Phase2AppraisalClosed = 'appraisal-closed',

  Phase3FormalizationRequest = 'formalization-request',
  Phase3ClosingValues = 'closing-values',
  Phase3SignatureDate = 'signature-date',
  Phase3SignatureKit = 'signature-kit',
  Phase3SignatureReady = 'signature-ready',
  Phase3Escriturado = 'escriturado',
  Phase3Fondeo = 'fondeo',
  Phase3Completed = 'completed',
}

export enum Pages {
  Home = 'home',
  Profile = 'profile',
  NewClient = 'newClient',
  NewCredit = 'newCredit',
  Clients = 'clients',
  ClientDetail = 'clientDetail',
  CreditDetail = 'creditDetail',
  Assignation = 'assignation',
  FollowUp = 'followUp',
  FollowUpMore = 'followUpMore',
  Allocation = 'allocation',
  Trash = 'trash',
  Commissions = 'commissions',
  PaidCommissions = 'paidCommissions',
  CommissionsControl = 'commissionsControl',
  AuthorizationCard = 'authorization',
  Celulas = 'celulas',
  Operators = 'operators',
  SavingsFunds = 'savingsFund',
}

export enum CelulaTypes {
  Junior = 'junior',
  Senior = 'senior',
  Externo = 'external',
  AdvisorOperator = 'advisor-operator',
}
