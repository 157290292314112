const LogoutCircle: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M13.78 7.53a.75.75 0 000-1.06l-2-2a.75.75 0 00-1.06 0 .75.75 0 000 1.06l.29.29a.26.26 0 01.06.28.25.25 0 01-.23.15H5.25a.75.75 0 000 1.5h5.59a.25.25 0 01.23.15.26.26 0 01-.06.28l-.29.29a.75.75 0 000 1.06.75.75 0 001.06 0z"></path>
      <path d="M11.25 11.25a2.21 2.21 0 01-1.59-.66A2.25 2.25 0 019 9.45a.24.24 0 00-.24-.2H5.25a2.25 2.25 0 010-4.5H8.8a.24.24 0 00.2-.2 2.25 2.25 0 01.62-1.14 2.21 2.21 0 011.59-.66.08.08 0 00.07 0 .08.08 0 000-.08 6.5 6.5 0 100 8.76.08.08 0 000-.08.08.08 0 00-.03-.1z"></path>
    </svg>
  )
}

export default LogoutCircle
