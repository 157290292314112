import { createContext, useContext, useEffect } from 'react'
import { useMutation } from 'react-query'
import { getBanks, getContactForms, getNotarias } from '../services/catalogs'
import { Bank, ContactForm, Notaria } from '../types/catalogs'
import { useAuth } from './Auth'

type CatalogsContextValue = {
  banks?: Bank[]
  notarias?: Notaria[]
  contactForms?: ContactForm[]
}

export const CatalogsContext = createContext<Partial<CatalogsContextValue>>({})

type ProviderProps = {
  children: React.ReactNode
}

const CatalogsProvider: React.FC<ProviderProps> = ({ children }) => {
  const { isAuth } = useAuth()
  const { data: banks, mutate: getBanksHandler } = useMutation(getBanks)
  const { data: notarias, mutate: getNotariasHandler } =
    useMutation(getNotarias)

  const { data: contactForms, mutate: getContactFormsHandler } =
    useMutation(getContactForms)

  useEffect(() => {
    if (isAuth) {
      getNotariasHandler()
      getBanksHandler()
      getContactFormsHandler()
    }
  }, [isAuth])

  const catalogsValue: CatalogsContextValue = {
    // Data
    notarias,
    banks,
    contactForms,
  }

  return (
    <CatalogsContext.Provider value={catalogsValue}>
      {children}
    </CatalogsContext.Provider>
  )
}

export const useCatalogs = () => {
  return useContext(CatalogsContext)
}

export default CatalogsProvider
