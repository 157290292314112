import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledMonthAllocationsTable } from './styled'
import { ReactBaseElement } from '../../types/react'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import { AllocationRow } from '../../types/allocations'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { getMonthAllocations } from '../../services/allocation'
import { useQuery } from 'react-query'
import { BankCreditListItem } from '../../types/credits'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import Heading from '../../components/atoms/Heading'
import {
  getConditionalStyles,
  parseAllocationForTable,
} from '../../utils/allocations'

type MonthAllocationsTableProps = ReactBaseElement

const MonthAllocationsTable: React.FC<MonthAllocationsTableProps> = () => {
  const { user } = useUser()
  const [stateCredits, setStateCredits] = useState<
    BankCreditListItem[] | undefined
  >(undefined)
  const navigator = useNavigate()

  const { isFetching } = useQuery<BankCreditListItem[]>(
    ['getAllocationsCredits'],
    () => getMonthAllocations(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setStateCredits(data)
      },
    }
  )

  const parsedCredits = useMemo(() => {
    if (stateCredits && stateCredits.length) {
      return parseAllocationForTable(stateCredits)
    }
    return []
  }, [stateCredits])

  if (isFetching || !stateCredits) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando lista de creditos.</StyledDescription>
      </Container>
    )
  }

  const AllocationsColumns = getRoleResourceColumns('allocation', user?.role)
  const AllocationsData = getTableData(AllocationsColumns, parsedCredits)

  const handleClick = (credit: AllocationRow) => {
    navigator(`/credito/${credit.id}`)
  }

  const conditional = getConditionalStyles()
  const currentMonth = new Date().toLocaleDateString('es-MX', {
    month: 'long',
    year: 'numeric',
  })

  return (
    <StyledMonthAllocationsTable>
      <Heading type="3" border color="var(--color-red-2)" margin>
        {`Colocaciones del Mes :: ${currentMonth.toUpperCase()}`}
      </Heading>
      <Table
        columns={AllocationsColumns}
        data={AllocationsData}
        onClick={handleClick}
        conditionalRowStyles={conditional}
        hideSelect
        fileName="colocacion-del-mes"
      />
    </StyledMonthAllocationsTable>
  )
}

export default MonthAllocationsTable
