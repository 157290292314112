import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../../atoms/Avatar'
import { StyledProfileWidget, StyledName } from './styled'

export type ProfileWidgetProps = {
  name: string
  avatar: string
  link: string
}

const ProfileWidget: React.FC<ProfileWidgetProps> = (props) => {
  return (
    <StyledProfileWidget>
      <StyledName>👋 {props.name}</StyledName>
      <Link to={props.link}>
        <Avatar src={props.avatar} />
      </Link>
    </StyledProfileWidget>
  )
}

export default ProfileWidget
