import React from 'react'
import Container from '../../components/atoms/Container'
import { useUser } from '../../providers/User'
import ChangePasswordForm from '../../templates/ChangePasswordCard'
import Layout from '../../templates/Layout'
import UserCelula from '../../templates/UserCelula'
import UserData from '../../templates/UserData'

const ProfilePage = () => {
  const { user } = useUser()
  return (
    <Layout>
      <Container align="center" padding>
        <UserData headline="Mis Datos" description="Informacion de empleado" />
      </Container>
      {user?.celula && (
        <Container align="center" padding>
          <UserCelula headline="Celula" description="Informacion de celula" />
        </Container>
      )}
      <Container align="center" padding>
        <ChangePasswordForm />
      </Container>
    </Layout>
  )
}

export default ProfilePage
