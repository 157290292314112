import Config from '../config/environment'

const { REST_API_URL } = Config

export const getCelulas = async function () {
  const celulasResponse = await fetch(`${REST_API_URL}/celulas`, {
    method: 'GET',
    credentials: 'include',
  })
  const celulasData = await celulasResponse.json()
  if ('error' in celulasResponse) {
    throw new Error(celulasData.error.message)
  }
  return celulasData
}

export const getCelulasPerformance = async function (currentYear: string) {
  const celulasPerformanceResponse = await fetch(
    `${REST_API_URL}/util/celulas-performance?currentYear=${currentYear}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await celulasPerformanceResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}
