import CreditInformation from '../../templates/CreditInformation'
import Layout from '../../templates/Layout'

const CreditDetailPage = () => {
  return (
    <Layout>
      <CreditInformation />
    </Layout>
  )
}

export default CreditDetailPage
