import { BankCreditListItem } from '../types/credits'
import { Owners } from '../types/enums'
import { FollowUpRow } from '../types/follow-up'
import { getCreditColor, PhaseOwner } from './credits'
import { STEPS_OWNERS } from './steps'

export function parseTrashTable(
  credits: BankCreditListItem[],
  languages: {
    [key: string]: string
  }
): FollowUpRow[] {
  const allocations = credits.map((credit) => {
    const operator = Object.keys(credit.owners).find((owner) => {
      const currentOwner = PhaseOwner[credit.phase]

      if (currentOwner === owner) {
        return true
      }
      return false
    })
    const currentOwner = credit.owners[operator as Owners]

    const pesosFormatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })

    const phaseTranslation = languages[`phases.${credit.phase}.title`]
    const phaseStepTranslation =
      languages[`phases.${credit.phase}.steps.${credit.step}`]

    let creditAmount
    if (credit.meta.finalCreditAmount) {
      creditAmount = parseFloat(credit.meta.finalCreditAmount)
    } else if (credit.meta.formalizacionMontoCredito) {
      creditAmount = credit.meta.formalizacionMontoCredito
    } else if (credit.meta.authorizedCredit) {
      creditAmount = credit.meta.authorizedCredit
    } else {
      creditAmount = credit.meta.creditRequested
    }

    const roleOwner = STEPS_OWNERS[credit.step]
    let owner = credit.owners[credit.currentOwner as Owners]
    if (!owner) {
      owner = credit.owners[roleOwner]
    }

    return {
      id: `${credit.code}`,
      visualId: `CR-${credit.code}`,
      advisor: {
        active: owner._id === credit.owners.advisor._id,
        text: credit.owners.advisor.displayName,
      },
      operator: {
        active: owner._id === currentOwner._id,
        text: currentOwner.displayName,
      },
      createdAt: new Date(credit.createdAt || '').toLocaleDateString('es-MX'),
      client: credit.client.name.toUpperCase(),
      bank: credit.bank.name,
      productType: credit.meta.productType.name,
      contactForm: credit.client.meta.contactForm.name,
      creditAmount: pesosFormatter.format(creditAmount),
      step: `${credit.phase} / ${credit.step}`,
      visualStep: {
        text: `${phaseTranslation} / ${phaseStepTranslation}`,
        color: getCreditColor(credit.phase),
      },
    }
  })
  return allocations
}

export function getConditionalStyles() {
  return []
}
