const Settings: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M12.44 8a.23.23 0 01-.06-.16V6.19a.23.23 0 01.06-.19l.75-.94a1.07 1.07 0 00.09-1.21l-.44-.76a1.09 1.09 0 00-1.09-.53l-1.19.18a.22.22 0 01-.16 0L9 1.93a.24.24 0 01-.11-.13L8.44.68a1.07 1.07 0 00-1-.68h-.88a1.07 1.07 0 00-1 .68L5.12 1.8a.24.24 0 01-.12.13l-1.4.81a.22.22 0 01-.16 0l-1.19-.15a1.09 1.09 0 00-1.09.53l-.44.76a1.07 1.07 0 00.09 1.21l.75.91a.23.23 0 01.06.16v1.65a.23.23 0 01-.06.19l-.75.94a1.07 1.07 0 00-.09 1.21l.44.76a1.09 1.09 0 001.09.53l1.19-.18a.22.22 0 01.16 0l1.4.81a.24.24 0 01.11.13l.44 1.12a1.07 1.07 0 001 .68h.88a1.07 1.07 0 001-.68l.44-1.12a.24.24 0 01.13-.13l1.41-.81a.22.22 0 01.16 0l1.19.18a1.09 1.09 0 001.09-.53l.44-.76a1.07 1.07 0 00-.09-1.21zM7 9.25A2.25 2.25 0 119.25 7 2.25 2.25 0 017 9.25z"></path>
    </svg>
  )
}

export default Settings
