import React from 'react'
import FollowUpTable from '../../templates/FollowUpTable'
import FollowUpTiles from '../../templates/FollowUpTiles'
import Layout from '../../templates/Layout'

const FollowUpPage = () => {
  return (
    <Layout>
      <FollowUpTiles title="Seguimiento Personal" />
      <FollowUpTable />
    </Layout>
  )
}

export default FollowUpPage
