import React from 'react'
import { useTranslation } from '../../../providers/I18n'
import { ReactBaseElement } from '../../../types/react'
import Comment from '../Comment'
import DescriptionList from '../DataList'
import { StyledHistory, StyledTimeline, StyledEvent } from './styled'

type HistoryProps = {
  items: {
    type: string
    color: string
    title: string
    img: string
    user: string
    date: Date
    files?: { name: string; url: string }[]
    meta?: {
      [key: string]: string
    }
  }[]
} & ReactBaseElement

const History: React.FC<HistoryProps> = (props) => {
  const { getTranslation } = useTranslation()
  return (
    <StyledHistory>
      <StyledTimeline>
        {props.items.map((item) => {
          const meta = item.meta || {}
          const dataList = Object.keys(meta).map((key) => {
            const fieldTranslated = getTranslation!(`fields.${key}`) || key
            return {
              label: fieldTranslated,
              value: meta[key],
            }
          })
          return (
            <StyledEvent
              type={item.type}
              color={item.color}
              key={item.date.toLocaleString('es-MX', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
              })}
            >
              <Comment
                img={item.img}
                user={item.user}
                title={item.title}
                date={item.date}
                files={item.files}
              />
              <DescriptionList list={dataList} />
            </StyledEvent>
          )
        })}
      </StyledTimeline>
    </StyledHistory>
  )
}

export default History
