import styled from 'styled-components'

export const StyledLayout = styled.div`
  display: flex;
  justify-content: start;
  align-items: left;
  flex-direction: column;
  font-size: var(--fs-xl);
  background-color: var(--color-white);
  height: 100vh;
`

export const StyledContent = styled.div<{
  header: number
}>`
  display: flex;
  height: calc(100vh - ${(props) => `${props.header}px`});
`

export const StyledLayoutBody = styled.div`
  overflow: scroll;
  width: 100%;
`
