import { useDropzone } from 'react-dropzone'
import { Icons } from '../../../types/enums'
import Icon from '../../atoms/Icon'
import { StyledDescription } from '../../styled/Description'
import {
  StyledFileDropperContainer,
  StyledDropZone,
  StyledFilesDropped,
  StyledFile,
} from './styled'

type FileDropperProps = {
  multiple?: boolean
  files?: File[]
  label: string
  onChange: (files: FileWithBuffer[]) => void
}

export type FileWithBuffer = {
  file: File
  fixedName?: string
  buffer: ArrayBuffer
}

const FileDropper = (props: FileDropperProps) => {
  const { acceptedFiles, getRootProps, getInputProps, isFocused } = useDropzone(
    {
      multiple: props.multiple,
      maxFiles: props.multiple ? 5 : 1,
      accept: {
        'application/pdf': [],
      },
      onDrop: async (acceptedFiles: File[]) => {
        const files = acceptedFiles
        const buffersPromises = files.map((file) => {
          return file.arrayBuffer()
        })
        const buffers = await Promise.all(buffersPromises)
        const fileWithBuffer = files.map((file, index) => {
          return {
            file,
            buffer: buffers[index],
          }
        })
        props.onChange(fileWithBuffer)
      },
    }
  )

  const initialFiles = props.files || acceptedFiles
  const fileUploaded = initialFiles.length > 0

  return (
    <StyledFileDropperContainer>
      <StyledDropZone
        isFocuse={isFocused}
        hasFiles={initialFiles.length}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!fileUploaded && <Icon name={Icons.UPLOAD_CIRCLE} />}
        <StyledDescription>{props.label}</StyledDescription>
        {fileUploaded && (
          <StyledFilesDropped>
            {initialFiles.map((file) => (
              <StyledFile key={file.name}>
                <Icon name={Icons.FILE} />
                <span>{file.name}</span>
              </StyledFile>
            ))}
          </StyledFilesDropped>
        )}
      </StyledDropZone>
    </StyledFileDropperContainer>
  )
}

export default FileDropper
