import Config from '../config/environment'

const { REST_API_URL } = Config

export const addCommissionCredit = async function (credit: string) {
  const commissionResponse = await fetch(`${REST_API_URL}/commissions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      creditId: credit,
    }),
    credentials: 'include',
  })
  const data = await commissionResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const getCommissions = async function (userFilter: {
  from?: string
  to?: string
}) {
  const filter = {
    ...(userFilter.from ? { cpfrom: userFilter.from } : {}),
    ...(userFilter.to ? { cpto: userFilter.to } : {}),
  }

  const queryParams = new URLSearchParams(filter).toString()
  const commissionsResponse = await fetch(
    `${REST_API_URL}/commissions/?${queryParams}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await commissionsResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const calculateCommissions = async function () {
  const commissionsResponse = await fetch(
    `${REST_API_URL}/util/calculate-commissions`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await commissionsResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const payCommissions = async function (commissions: string[]) {
  const commissionsResponse = await fetch(
    `${REST_API_URL}/util/pay-commissions`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        commissions,
      }),
    }
  )
  const data = await commissionsResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const updateCommission = async function (
  commission: string,
  newPercentage: number
) {
  const commissionsResponse = await fetch(
    `${REST_API_URL}/commissions/${commission}`,
    {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grantedPercentage: newPercentage,
      }),
    }
  )
  if (commissionsResponse.status !== 204) {
    const data = await commissionsResponse.json()
    if ('error' in data) {
      throw new Error(data.error.message)
    }
  }
  return {
    commissionUpdated: true,
  }
}
