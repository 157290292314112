const MoneyBag: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M9.51 3a.2.2 0 01-.12-.16.25.25 0 010-.21l.65-.84a.41.41 0 00.05-.09 1.16 1.16 0 00-.02-1.16 1.13 1.13 0 00-1-.53H4.88a1.13 1.13 0 00-.95.53 1.16 1.16 0 00-.06 1.14.41.41 0 000 .09l.65.84a.25.25 0 010 .21.2.2 0 01-.03.18C1.77 4.57.5 6.66.5 9.51S2.87 14 7 14s6.5-1.63 6.5-4.48S12.23 4.57 9.51 3zM7.19 7.68c.69.3 1.56.67 1.56 1.57s-.54 1.25-1 1.32a.24.24 0 00-.22.25v.57a.5.5 0 11-1 0v-.55a.25.25 0 00-.25-.25h-.5a.54.54 0 010-1.07h1.74a.25.25 0 00.23-.26c0-.17-.63-.44-.94-.57-.69-.31-1.56-.69-1.56-1.58a1.28 1.28 0 011.06-1.29.26.26 0 00.19-.25V5a.5.5 0 111 0v.56a.25.25 0 00.25.25h.5a.54.54 0 010 1.07h-1.5c-.08 0-.5 0-.5.27s.63.4.94.53z"></path>
    </svg>
  )
}

export default MoneyBag
