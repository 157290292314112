const MinusCircle: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M7 0a7 7 0 107 7 7 7 0 00-7-7zm2.5 8h-5a1 1 0 010-2h5a1 1 0 010 2z"></path>
    </svg>
  )
}

export default MinusCircle
