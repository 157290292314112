import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import FileDropper, {
  FileWithBuffer,
} from '../../../../../components/molecules/FileDropper'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditDocument,
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import { uploadDocuments } from '../../../../../utils/documents'
import { useAlert } from '../../../../../providers/Alert'

const PrequalificationRequest: React.FC<{ refetch: () => Promise<any> }> = (
  props
) => {
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const { getTranslation } = useTranslation()
  const [prequalificationFile, setPrequalificationFile] = useState<
    FileWithBuffer[]
  >([])

  const { data: credit, isLoading } = useQuery<BankCreditDetail>(
    ['getCredit', code],
    () => getCredit(code!),
    {
      refetchOnWindowFocus: false,
    }
  )

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner =
      user!.code === credit.owners.prequalificationOperator?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const prequalificationFileBuffer = prequalificationFile
        ? prequalificationFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'prequalification'
            return fileWithBuffer.file
          })
        : []

      const handleSendPrequalification = async () => {
        setLoading(true)

        const pushedDocuments = await uploadDocuments(
          prequalificationFile,
          `credit/${credit!.code.toString()}`
        )

        const pushedPrequalificationFile = pushedDocuments.find(
          (file) => file.name === 'prequalification'
        )

        addCreditDocument(credit.code, pushedPrequalificationFile!)

        const hasComment = !!commentRef.current?.value
        const hasPrequalificationFile = prequalificationFile.length > 0
        if (!hasComment || !hasPrequalificationFile) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            step: CreditSteps.PrequalificationDocuments,
          }).then(async () => {
            const comment = `Avanzando a Carga de Expediente :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              documents: pushedDocuments,
              comment: comment,
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  owner: credit.owners.prequalificationOperator._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: credit.owners.advisor._id,
                  phase: CreditPhases.Prequalification,
                  step: CreditSteps.PrequalificationDocuments,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                props.refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      content = (
        <Fragment>
          <StyledDescription>
            El cliente ha solicitado precalificación, entregando los documentos
            correspondientes.
          </StyledDescription>
          <StyledDescription>
            Una vez se tenga el resultado, subirlo en este apartado:
          </StyledDescription>
          <FileDropper
            label="Resultado Precalificación"
            onChange={setPrequalificationFile}
            files={prequalificationFileBuffer}
          />
          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          <Button
            color={color}
            loading={loading}
            onClick={handleSendPrequalification}
          >
            Mandar Precalificación
          </Button>
        </Container>
      )
    } else {
      content = (
        <CreditWaitAction owner={credit.owners.prequalificationOperator} />
      )
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default PrequalificationRequest
