import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledClientsTable } from './styled'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { useQuery } from 'react-query'
import { ClientListItem, ClientRow } from '../../types/clients'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import { parseClientsForTable } from '../../utils/clients'
import Input from '../../components/atoms/Input'
import { Icons } from '../../types/enums'
import { getClients } from '../../services/clients'

const ClientsTable: React.FC = () => {
  const { user } = useUser()
  const [search, setSearch] = useState('')
  const [stateClients, setStateClients] = useState<
    ClientListItem[] | undefined
  >(undefined)

  const navigator = useNavigate()
  const { data: clients, isFetching } = useQuery<ClientListItem[]>(
    ['getClients'],
    () => getClients(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setStateClients(data)
      },
    }
  )

  useEffect(() => {
    if (!search) {
      setStateClients(clients)
    } else {
      const newListClients = clients!.filter((client) => {
        return (
          client.code.toString().includes(search) ||
          client.meta.curp.toLowerCase().includes(search) ||
          client.meta.rfc.toLowerCase().includes(search) ||
          client.advisor.displayName.toLowerCase().includes(search) ||
          client.name.toLowerCase().includes(search) ||
          client.meta.contactForm.name.toLowerCase().includes(search)
        )
      })
      setStateClients(newListClients)
    }
  }, [search])

  const parsedClients = useMemo(() => {
    if (stateClients && stateClients.length) {
      return parseClientsForTable(stateClients)
    }
    return []
  }, [stateClients])

  if (isFetching || !stateClients) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando lista de clientes.</StyledDescription>
      </Container>
    )
  }

  const clientsColumns = getRoleResourceColumns('clients', user?.role)
  const clientsData = getTableData(clientsColumns, parsedClients)

  const handleClick = (client: ClientRow) => {
    navigator(`/cliente/${client.id}`)
  }

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const searchValue = event.currentTarget.value.toLowerCase()
    setSearch(searchValue)
  }

  return (
    <StyledClientsTable>
      <Container>
        <Input
          placeholder="Buscar"
          onChange={handleSearch}
          preIcon={Icons.SEARCH}
          value={search}
        ></Input>
      </Container>
      <Table
        columns={clientsColumns}
        data={clientsData}
        hideSelect
        onClick={handleClick}
        fileName="clientes"
      />
    </StyledClientsTable>
  )
}

export default ClientsTable
