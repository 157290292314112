const Users: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
    >
      <path d="M9.25 1a2.81 2.81 0 00-.77.11.26.26 0 00-.16.15.24.24 0 000 .22A4.23 4.23 0 018.34 6a.24.24 0 000 .22.26.26 0 00.16.15 2.81 2.81 0 00.77.11 2.75 2.75 0 000-5.5zM9.72 8h-.34a.27.27 0 00-.24.15.25.25 0 000 .27A6.59 6.59 0 0111 13a2.86 2.86 0 01-.1.73.24.24 0 000 .22.26.26 0 00.2.1H13a1 1 0 001-1V13a4.89 4.89 0 00-4.28-5z"></path>
      <circle cx="4.75" cy="3.75" r="2.75"></circle>
      <path d="M0 13a1 1 0 001 1h7.5a1 1 0 001-1 4.89 4.89 0 00-4.28-5A4.75 4.75 0 000 12.75z"></path>
    </svg>
  )
}

export default Users
