import AuthorizationCard from '../../templates/AuthorizationCard'
import Layout from '../../templates/Layout'

const AuthorizationCardPage = () => {
  return (
    <Layout>
      <AuthorizationCard />
    </Layout>
  )
}

export default AuthorizationCardPage
