import React from 'react'
import { Icons } from '../../../../types/enums'
import DefaultAvatar from './default-avatar'
import PersonPlus from './person-plus'
import SearchCircle from './search-circle'
import Search from './search'
import Filter from './filter'
import Home from './home'
import List from './list'
import PersonCheck from './person-check'
import PersonCross from './person-cross'
import PercentageCircle from './percentage-circle'
import Percentage from './percentage'
import Trash from './trash'
import Books from './books'
import Graph from './graph'
import PersonLock from './person-lock'
import PersonQuestion from './person-question'
import MoneyBag from './money-bag'
import Settings from './settings'
import PersonScreening from './person-screening'
import PersonCrown from './person-crown'
import PersonMinus from './person-minus'
import Calendar from './calendar'
import File from './file'
import FileMinus from './file-minus'
import FilePlus from './file-plus'
import DownloadCircle from './download-circle'
import UploadCircle from './upload-circle'
import FileAttachment from './file-attachment'
import PlusCircle from './plus-circle'
import MinusCircle from './minus-circle'
import LoginCircle from './login-circle'
import LogoutCircle from './logout-circle'
import Bank from './bank'
import WarningBubble from './warning'
import Pencil from './pencil'
import Users from './users'
import SavingsBox from './savings'

export const IconKeys: {
  [key: string]: React.FC
} = {
  [Icons.BANK]: Bank,
  [Icons.DEFAULT_AVATAR]: DefaultAvatar,
  [Icons.LOGIN_CIRCLE]: LoginCircle,
  [Icons.LOGOUT_CIRCLE]: LogoutCircle,
  [Icons.PLUS_CIRCLE]: PlusCircle,
  [Icons.MINUS_CIRCLE]: MinusCircle,
  [Icons.PERCENTAGE_CIRCLE]: PercentageCircle,
  [Icons.PERCENTAGE]: Percentage,
  [Icons.SEARCH_CIRCLE]: SearchCircle,
  [Icons.SEARCH]: Search,
  [Icons.CALENDAR]: Calendar,
  [Icons.FILTER]: Filter,
  [Icons.PENCIL]: Pencil,
  [Icons.PERSON_SCREENING]: PersonScreening,
  [Icons.PERSON_CROWN]: PersonCrown,
  [Icons.PERSON_PLUS]: PersonPlus,
  [Icons.PERSON_MINUS]: PersonMinus,
  [Icons.PERSON_CHECK]: PersonCheck,
  [Icons.PERSON_CROSS]: PersonCross,
  [Icons.PERSON_LOCK]: PersonLock,
  [Icons.PERSON_QUESTION]: PersonQuestion,
  [Icons.HOME]: Home,
  [Icons.LIST]: List,
  [Icons.TRASH]: Trash,
  [Icons.GRAPH]: Graph,
  [Icons.BOOKS]: Books,
  [Icons.MONEY_BAG]: MoneyBag,
  [Icons.SETTINGS]: Settings,
  [Icons.FILE]: File,
  [Icons.FILE_MINUS]: FileMinus,
  [Icons.FILE_PLUS]: FilePlus,
  [Icons.FILE_ATTACHMENT]: FileAttachment,
  [Icons.DOWNLOAD_CIRCLE]: DownloadCircle,
  [Icons.UPLOAD_CIRCLE]: UploadCircle,
  [Icons.USERS]: Users,
  [Icons.SAVINGS_BOX]: SavingsBox,
  [Icons.WARNING_BUBBLE]: WarningBubble,
}
