import { CommissionResponse, CommissionTableRow } from '../types/commissions'

export function parseCommissionForTable(
  commissions: CommissionResponse[],
  options: {
    getTranslation?: (key: string) => string
  },
  paid: boolean
): CommissionTableRow[] {
  const commissionsRows = commissions.map((commission) => {
    const pesosFormatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })

    const lastPayment = commission.payments[commission.payments.length - 1]

    let commissionAmount = commission.granted.amount
    let commissionPercentage = commission.granted.percentage

    if (paid) {
      commissionAmount = commission.paid?.amount
      commissionPercentage = commission.paid?.percentage
    } else {
      if (commission.paid) {
        commissionAmount = commissionAmount - commission.paid?.amount
        commissionPercentage =
          commissionPercentage - commission.paid?.percentage
      }
    }

    const signatureDate = new Date(commission.credit.meta.finalSignatureDate)
    signatureDate.setDate(signatureDate.getDate() + 1)

    const fundingDate = new Date(commission.credit.meta.fundingDate)
    fundingDate.setDate(fundingDate.getDate() + 1)

    const documentsDate = new Date(commission.credit.meta.documentsDate)
    documentsDate.setDate(documentsDate.getDate() + 1)

    return {
      id: `${commission._id}`,
      visualId: `CR-${commission.credit.code}`,
      creditCode: commission.credit.code,
      user: commission.owner.displayName,
      client: commission.credit.client.name.toUpperCase(),
      amount: pesosFormatter.format(commission.credit.meta.finalCreditAmount),
      amountNumber: commission.credit.meta.finalCreditAmount,
      bank: commission.credit.bank.name,
      contactForm: commission.credit.client.meta.contactForm.name,
      notaria: commission.credit.meta.formalizacionNotaria.name,
      privateNeedsPayment: commission.needsPayment,
      privateCelula: commission.celula
        ? commission.celula._id
        : commission.owner._id,
      payments: commission.payments,
      commissionType: options.getTranslation!(
        `celulas.commissions.${commission.type}`
      ),
      lastPayment: lastPayment
        ? new Date(lastPayment.paidAt).toLocaleDateString('es-MX')
        : '--',
      actions: {
        id: commission._id,
      },
      needsPayment: {
        text: commission.needsPayment ? 'Pendiente' : 'Pagado',
        color: commission.needsPayment
          ? 'var(--color-red-2)'
          : 'var(--color-green-2)',
      },
      signatureDate: commission.credit.meta.finalSignatureDate
        ? signatureDate.toLocaleDateString('es-MX')
        : '--',
      fundingDate: commission.credit.meta.fundingDate
        ? fundingDate.toLocaleDateString('es-MX')
        : '--',
      documentsDate: commission.credit.meta.documentsDate
        ? documentsDate.toLocaleDateString('es-MX')
        : '--',
      commissionAmountNumber: commissionAmount,
      commissionAmount: pesosFormatter.format(commissionAmount),
      commissionPercentage: (commissionPercentage * 100).toFixed(4) + '%',
    }
  })
  return commissionsRows
}

export function getConditionalStyles() {
  return [
    {
      when: (row: CommissionTableRow) => row.privateNeedsPayment === true,
      style: {
        backgroundColor: 'var(--color-red-5)',
      },
    },
  ]
}
