import Heading from '../../components/atoms/Heading'
import Layout from '../../templates/Layout'
import SavingFundsTable from '../../templates/SavingFundsTable'

const SavingFundsPage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        Aportaciones Fondo de Ahorro
      </Heading>
      <SavingFundsTable />
    </Layout>
  )
}

export default SavingFundsPage
