const Graph: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M13.25 12.5H.75a.75.75 0 000 1.5h12.5a.75.75 0 000-1.5z"></path>
      <rect x="1" width="3" height="11" rx="0.75"></rect>
      <rect x="5.5" y="5" width="3" height="6" rx="0.75"></rect>
      <rect x="10" y="2" width="3" height="9" rx="0.75"></rect>
    </svg>
  )
}

export default Graph
