import React from 'react'
import { Icons } from '../../../types/enums'
import { ReactBaseElement } from '../../../types/react'
import { StyledError } from '../../styled/Error'
import Icon from '../Icon'
import {
  StyledInputContainer,
  StyledTextContainer,
  StyledLabel,
  StyledText,
} from './styled'

type DateInputProps = {
  label?: string
  placeholder?: string
  required?: boolean
  autocomplete?: string
  error?: string
  type?: 'text' | 'password' | 'email' | 'number'
  preIcon?: Icons
  afterIcon?: Icons
  maxLength?: number
  minLength?: number
  value?: string
  disabled?: boolean
  onChange?: React.ReactEventHandler
  onBlur?: React.ReactEventHandler
} & ReactBaseElement

const DateInput = React.forwardRef(
  (props: DateInputProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <StyledInputContainer>
        {props.preIcon && <Icon name={props.preIcon} />}
        <StyledLabel>{props.label}</StyledLabel>
        <StyledTextContainer>
          <StyledText
            type={'date'}
            placeholder={props.placeholder || '...'}
            required={props.required}
            autoComplete={props.autocomplete}
            ref={ref}
            maxLength={props.maxLength}
            minLength={props.minLength}
            onChange={props.onChange}
            onBlur={props.onBlur}
            defaultValue={props.value}
            disabled={props.disabled}
          />
          {props.error && <StyledError>{props.error}</StyledError>}
        </StyledTextContainer>
      </StyledInputContainer>
    )
  }
)

export default DateInput
