import { Mappeable } from '../types/objects'

export const convertArrayOfObjectsToCSV = (
  headers: Mappeable<string>,
  array: Mappeable<any>[]
) => {
  let result: string
  const columnDelimiter = ','
  const lineDelimiter = '\n'
  const keys = Object.values(headers)
  const keysMap = Object.keys(headers)

  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  array.forEach((item) => {
    let ctr = 0
    keysMap.forEach((key) => {
      if (ctr > 0) result += columnDelimiter
      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export const downloadCSV = (
  headers: Mappeable<string>,
  array: Mappeable<any>[],
  fileName?: string
) => {
  const link = document.createElement('a')
  const csv = convertArrayOfObjectsToCSV(headers, array)
  if (csv == null) return

  const filename = `${fileName || 'export'}.csv`

  const blob = new Blob([csv], { type: 'text/csv' })

  link.setAttribute('href', window.URL.createObjectURL(blob))
  link.setAttribute('download', filename)
  link.click()
}
