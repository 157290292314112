import React from 'react'
import { Icons } from '../../../types/enums'
import { ReactContainerElement } from '../../../types/react'
import Icon from '../../atoms/Icon'
import Spinner from '../../atoms/Spinner'
import { StyledButton } from './styled'

export type ButtonStyles = {
  priority?: 'primary' | 'secondary'
  disabled?: boolean
  color?: string
}

export type ButtonProps = {
  preIcon?: Icons
  afterIcon?: Icons
  loading?: boolean
  type?: 'button' | 'submit'
  children: React.ReactNode
  onClick?: React.MouseEventHandler
} & ReactContainerElement &
  ButtonStyles

const Button: React.FC<ButtonProps> = (props) => {
  const onClickHandler = props.type !== 'submit' ? props.onClick : undefined
  const buttonType = props.type || 'button'

  if (props.loading) {
    return <Spinner type="bar" />
  }

  return (
    <StyledButton
      type={buttonType}
      priority={props.priority || 'primary'}
      disabled={props.disabled}
      onClick={onClickHandler}
      color={props.color}
    >
      {props.preIcon && <Icon name={props.preIcon} />}
      {props.children}
      {props.afterIcon && <Icon name={props.afterIcon} />}
    </StyledButton>
  )
}

export default Button
