import PagesConfig from '../config/pages'
import RolesConfig from '../config/role'
import { ConfigPage } from '../types/config'
import { Pages } from '../types/enums'

export function getPageConfig(page: Pages): ConfigPage {
  return PagesConfig[page]
}

export function getRolePages(role: string, hidden?: boolean) {
  const { pages } = RolesConfig[role]
  const pagesConfig = pages.reduce((pages: ConfigPage[], current) => {
    const pageConfig = getPageConfig(current)
    if (!pageConfig.hidden || (pageConfig.hidden && hidden)) {
      pages.push(pageConfig)
    }

    return pages
  }, [])
  return pagesConfig
}
