import styled from 'styled-components'

export type BackgroundProps = {
  src: string
}

export const StyledBackground = styled.div<BackgroundProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.1;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`
