import BackgroundLayout from '../../templates/BackgroundLayout'
import LogoutHandler from '../../templates/LogoutHandler'

const LogoutPage = () => {
  return (
    <BackgroundLayout>
      <LogoutHandler />
    </BackgroundLayout>
  )
}

export default LogoutPage
