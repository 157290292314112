import styled from 'styled-components'

export const StyledComment = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 0.5rem;
`

export const StyledCommentData = styled.div`
  display: inline-block;
`

export const StyledCommentTitle = styled.div`
  font-size: var(--fs-md);
  white-space: pre-wrap;
`

export const StyledCommentUser = styled.div`
  font-size: var(--fs-sm);
  color: var(--color-gray-2);
  font-weight: bold;
`

export const StyledCommentDate = styled.div`
  font-size: var(--fs-sm);
  color: var(--color-gray-3);
`

export const StyledCommentImage = styled.div`
  display: inline-block;
  margin-right: 1rem;
`

export const StyledAttachment = styled.div`
  flex: 1;
  font-size: var(--fs-xs);
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
  color: var(--color-gray-1);
  font-weight: bold;
`
