import React, { FormEvent, useRef } from 'react'
import Button from '../../components/molecules/Button'
import Heading from '../../components/atoms/Heading'
import Input from '../../components/atoms/Input'
import Card from '../../components/atoms/Card'
import { StyledLoginForm } from './styled'
import { Icons } from '../../types/enums'
import Container from '../../components/atoms/Container'
import { StyledError } from '../../components/styled/Error'
import { StyledDescription } from '../../components/styled/Description'
import { useAuth } from '../../providers/Auth'

const LoginForm: React.FC = () => {
  const { login, isTryingToLogIn, loginFailed } = useAuth()

  const usernameRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const headline = 'CRM Autenticación'
  let buttonText = 'Entrar'
  let feedback = 'Ingresa tus credenciales'
  if (isTryingToLogIn) {
    feedback = 'Validando credenciales'
    buttonText = '...'
  }

  let errorText
  if (loginFailed) {
    errorText = 'Oops... Incorrecto, intenta de nuevo'
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (usernameRef?.current?.value && passwordRef?.current?.value) {
      login!({
        username: usernameRef?.current?.value,
        password: passwordRef?.current?.value,
      })
    }
  }

  const Header = (
    <Container align="left" padding>
      <Heading type="3">{headline}</Heading>
      {feedback && <StyledDescription>{feedback}</StyledDescription>}
    </Container>
  )
  const Footer = (
    <Container align="right" direction="row" padding>
      <Button
        disabled={isTryingToLogIn}
        loading={isTryingToLogIn}
        afterIcon={Icons.LOGIN_CIRCLE}
        type="submit"
      >
        {buttonText}
      </Button>
    </Container>
  )
  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <Card header={Header} footer={Footer}>
        <Input
          label="Usuario"
          ref={usernameRef}
          required
          autocomplete="moreuser"
        />
        <Input
          label="Contraseña"
          type="password"
          ref={passwordRef}
          required
          autocomplete="morepassword"
        />
        {errorText && (
          <Container align="center">
            <StyledError>{errorText}</StyledError>
          </Container>
        )}
      </Card>
    </StyledLoginForm>
  )
}

export default LoginForm
