const SavingsBox: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
    >
      <path d="M14 3.25a1.76 1.76 0 00-1.75-1.75H1.75A1.76 1.76 0 000 3.25v7.5a1.76 1.76 0 001.75 1.75.25.25 0 01.25.25v.5a.76.76 0 00.75.75h1.5a.76.76 0 00.75-.75v-.5a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25v.5a.76.76 0 00.75.75h1.5a.76.76 0 00.75-.75v-.5a.25.25 0 01.25-.25A1.76 1.76 0 0014 10.75zM3 9.5a.5.5 0 01-1 0v-5a.5.5 0 011 0zm7.74-2.25a.23.23 0 00.12.25l.18.1a.74.74 0 01.27 1 .72.72 0 01-.65.38.74.74 0 01-.37-.1l-.18-.11a.26.26 0 00-.28 0 2.3 2.3 0 01-.43.25.25.25 0 00-.15.23v.25a.75.75 0 01-1.5 0v-.21a.25.25 0 00-.15-.23 2.3 2.3 0 01-.43-.25.26.26 0 00-.28 0l-.18.11a.74.74 0 01-.37.1.72.72 0 01-.65-.38A.74.74 0 016 7.6l.18-.1a.23.23 0 00.12-.25 2.78 2.78 0 010-.5.23.23 0 00-.12-.25L6 6.4a.75.75 0 01.75-1.3l.18.11a.26.26 0 00.28 0 2.3 2.3 0 01.43-.25.25.25 0 00.15-.23V4.5a.75.75 0 011.5 0v.21a.25.25 0 00.15.23 2.3 2.3 0 01.43.25.26.26 0 00.28 0l.18-.11A.75.75 0 0111 6.4l-.18.1a.23.23 0 00-.12.25 2.78 2.78 0 01.04.5z"></path>
      <circle cx="8.5" cy="7" r="1.25"></circle>
    </svg>
  )
}

export default SavingsBox
