import React from 'react'
import BounceLoader from 'react-spinners/BounceLoader'
import BackgroundLayout from '../../templates/BackgroundLayout'
import { LoadingCardContainer } from './styled'
import { StyledDescription } from '../../components/styled/Description'
import { useUser } from '../../providers/User'

const LoadingPage = () => {
  const { isFetchingUser } = useUser()

  return (
    <BackgroundLayout>
      <LoadingCardContainer align="center">
        <BounceLoader
          loading={isFetchingUser}
          color="var(--color-red-1)"
          size={50}
        />
        <StyledDescription>Revisando Sesión de Usuario...</StyledDescription>
      </LoadingCardContainer>
    </BackgroundLayout>
  )
}

export default LoadingPage
