import React from 'react'
import { ReactContainerElement } from '../../../types/react'
import { StyledPrintContainer } from './styled'

type PrintContainerProps = ReactContainerElement

const PrintContainer: React.FC<PrintContainerProps> = (props) => {
  return (
    <StyledPrintContainer className={props.className}>
      {props.children}
    </StyledPrintContainer>
  )
}

export default PrintContainer
