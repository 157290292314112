const SearchCircle: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <circle cx="6.25" cy="6.25" r="1.5"></circle>
      <path d="M7 0a7 7 0 107 7 7 7 0 00-7-7zm3.28 10.28a.75.75 0 01-1.06 0L7.91 9a.26.26 0 00-.3-.05 2.94 2.94 0 01-1.36.33 3 3 0 113-3 2.94 2.94 0 01-.33 1.36.26.26 0 00.05.3l1.31 1.31a.75.75 0 010 1.03z"></path>
    </svg>
  )
}

export default SearchCircle
