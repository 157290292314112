import React from 'react'
import { ReactContainerElement } from '../../../types/react'
import { StyledHeading } from './styled'

export type HeadingStyles = {
  type: '1' | '2' | '3' | '4' | '5' | '6'
  align?: 'center' | 'left' | 'right'
  color?: string
  border?: boolean
  margin?: boolean
}

export type HeadingProps = ReactContainerElement & HeadingStyles

const Heading: React.FC<HeadingProps> = (props) => {
  return (
    <StyledHeading
      as={`h${props.type}`}
      type={props.type}
      margin={props.margin}
      border={props.border}
      color={props.color || 'var(--color-red-1)'}
      align={props.align || 'left'}
    >
      {props.children}
    </StyledHeading>
  )
}

export default Heading
