import Container from '../../components/atoms/Container'
import CelulaTypesData from '../../templates/CelulaTypesData'
import Layout from '../../templates/Layout'

const CommissionsControlPage = () => {
  return (
    <Layout>
      <Container align="center" padding>
        <CelulaTypesData
          headline="Tipos de Celulas"
          description="Control de comisiones por tipos de celula"
        />
      </Container>
    </Layout>
  )
}

export default CommissionsControlPage
