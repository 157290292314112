import {
  ClientInformation,
  ClientListItem,
  ClientPayload,
  ClientRow,
} from '../types/clients'

export function transformClientInput(client: ClientInformation): ClientPayload {
  const secondParticipantMeta = client.secondParticipantMeta && {
    name: client.secondParticipantMeta.name,
    curp: client.secondParticipantMeta.curp,
    email: client.secondParticipantMeta.email,
    phone: client.secondParticipantMeta.phone,
    rfc: client.secondParticipantMeta.rfc,
    maritalStatus: client.secondParticipantMeta.maritalStatus.label,
    maritalRegimen: client.secondParticipantMeta.maritalRegimen.label,
    state: client.secondParticipantMeta.state.label,
    city: client.secondParticipantMeta.city,
    dateOfBirth: client.secondParticipantMeta.dateOfBirth,
    relation: client.secondParticipantMeta.relation.label,
    street: client.secondParticipantMeta.street,
    neighborhood: client.secondParticipantMeta.neighborhood,
    zipcode: client.secondParticipantMeta.zipcode,
  }

  return {
    name: client.name,
    meta: {
      contactForm: client.contactForm.value,
      curp: client.curp,
      email: client.email,
      phone: client.phone,
      rfc: client.rfc,
      maritalStatus: client.maritalStatus.label,
      maritalRegimen: client.maritalRegimen.label,
      state: client.state.label,
      city: client.city,
      street: client.street,
      neighborhood: client.neighborhood,
      zipcode: client.zipcode,
      dateOfBirth: client.dateOfBirth,
      secondParticipant: client.secondParticipant.value === '1',
      secondParticipantMeta: secondParticipantMeta,
    },
  }
}

export function parseClientsForTable(clients: ClientListItem[]): ClientRow[] {
  const parsedClients = clients.map((client) => {
    return {
      id: `${client.code}`,
      visualId: `CL-${client.code}`,
      advisor: client.advisor.displayName,
      createdAt: new Date(client.createdAt || '').toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
      name: client.name.toUpperCase(),
      contactForm: client.meta.contactForm.name,
      contact: `${client.meta.phone} / ${client.meta.email}`,
      curp: client.meta.curp,
      rfc: client.meta.rfc,
    }
  })
  return parsedClients
}
