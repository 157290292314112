import Config from '../config/environment'

const { REST_API_URL } = Config

export const getTrash = async function () {
  const filter = {
    isActive: 'false',
  }

  const queryParams = new URLSearchParams(filter).toString()
  const creditsReponse = await fetch(
    `${REST_API_URL}/credits/?${queryParams}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await creditsReponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}
