import React from 'react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import Layout from '../../templates/Layout'
import NewClientFlow from '../../templates/NewClientFlow'

const NewClientPage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        Nuevo Cliente
      </Heading>
      <Container align="center">
        <NewClientFlow />
      </Container>
    </Layout>
  )
}

export default NewClientPage
