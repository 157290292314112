const DefaultAvatar: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M7 0a7 7 0 107 7 7 7 0 00-7-7zm0 3a2 2 0 11-2 2 2 2 0 012-2zm3.14 7.82a4.77 4.77 0 01-6.28 0 .25.25 0 010-.35 4.14 4.14 0 016.33 0 .25.25 0 01-.05.35z"></path>
    </svg>
  )
}

export default DefaultAvatar
