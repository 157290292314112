import React from 'react'
import Card from '../../components/atoms/Card'
import DescriptionList from '../../components/molecules/DataList'
import { StyledContent, StyledUserCelula } from './styled'
import { ReactBaseElement } from '../../types/react'
import { useTranslation } from '../../providers/I18n'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import { StyledDescription } from '../../components/styled/Description'
import { useUser } from '../../providers/User'

type UserCelulaProps = {
  headline: string
  description?: string
} & ReactBaseElement

const UserCelula: React.FC<UserCelulaProps> = (props) => {
  const { user } = useUser()
  const { getTranslation } = useTranslation()

  const userData = []
  if (user?.celula) {
    const celulaTypeKey = `celulas.types.${user?.celula.type}`
    userData.push({
      label: 'Nombre',
      value: `Celula ${user?.celula.name}`,
    })
    userData.push({
      label: 'Tipo Celula',
      value: getTranslation!(celulaTypeKey),
    })
    userData.push({
      label: 'Desde',
      value: new Date(user.celula.from || '').toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    })
  }

  const Header = (
    <Container align="left" padding>
      <Heading type="3">{props.headline}</Heading>
      {props.description && (
        <StyledDescription>{props.description}</StyledDescription>
      )}
    </Container>
  )

  return (
    <StyledUserCelula>
      <Card header={Header}>
        <StyledContent>
          <DescriptionList list={userData} />
        </StyledContent>
      </Card>
    </StyledUserCelula>
  )
}

export default UserCelula
