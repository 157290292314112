import Config from '../config/environment'
import { CreditPhases } from '../types/enums'
import {
  OperatorPatchResponse,
  OperatorUpdatePayload,
} from '../types/operators'

const { REST_API_URL } = Config

export const getOperators = async function () {
  const operators = await fetch(`${REST_API_URL}/operators`, {
    method: 'GET',
    credentials: 'include',
  })
  const data = await operators.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const getNextOperator = async function (phase: CreditPhases) {
  const nextOperatorResponse = await fetch(
    `${REST_API_URL}/operators/next/${phase}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await nextOperatorResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const getOperatorsWorkload = async function () {
  const operatorsWorkload = await fetch(
    `${REST_API_URL}/util/operators-workload`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await operatorsWorkload.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const updateOperator = async function (payload: OperatorUpdatePayload) {
  const { operator, ...rest } = payload
  const updatedOperatorResponse = await fetch(
    `${REST_API_URL}/operators/${operator}`,
    {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rest),
    }
  )
  if (updatedOperatorResponse.status !== 204) {
    const data = (await updatedOperatorResponse.json()) as OperatorPatchResponse
    if ('error' in data) {
      throw new Error(data.error.message)
    }
  }
  return {
    userUpdated: true,
  }
}
