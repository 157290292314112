const WarningBubble: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
    >
      <path d="M1.5 14a1.5 1.5 0 01-1.41-2L1 9.57a.29.29 0 000-.09V2a2 2 0 012-2h9a2 2 0 012 2v9a2 2 0 01-2 2H5.38l-3.49 1a1.74 1.74 0 01-.39 0zM3 1.5a.5.5 0 00-.5.5v7.66a.8.8 0 010 .26l-1 2.58 3.62-1a.69.69 0 01.2 0H12a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5z"></path>
      <circle cx="7.5" cy="9.25" r="1.25"></circle>
      <path d="M8.5 6a1 1 0 01-2 0V3.5a1 1 0 012 0z"></path>
    </svg>
  )
}

export default WarningBubble
