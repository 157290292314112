import { formatCurrency } from '../../../../../utils/currency'
import { StyledDescription } from '../../../../styled/Description'
import {
  StyledListCell,
  StyledNegativeMessage,
  StyledPositiveMessage,
} from './styled'

type ListCellProps = {
  data: {
    payments: {
      _id: string
      amount: number
      description?: string
      paidAt: Date
    }[]
  }
}

const ListCell = (props: ListCellProps) => {
  if (!props.data.payments || props.data.payments.length === 0) {
    return (
      <StyledListCell>
        <StyledDescription>No se ha efectuado ningún pago.</StyledDescription>
      </StyledListCell>
    )
  }

  return (
    <StyledListCell>
      <ul>
        {props.data.payments.map((payment) => {
          const paymentType = payment.amount > 0 ? 'Pago' : 'Reducción'
          let paymentText = `${paymentType} de ${formatCurrency(
            payment.amount
          )} el ${new Date(payment.paidAt).toLocaleDateString('es-MX', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}`
          if (payment.description) {
            paymentText += ` (${payment.description})`
          }
          return (
            <li key={payment._id}>
              {payment.amount > 0 ? (
                <StyledPositiveMessage>{paymentText}</StyledPositiveMessage>
              ) : (
                <StyledNegativeMessage>{paymentText}</StyledNegativeMessage>
              )}
            </li>
          )
        })}
      </ul>
    </StyledListCell>
  )
}

export default ListCell
