import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from '../../../providers/I18n'
import { Icons } from '../../../types/enums'
import { ReactBaseElement } from '../../../types/react'
import Icon from '../../atoms/Icon'
import Spinner from '../../atoms/Spinner'
import { StyledDescription } from '../../styled/Description'
import { FileWithBuffer } from '../FileDropper'
import {
  StyledFileItem,
  StyledFileActions,
  StyledFileInfo,
  StyledFileData,
} from './styled'

type FileItemProps = {
  id?: string
  url: string
  uploadedAt?: Date
  onChange?: (files: FileWithBuffer[]) => void
} & ReactBaseElement

const FileItem: React.FC<FileItemProps> = (props) => {
  const { getTranslation } = useTranslation()
  const [loading, setLoading] = useState(false)

  const documentName = getTranslation!(`files.${props.id}`) || props.id

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'application/pdf': [],
    },
    onDrop: async (acceptedFiles: File[]) => {
      const files = acceptedFiles
      const buffersPromises = files.map((file) => {
        return file.arrayBuffer()
      })
      const buffers = await Promise.all(buffersPromises)
      const fileWithBuffer = files.map((file, index) => {
        return {
          file: file,
          fixedName: props.id || file.name,
          buffer: buffers[index],
        }
      })
      setLoading(true)
      props.onChange!(fileWithBuffer)
    },
  })

  useEffect(() => {
    setLoading(false)
  }, [props.uploadedAt])

  const initialFiles = acceptedFiles
  const fileUploaded = initialFiles.length > 0

  return (
    <StyledFileItem>
      <StyledFileData>
        <StyledFileInfo target="_blank" href={props.url}>
          <Icon name={Icons.FILE} />
          <StyledDescription>
            {documentName} /{' '}
            {props.uploadedAt
              ? new Date(props.uploadedAt).toLocaleString('es-MX', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
              : 'Generada por sistema'}
          </StyledDescription>
        </StyledFileInfo>
        {props.onChange && (
          <StyledFileActions>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {loading ? (
                <Spinner type="bar" />
              ) : (
                <span>
                  <Icon name={Icons.FILE_PLUS} />
                  {fileUploaded && (
                    <div>
                      {initialFiles.map((file) => (
                        <span key={file.name}>{file.name}</span>
                      ))}
                    </div>
                  )}
                </span>
              )}
            </div>
          </StyledFileActions>
        )}
      </StyledFileData>
    </StyledFileItem>
  )
}

export default FileItem
