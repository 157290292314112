import styled from 'styled-components'
import { StyledAvatar, StyledDefaultAvatar } from '../../atoms/Avatar/styled'

export const StyledAvatarInput = styled.div`
  position: relative;
  margin-bottom: var(--spc-lg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;

  ${StyledAvatar} {
    position: relative;
    width: 6rem;
    height: 6rem;

    border: 3px solid;
    border-color: var(--color-gray-5);
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }
  }

  ${StyledDefaultAvatar} {
    position: relative;
    font-size: 6rem;

    border: 3px solid;
    border-color: var(--color-gray-5);
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }
  }
`
