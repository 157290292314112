import styled from 'styled-components'

export const StyledCircleImage = styled.div<{
  disabled: boolean
}>`
  position: relative;

  a {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  ${(props) =>
    props.disabled
      ? `
      filter: blur(5px);
  `
      : `
      cursor: pointer;
      `}
`

export const StyledCircle = styled.div<{
  image: string
  color: string
}>`
  width: 50px;
  height: 50px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: 2px solid black;

  margin-bottom: 5px;
  transition: 1s all ease;

  &:hover {
    transform: scale(1.2);
  }
`
