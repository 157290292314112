import styled from 'styled-components'

export const StyledDescription = styled.p<{
  color?: string
}>`
  font-size: var(--fs-sm);
  color: ${(props) => props.color || 'var(--color-gray-2)'};
  font-weight: lighter;
  margin-bottom: 0.5rem;
`
