import styled from 'styled-components'

export const StyledCard = styled.div`
  background-color: var(--color-white);
  border-radius: 10px;
  border: 1px solid var(--color-gray-5);
  border-bottom: 4px solid var(--color-gray-5);
  margin-bottom: 1rem;
  height: 100%;
`

export const StyledCardHeader = styled.div`
  min-height: var(--spc-xl);
  border-bottom: 1px solid var(--color-gray-5);
`

export const StyledCardContent = styled.div`
  padding: var(--spc-sm);
`

export const StyledCardFooter = styled.div`
  min-height: var(--spc-xl);
  border-top: 1px solid var(--color-gray-5);
`
