import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledAuthExpiredCreditsTable } from './styled'
import { ReactBaseElement } from '../../types/react'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import { AllocationRow } from '../../types/allocations'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { getAuthExpiredCredits } from '../../services/allocation'
import { useQuery } from 'react-query'
import { BankCreditListItem } from '../../types/credits'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import Heading from '../../components/atoms/Heading'
import {
  parseBankCreditForTable,
  getConditionalStyles,
} from '../../utils/credits'
import { useTranslation } from '../../providers/I18n'

type AuthExpiredCreditsTableProps = ReactBaseElement

const AuthExpiredCreditsTable: React.FC<AuthExpiredCreditsTableProps> = () => {
  const { user } = useUser()
  const { languages } = useTranslation()
  const [stateCredits, setStateCredits] = useState<
    BankCreditListItem[] | undefined
  >(undefined)
  const navigator = useNavigate()

  const { isFetching } = useQuery<BankCreditListItem[]>(
    ['getAuthExpiredCredits'],
    () => getAuthExpiredCredits(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setStateCredits(data)
      },
    }
  )

  const parsedCredits = useMemo(() => {
    if (stateCredits && stateCredits.length) {
      return parseBankCreditForTable(stateCredits, languages!)
    }
    return []
  }, [stateCredits])

  if (isFetching || !stateCredits) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>
          Cargando lista de creditos a expirar.
        </StyledDescription>
      </Container>
    )
  }

  const followUpColumns = getRoleResourceColumns('followUp', user?.role)
  const followUpData = getTableData(followUpColumns, parsedCredits)

  const handleClick = (credit: AllocationRow) => {
    navigator(`/credito/${credit.id}`)
  }

  const conditional = getConditionalStyles(user!)
  const expiredDate = new Date()
  expiredDate.setDate(expiredDate.getDate() + 15)
  const expirationDate = expiredDate.toLocaleDateString('es-MX', {
    day: 'numeric',
    month: 'long',
  })

  return (
    <StyledAuthExpiredCreditsTable>
      <Heading type="3" border color="var(--color-red-2)" margin>
        {`Autorizaciones a expirar antes de ${expirationDate.toUpperCase()}`}
      </Heading>
      <Table
        columns={followUpColumns}
        data={followUpData}
        onClick={handleClick}
        conditionalRowStyles={conditional}
        hideSelect
        fileName="colocacion-del-mes"
      />
    </StyledAuthExpiredCreditsTable>
  )
}

export default AuthExpiredCreditsTable
