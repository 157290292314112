import React, { Fragment, useState } from 'react'
import { StyledClientInformation, StyledEditButton } from './styled'
import { ReactBaseElement } from '../../types/react'
import Heading from '../../components/atoms/Heading'
import Container from '../../components/atoms/Container'
import DescriptionList from '../../components/molecules/DataList'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { getClient, updateClient } from '../../services/clients'
import Spinner from '../../components/atoms/Spinner'
import { StyledDescription } from '../../components/styled/Description'
import { ClientBankCredit, ClientResponse } from '../../types/clients'
import { StyledColumn, StyledColumns } from '../../components/styled/Columns'
import List from '../../components/atoms/List'
import { CreditPhases, Icons } from '../../types/enums'
import Icon from '../../components/atoms/Icon'
import { useTranslation } from '../../providers/I18n'
import { getCreditColor } from '../../utils/credits'
import Button from '../../components/molecules/Button'
import { useModal } from 'react-modal-hook'
import EditClientName from '../../modals/EditClientName'

type ClientInformationProps = ReactBaseElement

function getClientBankDescription(bankCredit: ClientBankCredit): string {
  const pesosFormatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  })
  const bankName = bankCredit.bank.name
  const creditTypeAndProduct = `${bankCredit.meta.creditType.name} / ${bankCredit.meta.productType.name}`
  const bankTerms = bankCredit.meta.terms

  let creditAmount
  if (bankCredit.meta.finalCreditAmount) {
    creditAmount = parseFloat(bankCredit.meta.finalCreditAmount)
  } else if (bankCredit.meta.formalizacionMontoCredito) {
    creditAmount = bankCredit.meta.formalizacionMontoCredito
  } else if (bankCredit.meta.authorizedCredit) {
    creditAmount = bankCredit.meta.authorizedCredit
  } else {
    creditAmount = bankCredit.meta.creditRequested
  }

  const creditRequested = pesosFormatter.format(creditAmount)
  return `${bankName} - ${creditRequested} - ${bankTerms} - ${creditTypeAndProduct}`
}

const ClientInformation: React.FC<ClientInformationProps> = () => {
  const { code } = useParams()
  const { getTranslation } = useTranslation()
  const [editField, setEditField] = useState('')
  const {
    data: client,
    isLoading,
    refetch,
  } = useQuery<ClientResponse>(['getClient', code], () => getClient(code!), {
    refetchOnWindowFocus: false,
  })

  const [showEditModal, hideEditModal] = useModal(
    ({ in: open }) => (
      <EditClientName
        open={open}
        onSubmit={async (newName) => {
          if (client) {
            await updateClient(client.code, { name: newName })
            refetch()
          }
        }}
        onClose={() => {
          setEditField('')
          hideEditModal()
        }}
        field={editField}
      />
    ),
    [editField]
  )

  const handleOpenEditModal = (field: string) => () => {
    setEditField(field)
    showEditModal()
  }

  if (isLoading || !client) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando información de cliente.</StyledDescription>
      </Container>
    )
  }

  const clientData = []
  const clientDataSecondary = []
  if (client) {
    clientData.push(
      {
        label: 'Folio',
        value: 'CL-' + client.code!.toString() || '',
      },
      {
        label: 'Fecha Creación',
        value: new Date(client.createdAt!).toLocaleDateString('es-MX', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      },
      {
        label: 'Asesora',
        value: client.advisor.displayName || '',
      },
      {
        label: 'Forma de Contacto',
        value: client.meta.contactForm.name || '',
      },
      {
        label: 'Email',
        value: client.meta.email || '',
      },
      {
        label: 'Teléfono',
        value: client.meta.phone || '',
      },
      {
        label: 'RFC',
        value: client.meta.rfc || '',
      },
      {
        label: 'CURP',
        value: client.meta.curp || '',
      },
      {
        label: 'Fecha de Nacimiento',
        value: client.meta.dateOfBirth || '',
      }
    )

    clientDataSecondary.push({
      label: 'Estado Civil',
      value: client.meta.maritalStatus || '',
    })

    if (client.meta.maritalStatus === 'Casado') {
      clientDataSecondary.push({
        label: 'Regimen Matrimonial',
        value: client.meta.maritalRegimen || '',
      })
    }

    clientDataSecondary.push(
      {
        label: 'Estado',
        value: client.meta.state || '',
      },
      {
        label: 'Municipio',
        value: client.meta.city || '',
      },
      {
        label: 'Calle y Numero',
        value: client.meta.street || '',
      },
      {
        label: 'Colonía',
        value: client.meta.neighborhood || '',
      },
      {
        label: 'Codigo Postal',
        value: client.meta.zipcode || '',
      }
    )
  }

  const secondParticipantData = []
  const secondParticipantDataSecondary = []
  if (client.meta.secondParticipant) {
    secondParticipantData.push(
      {
        label: 'Relación',
        value: client.meta.secondParticipantMeta?.relation || '',
      },
      {
        label: 'Nombre',
        value: client.meta.secondParticipantMeta?.name || '',
      },
      {
        label: 'Email',
        value: client.meta.secondParticipantMeta?.email || '',
      },
      {
        label: 'Teléfono',
        value: client.meta.secondParticipantMeta?.phone || '',
      },
      {
        label: 'RFC',
        value: client.meta.secondParticipantMeta?.rfc || '',
      },
      {
        label: 'CURP',
        value: client.meta.secondParticipantMeta?.curp || '',
      },
      {
        label: 'Fecha de Nacimiento',
        value: client.meta.secondParticipantMeta?.dateOfBirth || '',
      }
    )

    secondParticipantDataSecondary.push({
      label: 'Estado Civil',
      value: client.meta.secondParticipantMeta?.maritalStatus || '',
    })

    if (client.meta.secondParticipantMeta?.maritalStatus === 'Casado') {
      secondParticipantDataSecondary.push({
        label: 'Regimen Matrimonial',
        value: client.meta.secondParticipantMeta?.maritalRegimen || '',
      })
    }

    secondParticipantDataSecondary.push(
      {
        label: 'Estado',
        value: client.meta.secondParticipantMeta?.state || '',
      },
      {
        label: 'Municipio',
        value: client.meta.secondParticipantMeta?.city || '',
      },
      {
        label: 'Calle y Numero',
        value: client.meta.secondParticipantMeta?.street || '',
      },
      {
        label: 'Colonía',
        value: client.meta.secondParticipantMeta?.neighborhood || '',
      },
      {
        label: 'Codigo Postal',
        value: client.meta.secondParticipantMeta?.zipcode || '',
      }
    )
  }

  const clientDocuments = client.documents.reduce<
    { id: string; content: JSX.Element }[]
  >((activeDocuments, document) => {
    const documentName = getTranslation!(`files.${document.name}`)

    if (document.isActive) {
      activeDocuments.push({
        id: document.name,
        content: (
          <a target="_blank" href={document.url}>
            <Container align="left" direction="row" padding>
              <Icon name={Icons.FILE} />
              <StyledDescription>
                {documentName} /{' '}
                {new Date(document.uploadedAt || '').toLocaleString('es-MX', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })}
              </StyledDescription>
            </Container>
          </a>
        ),
      })
    }

    return activeDocuments
  }, [])

  const clientCredits = client.credits.map((credit) => {
    const color = getCreditColor(credit.phase as CreditPhases)
    return {
      id: credit._id,
      content: (
        <Link to={`/credito/${credit.code}`}>
          <Container align="left" direction="row" padding>
            <Icon name={Icons.BANK} color={color} />
            <StyledDescription color={color}>
              <b>{getClientBankDescription(credit)}</b>
            </StyledDescription>
          </Container>
        </Link>
      ),
    }
  })

  const EditClientNameButton = (
    <StyledEditButton onClick={handleOpenEditModal('clientName')}>
      Editar
    </StyledEditButton>
  )

  return (
    <StyledClientInformation>
      <Heading type="2" border margin>
        {client.name.toUpperCase()}
        {EditClientNameButton}
      </Heading>
      <StyledColumns>
        <StyledColumn>
          <DescriptionList list={clientData} />
        </StyledColumn>
        <StyledColumn>
          <DescriptionList list={clientDataSecondary} />
        </StyledColumn>
      </StyledColumns>
      {client.meta.secondParticipant && (
        <Fragment>
          <Heading type="3" border margin>
            Segundo Participante
          </Heading>
          <StyledColumns>
            <StyledColumn>
              <DescriptionList list={secondParticipantData} />
            </StyledColumn>
            <StyledColumn>
              <DescriptionList list={secondParticipantDataSecondary} />
            </StyledColumn>
          </StyledColumns>
        </Fragment>
      )}

      <Fragment>
        <Heading type="3" border margin>
          Creditos de Cliente
        </Heading>
        <List items={clientCredits} />
        <Link to={`/cliente/${code}/nuevo-credito`}>
          <Button>Agregar Nuevo</Button>
        </Link>
      </Fragment>

      {clientDocuments.length > 0 && (
        <Fragment>
          <Heading type="3" border margin>
            Documentos de Cliente
          </Heading>
          <List items={clientDocuments} />
        </Fragment>
      )}
    </StyledClientInformation>
  )
}

export default ClientInformation
