import React from 'react'
import Card from '../../components/atoms/Card'
import DescriptionList from '../../components/molecules/DataList'
import { StyledContent, StyledCreditProgress } from './styled'
import { ReactBaseElement } from '../../types/react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import { PieChart } from 'react-minimal-pie-chart'
import { BankCreditDetail } from '../../types/credits'
import { getCreditColor } from '../../utils/credits'
import { relativeDate } from '../../utils/date'
import { useTranslation } from '../../providers/I18n'
import { CreditSteps } from '../../types/enums'

type CreditProgressProps = {
  credit: BankCreditDetail
} & ReactBaseElement

const StepsProgress = {
  [CreditSteps.PrequalificationRequest]: 1,
  [CreditSteps.PrequalificationDocuments]: 1,
  [CreditSteps.Phase1Assignation]: 2,
  [CreditSteps.Phase1Analysis]: 3,
  [CreditSteps.Phase1Inconsistency]: 4,
  [CreditSteps.Phase1Rejected]: 4,
  [CreditSteps.Phase1Authorized]: 5,
  [CreditSteps.Phase1MissingOriginales]: 5,
  [CreditSteps.Phase1AppraisalDocuments]: 5,
  [CreditSteps.Phase2AppraisalRequest]: 6,
  [CreditSteps.Phase2AppraisalInProgress]: 7,
  [CreditSteps.Phase2AppraisalClosed]: 8,
  [CreditSteps.Phase3FormalizationRequest]: 9,
  [CreditSteps.Phase3ClosingValues]: 10,
  [CreditSteps.Phase3SignatureDate]: 11,
  [CreditSteps.Phase3SignatureReady]: 12,
  [CreditSteps.Phase3SignatureKit]: 13,
  [CreditSteps.Phase3Escriturado]: 14,
  [CreditSteps.Phase3Fondeo]: 15,
  [CreditSteps.Phase3Completed]: 16,
}

const TOTAL_STEPS = 16

const CreditProgress: React.FC<CreditProgressProps> = (props) => {
  const { getTranslation } = useTranslation()
  const creditData = []
  const lastActivity = props.credit?.history[0]
  const timeSinceCreate = relativeDate(new Date(props.credit.createdAt!))
  const timeSinceLastActivity = relativeDate(new Date(lastActivity.start))
  if (props.credit) {
    creditData.push({
      label: 'Etapa',
      value: getTranslation!(`phases.${props.credit.phase}.title`),
    })
    creditData.push({
      label: 'Paso',
      value: getTranslation!(
        `phases.${props.credit.phase}.steps.${props.credit.step}`
      ),
    })
    creditData.push({
      label: 'Tiempo Desde Creación',
      value: timeSinceCreate || '...',
    })
    creditData.push({
      label: 'Ultima Actividad',
      value: timeSinceLastActivity || '...',
    })
  }

  const color = getCreditColor(props.credit.phase)

  const Header = (
    <Container align="left" padding>
      <Heading type="3" color={color}>
        🏁 Progreso
      </Heading>
    </Container>
  )

  const currentStep = StepsProgress[props.credit.step]
  const progressValue = (currentStep * 100) / TOTAL_STEPS

  return (
    <StyledCreditProgress>
      <Card header={Header}>
        <StyledContent>
          <DescriptionList list={creditData} />
          <PieChart
            style={{ height: '100px', width: '100px' }}
            lineWidth={30}
            rounded
            totalValue={100}
            label={({ dataEntry }) => {
              if (dataEntry.hide) {
                return ''
              }
              return dataEntry.value.toFixed(0) + '%'
            }}
            labelStyle={{
              fontSize: '1rem',
              fill: color,
            }}
            labelPosition={0}
            data={[
              {
                value: 100 - progressValue,
                color: 'var(--color-gray-5)',
                hide: true,
              },
              { value: progressValue, color: color },
            ]}
          />
        </StyledContent>
      </Card>
    </StyledCreditProgress>
  )
}

export default CreditProgress
