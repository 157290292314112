import React, { useEffect, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { Link } from 'react-router-dom'
import Card from '../../../../components/atoms/Card'
import Container from '../../../../components/atoms/Container'
import Button from '../../../../components/molecules/Button'
import { StyledDescription } from '../../../../components/styled/Description'
import { StyledEmoji } from '../../../../components/styled/Emoji'
import { addClientDocument } from '../../../../services/clients'
import { useForm } from '../../../../providers/Form'
import { transformBankCreditInput } from '../../../../utils/credits'
import { ClientResponse } from '../../../../types/clients'
import {
  BankCredit,
  BankCreditPayload,
  BankCreditResponse,
} from '../../../../types/credits'
import { addCredit, addCreditDocument } from '../../../../services/credits'
import { FileWithBuffer } from '../../../../components/molecules/FileDropper'
import { Mappeable } from '../../../../types/objects'
import { getSignedUrls, putWithSignedUrl } from '../../../../services/s3'
import Config from '../../../../config/environment'

const { INTERNAL_URL } = Config

const Completed: React.FC = () => {
  const { newCreditForm, setNewCreditForm } = useForm()
  const [credits, setCredits] = useState<BankCreditResponse[]>()
  const [step, setStep] = useState(1)

  const clientInformation = newCreditForm?.clientInformation as ClientResponse

  useEffect(() => {
    const phaseSubmissions = async () => {
      if (step === 1) {
        const clientDocuments: Mappeable<FileWithBuffer> = {}
        if (newCreditForm!.analisisDocuments.addressProofFile) {
          newCreditForm!.analisisDocuments.addressProofFile.forEach(
            (file: FileWithBuffer) => {
              clientDocuments['addressProof'] = file
            }
          )
        }
        if (newCreditForm!.analisisDocuments.maritalFile) {
          newCreditForm!.analisisDocuments.maritalFile.forEach(
            (file: FileWithBuffer) => {
              clientDocuments['marital'] = file
            }
          )
        }

        const clientDocumentUrls = {
          documents: {
            path: `client/${clientInformation!.code.toString()}`,
            keys: Object.keys(clientDocuments),
          },
        }
        const urls = await getSignedUrls(clientDocumentUrls)
        const submitDocuments = Object.keys(clientDocuments).map(
          (documentKey) => {
            const document = clientDocuments[documentKey]
            const signedUrl = urls.documents[documentKey]
            return putWithSignedUrl(signedUrl.url, document.file).then(() => {
              return addClientDocument(clientInformation!.code!, {
                url: `${INTERNAL_URL}/${signedUrl.path}`,
                name: documentKey,
                isActive: true,
              })
            })
          }
        )
        await Promise.all(submitDocuments)
        setStep(2)
      } else if (step === 2) {
        const bankCreditsArray = Object.values(
          newCreditForm?.bankCredits
        ) as BankCredit[]

        const bankCreditsPayloads = bankCreditsArray.map(
          (bankCredit: BankCredit) => {
            return transformBankCreditInput(
              clientInformation?._id,
              bankCredit,
              'EXISTING'
            )
          }
        )

        const bankPromises = bankCreditsPayloads.map(
          (bankCreditPayload: BankCreditPayload) => {
            bankCreditPayload.comments =
              newCreditForm!.analisisDocuments.comments
            return addCredit(bankCreditPayload)
          }
        )

        await Promise.all<BankCreditResponse>(bankPromises).then(
          (creditsResponse) => {
            setCredits(creditsResponse)
            setStep(3)
          }
        )
      } else if (step === 3 && newCreditForm && credits) {
        const submitCreditDocuments = credits.map(
          (credit: BankCreditResponse) => {
            const bankCreditDocuments = newCreditForm.bankCredits[credit.bank]
            const bankDocuments: Mappeable<FileWithBuffer> = {}

            if (bankCreditDocuments.anexosFiles) {
              bankCreditDocuments.anexosFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['anexo'] = file
                }
              )
            }

            if (bankCreditDocuments.solicitudesFiles) {
              bankCreditDocuments.solicitudesFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['solicitudes'] = file
                }
              )
            }

            if (bankCreditDocuments.solicitudesEditableFiles) {
              bankCreditDocuments.solicitudesEditableFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['solicitudesEditable'] = file
                }
              )
            }

            if (bankCreditDocuments.incomeProofFiles) {
              bankCreditDocuments.incomeProofFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['incomeProof'] = file
                }
              )
            }

            if (bankCreditDocuments.paymentStubsFiles) {
              bankCreditDocuments.paymentStubsFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['paymentStubs'] = file
                }
              )
            }

            if (bankCreditDocuments.altaHaciendaFiles) {
              bankCreditDocuments.altaHaciendaFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['altaHacienda'] = file
                }
              )
            }

            if (bankCreditDocuments.otherFiles) {
              bankCreditDocuments.otherFiles.forEach((file: FileWithBuffer) => {
                bankDocuments['otherInitial'] = file
              })
            }

            const bankDocumentUrls = {
              documents: {
                path: `credit/${credit!.code.toString()}`,
                keys: Object.keys(bankDocuments),
              },
            }
            return getSignedUrls(bankDocumentUrls).then(async (urls) => {
              const submitDocuments = Object.keys(bankDocuments).map(
                (documentKey) => {
                  const document = bankDocuments[documentKey]
                  const signedUrl = urls.documents[documentKey]
                  return putWithSignedUrl(signedUrl.url, document.file).then(
                    () => {
                      return addCreditDocument(credit!.code, {
                        url: `${INTERNAL_URL}/${signedUrl.path}`,
                        name: documentKey,
                        isActive: true,
                      })
                    }
                  )
                }
              )
              return submitDocuments
            })
          }
        )

        const creditDocumentsSubmitted = await Promise.all(
          submitCreditDocuments
        )

        if (creditDocumentsSubmitted) {
          setStep(4)
          setNewCreditForm!({
            ...newCreditForm,
            bankCredits: {},
            analisisDocuments: {},
          })
        }
      }
    }
    phaseSubmissions()
  }, [step, newCreditForm])

  const phases = [
    {
      emoji: '📄',
      feedback: 'Actualizando Documentos de Cliente',
    },
    {
      emoji: '🏦',
      feedback: 'Creando Solicitudes a Banco...',
    },
    {
      emoji: '📄',
      feedback: 'Subiendo Documentos de Banco...',
    },
    {
      emoji: '🎉',
      feedback: 'Listo!',
    },
  ]

  const stepInfo = phases[step - 1]
  const completed = step === phases.length
  const progress = (100 / phases.length) * step
  const colorProgress = progress < 100 ? 'error' : 'success'

  return (
    <Card>
      <Container align="center">
        <StyledEmoji>{stepInfo.emoji}</StyledEmoji>
        <LinearProgress
          variant="determinate"
          color={colorProgress}
          value={progress}
          style={{ width: '100%' }}
        />
        <StyledDescription>{stepInfo.feedback}</StyledDescription>
        {completed && (
          <Link to={`/cliente/${clientInformation!.code}`}>
            <Button>Ver Cliente</Button>
          </Link>
        )}
      </Container>
    </Card>
  )
}

export default Completed
