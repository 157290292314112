const Calendar: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M12.25 1.5H11a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5A.25.25 0 019 1.5H5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5A.25.25 0 013 1.5H1.75A1.76 1.76 0 000 3.25v9A1.76 1.76 0 001.75 14h10.5A1.76 1.76 0 0014 12.25v-9a1.76 1.76 0 00-1.75-1.75zM3 12.5a1 1 0 111-1 1 1 0 01-1 1zM3 9a1 1 0 111-1 1 1 0 01-1 1zm4 0a1 1 0 111-1 1 1 0 01-1 1zm4 0a1 1 0 111-1 1 1 0 01-1 1zm1.5-3.75a.25.25 0 01-.25.25H1.75a.25.25 0 01-.25-.25v-2A.25.25 0 011.75 3H3a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5A.25.25 0 015 3h4a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5A.25.25 0 0111 3h1.25a.25.25 0 01.25.25z"></path>
    </svg>
  )
}

export default Calendar
