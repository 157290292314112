import AllocationPage from '../pages/Allocation'
import AuthorizationCardPage from '../pages/AuthorizationCard'
import CelulasPage from '../pages/Celulas'
import ClientDetailPage from '../pages/ClientDetail'
import ClientsPage from '../pages/Clients'
import CommissionsPage from '../pages/Commissions'
import CommissionsControlPage from '../pages/CommissionsControl'
import CreditDetailPage from '../pages/CreditDetail'
import FollowUpPage from '../pages/FollowUp'
import FollowUpMorePage from '../pages/FollowUpMore'
import HomePage from '../pages/Home'
import NewClientPage from '../pages/NewClient'
import NewCreditPage from '../pages/NewCredit'
import OperatorsPage from '../pages/Operators'
import PaidCommissionsPage from '../pages/PaidCommissions'
import SavingFundsPage from '../pages/SavingFunds'
import TrashPage from '../pages/Trash'
import { ConfigPages } from '../types/config'
import { Icons, Pages } from '../types/enums'

const PagesConfig: ConfigPages = {
  [Pages.Home]: {
    icon: Icons.HOME,
    label: 'Inicio',
    path: '/',
    component: HomePage,
  },
  [Pages.Operators]: {
    icon: Icons.GRAPH,
    label: 'Operadoras',
    path: '/operadoras',
    component: OperatorsPage,
  },
  [Pages.NewClient]: {
    icon: Icons.PERSON_PLUS,
    label: 'Nuevo Cliente',
    path: '/nuevo-cliente',
    component: NewClientPage,
  },
  [Pages.Clients]: {
    icon: Icons.PERSON_CROWN,
    label: 'Clientes',
    path: '/clientes',
    component: ClientsPage,
  },
  [Pages.NewCredit]: {
    icon: Icons.PERSON_PLUS,
    label: 'Nuevo Credito a Cliente',
    path: '/cliente/:clientCode/nuevo-credito/',
    hidden: true,
    component: NewCreditPage,
  },
  [Pages.ClientDetail]: {
    icon: Icons.PERSON_SCREENING,
    label: 'Client Detail',
    path: '/cliente/:code',
    hidden: true,
    component: ClientDetailPage,
  },
  [Pages.AuthorizationCard]: {
    icon: Icons.PLUS_CIRCLE,
    label: 'Carta Autorización',
    path: '/autorizacion/:code',
    hidden: true,
    component: AuthorizationCardPage,
  },
  [Pages.CreditDetail]: {
    icon: Icons.PERSON_SCREENING,
    label: 'Credit Detail',
    path: '/credito/:code',
    hidden: true,
    component: CreditDetailPage,
  },
  [Pages.FollowUp]: {
    icon: Icons.LIST,
    label: 'Seguimiento Personal',
    path: '/seguimiento',
    component: FollowUpPage,
  },
  [Pages.FollowUpMore]: {
    icon: Icons.LIST,
    label: 'Seguimiento More',
    path: '/seguimiento-more',
    component: FollowUpMorePage,
  },
  [Pages.Allocation]: {
    icon: Icons.PERSON_CHECK,
    label: 'Colocación',
    path: '/colocacion',
    component: AllocationPage,
  },
  [Pages.Commissions]: {
    icon: Icons.PERCENTAGE_CIRCLE,
    label: 'Comisiones Pendientes',
    path: '/comisiones-pendientes',
    component: CommissionsPage,
  },
  [Pages.PaidCommissions]: {
    icon: Icons.MONEY_BAG,
    label: 'Comisiones Pagadas',
    path: '/comisiones-pagadas',
    component: PaidCommissionsPage,
  },
  [Pages.CommissionsControl]: {
    icon: Icons.SETTINGS,
    label: 'Control de Comisiones',
    path: '/control-comisiones',
    component: CommissionsControlPage,
  },
  [Pages.Celulas]: {
    icon: Icons.USERS,
    label: 'Celulas',
    path: '/celulas',
    component: CelulasPage,
  },
  [Pages.SavingsFunds]: {
    icon: Icons.SAVINGS_BOX,
    label: 'Fondo de Ahorro',
    path: '/fondo-ahorro',
    component: SavingFundsPage,
  },
  [Pages.Trash]: {
    icon: Icons.TRASH,
    label: 'Congeladora',
    path: '/congeladora',
    component: TrashPage,
  },
}

export default PagesConfig
