import { SavingFunds } from '../types/savingFunds'

export function parseSavingFundsTable(savingFunds: SavingFunds[]) {
  const savingFundsRows = savingFunds.map((savingFund) => {
    const pesosFormatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })

    return {
      id: savingFund._id,
      creditCode: `CR-${savingFund.credit.code}`,
      user: savingFund.owner.displayName,
      client: savingFund.credit.client.name.toUpperCase(),
      amount: pesosFormatter.format(
        parseInt(savingFund.credit.meta.finalCreditAmount)
      ),
      contactForm: savingFund.contactForm.name,
      celula: savingFund.celula.name,
      signatureDate: savingFund.credit.meta.finalSignatureDate
        ? new Date(
            savingFund.credit.meta.finalSignatureDate
          ).toLocaleDateString('es-MX')
        : '--',
      savingFundAmount: savingFund.granted
        ? pesosFormatter.format(savingFund.granted.amount)
        : '...',
      savingFundPercentage: savingFund.granted
        ? (savingFund.granted.percentage * 100).toFixed(4) + '%'
        : '...',
    }
  })
  return savingFundsRows
}

export function getConditionalStyles() {
  return []
}
