import React from 'react'
import Layout from '../../templates/Layout'

const NotFoundPage = () => {
  return (
    <Layout>
      <div>Pagina no encontrada</div>
    </Layout>
  )
}

export default NotFoundPage
