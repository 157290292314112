import { createGlobalStyle } from 'styled-components'

const SpacingStyles = createGlobalStyle`
  :root {
    --spc-none: 0;
    --spc-xs: 0.5rem;
    --spc-sm: 1rem;
    --spc-md: 1.5rem;
    --spc-lg: 2rem;
    --spc-xl: 2.5rem;
    --spc-xxl: 3.5rem;
    --spc-xxl: 5rem;

    --bkp-mobile: 32rem;
    --bkp-tablet: 48rem;
    --bkp-laptop: 64rem;
    --bkp-desktop: 80rem;

    --wd-full: 100%;
    --wd-layout: 64rem;
    --wd-card: 38rem;
  }
`

export default SpacingStyles
