import React, { KeyboardEvent } from 'react'
import { Icons } from '../../../types/enums'
import { ReactBaseElement } from '../../../types/react'
import { StyledError } from '../../styled/Error'
import Icon from '../Icon'
import {
  StyledTextareaContainer,
  StyledTextContainer,
  StyledLabel,
  StyledText,
} from './styled'

type TextareaProps = {
  label?: string
  placeholder?: string
  required?: boolean
  autocomplete?: string
  error?: string
  preIcon?: Icons
  afterIcon?: Icons
  maxLength?: number
  minLength?: number
  value?: string
  formatter?: 'uppercase' | 'lowercase'
  onChange?: React.ReactEventHandler
} & ReactBaseElement

const Formatter = {
  uppercase: (event: KeyboardEvent<HTMLTextAreaElement>) => {
    return (event.currentTarget.value = event.currentTarget.value.toUpperCase())
  },
  lowercase: (event: KeyboardEvent<HTMLTextAreaElement>) => {
    return (event.currentTarget.value = event.currentTarget.value.toLowerCase())
  },
}

const Textarea = React.forwardRef(
  (props: TextareaProps, ref: React.Ref<HTMLTextAreaElement>) => {
    const formatterHandler = props.formatter
      ? Formatter[props.formatter]
      : undefined

    return (
      <StyledTextareaContainer>
        {props.preIcon && <Icon name={props.preIcon} />}
        {props.label && <StyledLabel>{props.label}</StyledLabel>}
        <StyledTextContainer>
          <StyledText
            placeholder={props.placeholder || '...'}
            required={props.required}
            autoComplete={props.autocomplete}
            ref={ref}
            onKeyUp={formatterHandler}
            maxLength={props.maxLength}
            minLength={props.minLength}
            onChange={props.onChange}
            defaultValue={props.value}
          />
          {props.error && <StyledError>{props.error}</StyledError>}
        </StyledTextContainer>
      </StyledTextareaContainer>
    )
  }
)

export default Textarea
