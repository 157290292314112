import styled from 'styled-components'

export const StyledGhostWrapper = styled.div<{
  ghost?: boolean
}>`
  width: 100%;
  ${(props) =>
    props.ghost
      ? `
      filter: blur(5px) grayscale(1);
      opacity: 0.5;
  `
      : ''}
`
