import {
  CelulaPerformanceListItem,
  CelulaPerformanceTableRow,
} from '../types/celula'
import { CelulaTypes } from '../types/enums'
import { Mappeable } from '../types/objects'
import { formatCurrency } from './currency'

export function parseCelulaPerformance(
  celulaPerformance: CelulaPerformanceListItem[],
  period: string
): CelulaPerformanceTableRow[] {
  const groupByPeriod = celulaPerformance.reduce<
    Mappeable<{
      celula: string
      type: string
      periods: {
        [key: string]: number
      }
    }>
  >((group, current) => {
    let celulaGroup = group[current.celula._id]
    if (celulaGroup) {
      celulaGroup.periods[current.period] = current.total
    } else {
      celulaGroup = {
        celula: current.celula.name,
        type: current.celulaType.name,
        periods: {},
      }
      celulaGroup.periods[current.period] = current.total
    }

    if (
      [CelulaTypes.Senior, CelulaTypes.Junior].includes(
        current.celulaType.type as CelulaTypes
      )
    ) {
      group[current.celula._id] = celulaGroup
    }

    return group
  }, {})

  const parsedCelulaPerformance = Object.values(groupByPeriod).map(
    (celulaGroup) => {
      const periods: { [key: string]: string } = {}
      const quarters: { [key: string]: number } = {}
      const quartersCurrency: { [key: string]: string } = {}
      for (let i = 0; i < 12; i++) {
        const periodKey = (i + 1).toString().padStart(2, '0')
        const periodAmount = celulaGroup.periods[`${period}-${periodKey}`] || 0
        const quarter = Math.ceil((i + 1) / 3)
        periods[`period${periodKey}`] = formatCurrency(periodAmount)
        quarters[`quarter${quarter}`] =
          (quarters[`quarter${quarter}`] || 0) + periodAmount
      }

      Object.keys(quarters).forEach((qkey) => {
        quartersCurrency[qkey] = formatCurrency(quarters[qkey])
      })

      return {
        celula: celulaGroup.celula,
        type: celulaGroup.type,
        ...periods,
        ...quartersCurrency,
      }
    }
  )
  return parsedCelulaPerformance
}
