export const BasicSavingFundsColumns = [
  {
    name: 'Private ID',
    key: 'id',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Credit Code',
    key: 'creditCode',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Asesora',
    key: 'user',
    width: '8rem',
  },
  {
    name: 'Cliente',
    key: 'client',
    width: '12rem',
  },
  {
    name: 'Forma de Contacto',
    key: 'contactForm',
    width: '8rem',
  },
  {
    name: 'Fecha de Firma',
    key: 'signatureDate',
    width: '8rem',
  },
  {
    name: 'Total Aportado $',
    key: 'savingFundAmount',
    width: '8rem',
  },
  {
    name: 'Total Aportado %',
    key: 'savingFundPercentage',
    width: '8rem',
  },
]
