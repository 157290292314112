import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import Card from '../../../../components/atoms/Card'
import Container from '../../../../components/atoms/Container'
import Heading from '../../../../components/atoms/Heading'
import Input from '../../../../components/atoms/Input'
import NumberMaskInput from '../../../../components/atoms/NumberMaskInput'
import Select from '../../../../components/atoms/Select'
import Button from '../../../../components/molecules/Button'
import FileDropper, {
  FileWithBuffer,
} from '../../../../components/molecules/FileDropper'
import {
  StyledColumn,
  StyledColumns,
} from '../../../../components/styled/Columns'
import { StyledDescription } from '../../../../components/styled/Description'
import {
  MaritalRegimen,
  MaritalStatus,
  SecondParticipantRelation,
  States,
  YesNo,
} from '../../../../data/selects'
import { useAlert } from '../../../../providers/Alert'
import { useCatalogs } from '../../../../providers/Catalogs'
import { useForm } from '../../../../providers/Form'
import { clientExists } from '../../../../services/utils'
import { ClientInformation } from '../../../../types/clients'
import { SelectOption } from '../../../../types/html'
import { getDateFromCurp } from '../../../../utils/date'
import { StyledStepForm } from '../../styled'

export type StepFormProps = {
  continue: (data: any) => void
}

const PersonalInformation: React.FC<StepFormProps> = (props) => {
  const { newClientForm } = useForm()
  const { setAlert } = useAlert()

  // FIRST
  const [maritalStatus, setMaritalStatus] = useState<SelectOption>(
    newClientForm?.personalInformation?.maritalStatus
  )
  const [curp, setCurp] = useState<string>(
    newClientForm?.personalInformation?.curp
  )
  const [dateOfBirth, setDateOfBirth] = useState<string>(
    newClientForm?.personalInformation?.dateOfBirth
  )

  // SECONDARY
  const [secondMaritalStatus, setSecondMaritalStatus] = useState<SelectOption>(
    newClientForm?.personalInformation?.maritalStatus
  )
  const [curpSecondParticipant, setCurpSecondParticipant] = useState<string>(
    newClientForm?.personalInformation?.secondParticipantMeta?.curp
  )
  const [stateSecondParticipant, setStateSecondParticipant] =
    useState<SelectOption>(
      newClientForm?.personalInformation?.secondParticipantMeta?.state
    )
  const [dateOfBirthSecondParticipant, setDateOfBirthSecondParticipant] =
    useState<string>(
      newClientForm?.personalInformation?.secondParticipantMeta?.dateOfBirth
    )
  const [curpError, setCurpError] = useState<string>()

  const [uniqueClient, setUniqueClient] = useState<boolean>(
    newClientForm?.uniqueClient
  )

  const stepData =
    (newClientForm?.personalInformation as ClientInformation) || {}
  const { contactForms } = useCatalogs()
  const [secondParticipant, setSecondParticipant] = useState<SelectOption>(
    stepData.secondParticipant
  )

  // Client Data
  const nameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)
  const rfcRef = useRef<HTMLInputElement>(null)
  const curpRef = useRef<HTMLInputElement>(null)
  const contactFormRef = useRef<HTMLInputElement>(null)
  const maritalStatusRef = useRef<HTMLInputElement>(null)
  const maritalRegimenRef = useRef<HTMLInputElement>(null)
  const stateRef = useRef<HTMLInputElement>(null)
  const cityRef = useRef<HTMLInputElement>(null)
  const streetRef = useRef<HTMLInputElement>(null)
  const neighborhoodRef = useRef<HTMLInputElement>(null)
  const zipcodeRef = useRef<HTMLInputElement>(null)
  const dateOfBirthRef = useRef<HTMLInputElement>(null)
  const secondParticipantRef = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<FileWithBuffer[]>(stepData.files || [])

  // Second Participant
  const secondNameRef = useRef<HTMLInputElement>(null)
  const secondEmailRef = useRef<HTMLInputElement>(null)
  const secondPhoneRef = useRef<HTMLInputElement>(null)
  const secondRfcRef = useRef<HTMLInputElement>(null)
  const secondCurpRef = useRef<HTMLInputElement>(null)
  const secondMaritalStatusRef = useRef<HTMLInputElement>(null)
  const secondRelationRef = useRef<HTMLInputElement>(null)
  const secondMaritalRegimenRef = useRef<HTMLInputElement>(null)
  const secondStateRef = useRef<HTMLInputElement>(null)
  const secondCityRef = useRef<HTMLInputElement>(null)
  const secondStreetRef = useRef<HTMLInputElement>(null)
  const secondNeighborhoodRef = useRef<HTMLInputElement>(null)
  const secondZipcodeRef = useRef<HTMLInputElement>(null)
  const secondDateOfBirthRef = useRef<HTMLInputElement>(null)
  const [secondFiles, setSecondFiles] = useState<FileWithBuffer[]>(
    stepData.secondParticipantMeta?.files || []
  )

  useEffect(() => {
    if (secondParticipant?.value === '1') {
      setStateSecondParticipant(JSON.parse(stateRef.current!.value))
      secondCityRef.current!.value = cityRef.current!.value
      secondStreetRef.current!.value = streetRef.current!.value
      secondNeighborhoodRef.current!.value = neighborhoodRef.current!.value
      secondZipcodeRef.current!.value = zipcodeRef.current!.value
    }
  }, [secondParticipant])

  function getStepFields(): ClientInformation {
    const contactFormOptions = JSON.parse(contactFormRef.current?.value || '{}')
    const maritalRegimenOptions = JSON.parse(
      maritalRegimenRef.current?.value || '{}'
    )
    const stateOptions = JSON.parse(stateRef.current?.value || '{}')
    const secondParticipantOptions = JSON.parse(
      secondParticipantRef.current?.value || '{}'
    )

    let secondParticipantMeta
    if (secondParticipant?.value === '1') {
      const secondRelationOptions = JSON.parse(
        secondRelationRef.current?.value || '{}'
      )
      const secondMaritalRegimenOptions = JSON.parse(
        secondMaritalRegimenRef.current?.value || '{}'
      )
      const secondStateOptions = JSON.parse(
        secondStateRef.current?.value || '{}'
      )

      secondParticipantMeta = {
        name: secondNameRef.current?.value || '',
        email: secondEmailRef.current?.value || '',
        phone: secondPhoneRef.current?.value || '',
        rfc: secondRfcRef.current?.value || '',
        curp: curpSecondParticipant,
        maritalStatus: secondMaritalStatus,
        maritalRegimen: secondMaritalRegimenOptions,
        state: secondStateOptions,
        city: secondCityRef.current?.value || '',
        street: secondStreetRef.current?.value || '',
        neighborhood: secondNeighborhoodRef.current?.value || '',
        zipcode: secondZipcodeRef.current?.value || '',
        dateOfBirth: secondDateOfBirthRef.current?.value || '',
        relation: secondRelationOptions,
        files: secondFiles || [],
      }
    }

    return {
      name: nameRef.current?.value || '',
      email: emailRef.current?.value || '',
      phone: phoneRef.current?.value || '',
      rfc: rfcRef.current?.value || '',
      curp: curp,
      contactForm: contactFormOptions,
      maritalStatus: maritalStatus,
      maritalRegimen: maritalRegimenOptions,
      state: stateOptions,
      city: cityRef.current?.value || '',
      street: streetRef.current?.value || '',
      neighborhood: neighborhoodRef.current?.value || '',
      zipcode: zipcodeRef.current?.value || '',
      dateOfBirth: dateOfBirth,
      secondParticipant: secondParticipantOptions,
      secondParticipantMeta,
      files: files || [],
    }
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (
      nameRef.current?.value &&
      emailRef.current?.value &&
      phoneRef.current?.value &&
      rfcRef.current?.value &&
      rfcRef.current?.value.length === 13 &&
      curpRef.current?.value &&
      curpRef.current?.value.length === 18 &&
      contactFormRef.current?.value &&
      maritalStatusRef.current?.value &&
      stateRef.current?.value &&
      cityRef.current?.value &&
      streetRef.current?.value &&
      neighborhoodRef.current?.value &&
      zipcodeRef.current?.value &&
      secondParticipantRef?.current?.value &&
      files.length > 0
    ) {
      if (secondParticipant?.value === '0') {
        const clientInformation = getStepFields()
        props.continue({
          uniqueClient: uniqueClient,
          personalInformation: clientInformation,
        })
      } else if (
        secondNameRef.current?.value &&
        secondEmailRef.current?.value &&
        secondPhoneRef.current?.value &&
        secondRfcRef.current?.value &&
        secondRfcRef.current?.value.length === 13 &&
        secondCurpRef.current?.value &&
        secondCurpRef.current?.value.length === 18 &&
        secondMaritalStatusRef.current?.value &&
        secondCityRef.current?.value &&
        secondStreetRef.current?.value &&
        secondNeighborhoodRef.current?.value &&
        secondZipcodeRef.current?.value &&
        secondFiles.length > 0
      ) {
        const clientInformation = getStepFields()
        props.continue({
          uniqueClient: uniqueClient,
          personalInformation: clientInformation,
        })
      } else {
        setAlert!({
          severity: 'error',
          title: 'Error',
          description:
            'Aún hace falta información del segundo participante, favor de confirmar.',
        })
      }
    } else {
      setAlert!({
        severity: 'error',
        title: 'Error',
        description:
          'Aún hace falta información del cliente, favor de confirmar.',
      })
    }
  }

  const Header = (
    <Container align="left" padding>
      <Heading type="3" color="var(--color-red-2)">
        Información de Cliente
      </Heading>
      <StyledDescription>Paso 1</StyledDescription>
    </Container>
  )
  const Footer = (
    <Container align="right" direction="row" padding>
      {secondParticipant?.value !== '1' ? (
        <Button type="submit" disabled={!uniqueClient}>
          Siguiente
        </Button>
      ) : (
        <StyledDescription>
          Es requerido agregar informacion de segundo participante.
        </StyledDescription>
      )}
    </Container>
  )

  const HeaderSecondParticipant = (
    <Container align="left" padding>
      <Heading type="3" color="var(--color-red-2)">
        Segundo Participante
      </Heading>
      <StyledDescription>
        Esta informacion es requerida solo cuando se cuenta con segundo
        participante.
      </StyledDescription>
    </Container>
  )

  const FooterSecondParticipant = (
    <Container align="right" direction="row" padding>
      <Button type="submit" disabled={!uniqueClient}>
        Siguiente
      </Button>
    </Container>
  )

  const contactFormsToItems = useMemo(() => {
    return contactForms?.map((contactForm) => {
      return {
        value: contactForm._id,
        label: contactForm.name,
      }
    })
  }, [contactForms])

  const stepDataFiles = files
    ? files.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const stepDataSecondParticipantFiles = secondFiles
    ? secondFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const handleCurpChanged = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    setCurp(value)

    if (value.length >= 10) {
      const curpToDateOfBirth = getDateFromCurp(value)

      if (isNaN(Date.parse(curpToDateOfBirth))) {
        setCurpError('Favor de ingresar un CURP con fecha de nacimiento valida')
      } else {
        setDateOfBirth(curpToDateOfBirth)
        clientExists(value).then((resp) => {
          if (resp.exists) {
            setUniqueClient(false)
            setCurpError(
              'Cliente ya esta registrado, favor de revisar con gerencía.'
            )
          } else {
            setUniqueClient(true)
            setCurpError('')
          }
        })
      }
    } else {
      setCurpError('Favor de ingresar un CURP Valido')
    }
  }

  const handleRFCChanged = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    if (value.length >= 10) {
      setCurp(value.substring(0, 10))
    }
  }

  const handleSecondaryRFCChanged = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    if (value.length >= 10) {
      setCurpSecondParticipant(value.substring(0, 10))
    }
  }

  const handleCurpSecondParticipantChanged = (
    event: FormEvent<HTMLInputElement>
  ) => {
    const value = event.currentTarget.value
    setCurpSecondParticipant(value)

    if (value.length >= 10) {
      const curpToDateOfBirth = getDateFromCurp(value)
      setDateOfBirthSecondParticipant(curpToDateOfBirth)
    }
  }

  const handleSetMaritalStatus = (option: SelectOption) => {
    setMaritalStatus(option)
  }

  const handleSetSecondMaritalStatus = (option: SelectOption) => {
    setSecondMaritalStatus(option)
  }

  return (
    <StyledStepForm onSubmit={handleSubmit}>
      <Card header={Header} footer={Footer}>
        <StyledColumns>
          <StyledColumn>
            <Input
              label="Nombre Completo"
              ref={nameRef}
              required
              value={stepData.name}
            />
            <Input
              label="Email Personal"
              ref={emailRef}
              formatter="lowercase"
              type="email"
              required
              value={stepData.email}
            />
            <NumberMaskInput
              mask="(##) #### ####"
              label="Teléfono"
              ref={phoneRef}
              required
              value={stepData.phone}
            />
            <Input
              label="RFC"
              ref={rfcRef}
              maxLength={13}
              minLength={13}
              formatter="uppercase"
              required
              value={stepData.rfc}
              onBlur={handleRFCChanged}
            />
            <Input
              label="CURP"
              ref={curpRef}
              maxLength={18}
              minLength={18}
              formatter="uppercase"
              required
              error={curpError}
              onBlur={handleCurpChanged}
              value={curp}
            />
            <Input
              label="Fecha de Nacimiento"
              ref={dateOfBirthRef}
              required
              value={dateOfBirth}
              disabled
            />
            <Select
              label="Forma Contacto"
              ref={contactFormRef}
              items={contactFormsToItems}
              required
              value={stepData.contactForm}
            />
          </StyledColumn>
          <StyledColumn>
            <Select
              label="Estado Civil"
              ref={maritalStatusRef}
              items={MaritalStatus}
              required
              onChange={handleSetMaritalStatus}
              value={stepData.maritalStatus}
            />
            {maritalStatus?.value === 'married' && (
              <Select
                label="Regimen Civil"
                ref={maritalRegimenRef}
                items={MaritalRegimen}
                required
                value={stepData.maritalRegimen}
              />
            )}
            <Select
              label="Estado"
              ref={stateRef}
              items={States}
              required
              value={stepData.state}
            />
            <Input
              label="Municipio"
              ref={cityRef}
              required
              value={stepData.city}
            />
            <Input
              label="Calle y Numero"
              ref={streetRef}
              required
              value={stepData.street}
            />
            <Input
              label="Colonia"
              ref={neighborhoodRef}
              required
              value={stepData.neighborhood}
            />
            <Input
              label="Codigo Postal"
              ref={zipcodeRef}
              required
              value={stepData.zipcode}
            />
          </StyledColumn>
        </StyledColumns>
        <StyledColumns>
          <StyledColumn>
            <FileDropper
              label="Identificaciones"
              onChange={setFiles}
              files={stepDataFiles}
            />
          </StyledColumn>
        </StyledColumns>
        <Select
          label="Segundo Participante?"
          ref={secondParticipantRef}
          items={YesNo}
          required
          value={stepData.secondParticipant}
          onChange={setSecondParticipant}
        />
      </Card>

      {secondParticipant?.value === '1' && (
        <Card header={HeaderSecondParticipant} footer={FooterSecondParticipant}>
          <StyledColumns>
            <StyledColumn>
              <Select
                label="Relacion"
                ref={secondRelationRef}
                items={SecondParticipantRelation}
                required
                value={stepData.secondParticipantMeta?.relation}
              />
              <Input
                label="Nombre Completo"
                ref={secondNameRef}
                required
                value={stepData.secondParticipantMeta?.name}
              />
              <Input
                label="Email Personal"
                ref={secondEmailRef}
                formatter="lowercase"
                type="email"
                required
                value={stepData.secondParticipantMeta?.email}
              />
              <NumberMaskInput
                mask="(##) #### ####"
                label="Teléfono"
                ref={secondPhoneRef}
                required
                value={stepData.secondParticipantMeta?.phone}
              />
              <Input
                label="RFC"
                ref={secondRfcRef}
                maxLength={13}
                minLength={13}
                formatter="uppercase"
                required
                value={stepData.secondParticipantMeta?.rfc}
                onBlur={handleSecondaryRFCChanged}
              />
              <Input
                label="CURP"
                ref={secondCurpRef}
                maxLength={18}
                minLength={18}
                formatter="uppercase"
                required
                onBlur={handleCurpSecondParticipantChanged}
                value={curpSecondParticipant}
              />
              <Input
                label="Fecha de Nacimiento"
                ref={secondDateOfBirthRef}
                required
                disabled
                value={dateOfBirthSecondParticipant}
              />
            </StyledColumn>
            <StyledColumn>
              <Select
                label="Estado Civil"
                ref={secondMaritalStatusRef}
                items={MaritalStatus}
                required
                onChange={handleSetSecondMaritalStatus}
                value={stepData.secondParticipantMeta?.maritalStatus}
              />
              {secondMaritalStatus?.value === 'married' && (
                <Select
                  label="Regimen Civil"
                  ref={secondMaritalRegimenRef}
                  items={MaritalRegimen}
                  required
                  value={stepData.secondParticipantMeta?.maritalRegimen}
                />
              )}
              <Select
                label="Estado"
                ref={secondStateRef}
                items={States}
                required
                value={stateSecondParticipant}
              />
              <Input
                label="Municipio"
                ref={secondCityRef}
                required
                value={stepData.secondParticipantMeta?.city}
              />
              <Input
                label="Calle y Numero"
                ref={secondStreetRef}
                required
                value={stepData.secondParticipantMeta?.street}
              />
              <Input
                label="Colonia"
                ref={secondNeighborhoodRef}
                required
                value={stepData.secondParticipantMeta?.neighborhood}
              />
              <Input
                label="Codigo Postal"
                ref={secondZipcodeRef}
                required
                value={stepData.secondParticipantMeta?.zipcode}
              />
            </StyledColumn>
          </StyledColumns>
          <StyledColumns>
            <StyledColumn>
              <FileDropper
                label="Identificaciones"
                onChange={setSecondFiles}
                files={stepDataSecondParticipantFiles}
              />
            </StyledColumn>
          </StyledColumns>
        </Card>
      )}
    </StyledStepForm>
  )
}

export default PersonalInformation
