import { ConfigEnvironment } from '../types/config'

const Config: ConfigEnvironment = {
  REST_API_URL:
    process.env.REACT_APP_REST_API_URL || 'http://localhost:3010/rest',
  INTERNAL_URL:
    process.env.REACT_APP_INTERNAL_URL ||
    'https://internal.pruebas.morecrm.net',
  SITE_ADDRESS:
    process.env.REACT_APP_SITE_ADDRESS || '-- Dirección No Disponible --',
  SITE_NAME: process.env.REACT_APP_SITE_NAME || '-- NAME --',
  SITE_CONTACT_INFO:
    process.env.REACT_APP_SITE_CONTACT_INFO ||
    '-- Informacion de Contacto No Disponible --',
}

export default Config
