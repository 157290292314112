import { useMemo } from 'react'
import { useQuery } from 'react-query'
import Heading from '../../components/atoms/Heading'
import Tile from '../../components/molecules/Tile'
import { StyledGhostWrapper } from '../../components/styled/GhostWrapper'
import { useUser } from '../../providers/User'
import { getFollowUp } from '../../services/follow-up'
import { BankCreditListItem } from '../../types/credits'
import { CreditPhases, CreditSteps, Icons, Role } from '../../types/enums'
import { PhaseOwner } from '../../utils/credits'
import { StyledFollowUpTiles } from './styled'

let cachedFollowUp: BankCreditListItem[] | undefined = undefined

const FollowUpTiles = (props: { title: string; showAll?: boolean }) => {
  const { user } = useUser()
  const { isFetching } = useQuery<BankCreditListItem[]>(
    'getFollowUpCredits',
    () => getFollowUp({ showAll: props.showAll }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        cachedFollowUp = data
      },
    }
  )

  const totalPhases = useMemo(() => {
    const phasesTotal = {
      assignation: 0,
      prequalification: 0,
      phase1: 0,
      phase2: 0,
      phase3: 0,
    }
    if (cachedFollowUp) {
      cachedFollowUp.forEach((credit) => {
        if (credit.phase === CreditPhases.Prequalification) {
          phasesTotal.prequalification++
        } else if (credit.phase === CreditPhases.Phase1) {
          phasesTotal.phase1++
        } else if (credit.phase === CreditPhases.Phase2) {
          phasesTotal.phase2++
        } else if (credit.phase === CreditPhases.Phase3) {
          phasesTotal.phase3++
        }

        const assignationSteps = [
          CreditSteps.PrequalificationRequest,
          CreditSteps.Phase1Assignation,
          CreditSteps.Phase2AppraisalRequest,
          CreditSteps.Phase3ClosingValues,
        ]

        const currentOperatorKey = PhaseOwner[credit.phase]
        const currentOperator = credit.owners[currentOperatorKey]

        if (assignationSteps.includes(credit.step)) {
          if (
            Role.Operator === user!.role &&
            currentOperator.code === user?.code
          ) {
            phasesTotal.assignation++
          } else if (Role.Advisor !== (user!.role as Role)) {
            phasesTotal.assignation++
          }
        }
      })
    }
    return phasesTotal
  }, [cachedFollowUp])

  const loading = isFetching && !cachedFollowUp

  return (
    <StyledGhostWrapper ghost={loading}>
      <Heading type="3" border color="var(--color-red-2)" margin>
        {props.title}
      </Heading>
      <StyledFollowUpTiles>
        <Tile
          color="var(--color-pink-4)"
          borderColor="var(--color-pink-1)"
          title={totalPhases.prequalification.toString()}
          subtitle="Precalificaciones"
          icon={Icons.PERSON_QUESTION}
        />
        <Tile
          color="var(--color-yellow-4)"
          borderColor="var(--color-yellow-1)"
          title={totalPhases.phase1.toString()}
          subtitle="Etapa 1"
          icon={Icons.PERSON_SCREENING}
        />
        <Tile
          color="var(--color-blue-4)"
          borderColor="var(--color-blue-1)"
          title={totalPhases.phase2.toString()}
          subtitle="Etapa 2"
          icon={Icons.PERSON_CHECK}
        />
        <Tile
          color="var(--color-green-4)"
          borderColor="var(--color-green-1)"
          title={totalPhases.phase3.toString()}
          subtitle="Etapa 3"
          icon={Icons.PERSON_CROWN}
        />
      </StyledFollowUpTiles>
      {user?.role !== Role.Advisor && (
        <StyledFollowUpTiles>
          <Tile
            color="var(--color-red-4)"
            borderColor="var(--color-red-1)"
            title={totalPhases.assignation.toString()}
            subtitle="En Asignación"
            icon={Icons.WARNING_BUBBLE}
          />
        </StyledFollowUpTiles>
      )}
    </StyledGhostWrapper>
  )
}

export default FollowUpTiles
