const PersonCheck: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <circle cx="4.75" cy="2.75" r="2.75"></circle>
      <path d="M6.66 13.34a2.25 2.25 0 011.41-3.83.25.25 0 00.21-.16.25.25 0 000-.26 4.63 4.63 0 00-3-1.57A4.75 4.75 0 000 12.25V13a1 1 0 001 1h5.71a.26.26 0 00.29-.15.28.28 0 00-.06-.28z"></path>
      <path d="M9.75 14a.74.74 0 01-.53-.22l-1.5-1.5a.75.75 0 011.06-1.06l.79.79a.25.25 0 00.36 0l2.79-2.79a.75.75 0 011.06 1.06l-3.5 3.5a.74.74 0 01-.53.22z"></path>
    </svg>
  )
}

export default PersonCheck
