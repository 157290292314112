import React, { FormEvent, useEffect, useRef, useState } from 'react'
import Button from '../../components/molecules/Button'
import Heading from '../../components/atoms/Heading'
import Input from '../../components/atoms/Input'
import Card from '../../components/atoms/Card'
import { StyledChangePasswordForm } from './styled'
import Container from '../../components/atoms/Container'
import { StyledError } from '../../components/styled/Error'
import { StyledDescription } from '../../components/styled/Description'
import { useAuth } from '../../providers/Auth'
import { useAlert } from '../../providers/Alert'

const ChangePasswordForm: React.FC = () => {
  const {
    changePassword,
    isChangingPassword,
    changePasswordFailed,
    passwordChanged,
  } = useAuth()
  const [errorText, setError] = useState<string>('')
  const { setAlert } = useAlert()

  const headline = 'Seguridad'
  const description = 'Cambiar contraseña'

  let changePasswordButtonText = 'Cambiar'
  if (isChangingPassword) {
    changePasswordButtonText = '...'
  }

  useEffect(() => {
    if (passwordChanged) {
      oldPasswordRef.current!.value = ''
      newPasswordRef.current!.value = ''
      verifiedPasswordRef.current!.value = ''
      setAlert!({
        severity: 'success',
        title: 'Listo!',
        description: 'Contraseña ha sido cambiada exitosamente',
      })
    }
  }, [passwordChanged])

  useEffect(() => {
    if (changePasswordFailed) {
      setError(
        'La contraseña ingresada no es la correcta, confirmala por favor.'
      )
    } else {
      setError('')
    }
  }, [changePasswordFailed])

  const oldPasswordRef = useRef<HTMLInputElement>(null)
  const newPasswordRef = useRef<HTMLInputElement>(null)
  const verifiedPasswordRef = useRef<HTMLInputElement>(null)

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    const oldPassword = oldPasswordRef?.current?.value
    const newPassword = newPasswordRef?.current?.value
    const verfiedPassword = verifiedPasswordRef?.current?.value

    if (!oldPassword || !newPassword) {
      setError('Ingresa contraseña actual y nueva')
    } else if (newPassword !== verfiedPassword) {
      setError('Nueva contraseña debe coincidir validación')
    } else if (oldPassword && newPassword) {
      const passwords = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      }
      changePassword!(passwords)
    }
  }

  const Header = (
    <Container align="left" padding>
      <Heading type="3">{headline}</Heading>
      {description && <StyledDescription>{description}</StyledDescription>}
    </Container>
  )
  const Footer = (
    <Container align="right" direction="row" padding>
      <Button
        disabled={isChangingPassword}
        loading={isChangingPassword}
        type="submit"
      >
        {changePasswordButtonText}
      </Button>
    </Container>
  )
  return (
    <StyledChangePasswordForm onSubmit={handleSubmit}>
      <Card header={Header} footer={Footer}>
        <Input
          label="Contraseña Actual"
          type="password"
          required
          autocomplete="more-password"
          ref={oldPasswordRef}
        />
        <Input
          label="Nueva Contraseña"
          type="password"
          required
          autocomplete="new-more-password"
          ref={newPasswordRef}
        />
        <Input
          label="Verifica Contraseña"
          type="password"
          required
          autocomplete="new-more-password"
          ref={verifiedPasswordRef}
        />
        {errorText && (
          <Container align="center">
            <StyledError>{errorText}</StyledError>
          </Container>
        )}
      </Card>
    </StyledChangePasswordForm>
  )
}

export default ChangePasswordForm
