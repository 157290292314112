import { ModalProps } from '@mui/material'
import { useRef } from 'react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import Input from '../../components/atoms/Input'
import Button from '../../components/molecules/Button'
import { StyledDescription } from '../../components/styled/Description'
import { StyledDialog, StyledDialogContent } from './styled'

type EditCommissionsPercentageProps = {
  open: boolean
  onClose: ModalProps['onClose']
  onSubmit: (newName: string) => void
}

const EditCommissionsPercentage = (props: EditCommissionsPercentageProps) => {
  const newPercentageRef = useRef<HTMLInputElement>(null)

  const handleChangePercentage = () => {
    const newPercentages = newPercentageRef.current?.value
    if (newPercentages) {
      props.onSubmit(newPercentages)
      if (props.onClose) props.onClose({}, 'backdropClick')
    }
  }

  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <StyledDialogContent>
        <Heading type="4" border>
          {`Editar Porcentaje de Comisión`}
        </Heading>
        <StyledDescription>
          Este nuevo porcentaje sera fijo y no se recalculara
        </StyledDescription>
        <Container>
          <Input
            ref={newPercentageRef}
            type="number"
            label="Nuevo Porcentaje"
          />
          <Button onClick={handleChangePercentage}>{`Cambiar`}</Button>
        </Container>
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default EditCommissionsPercentage
