import styled from 'styled-components'
import Icon from '../../atoms/Icon'
import { TileStyles } from './index'

export const StyledTile = styled.div<TileStyles>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: var(--spc-xs) var(--spc-sm);
  padding-right: var(--spc-xl);
  font-size: var(--fs-sm);
  background-color: ${(props) => props.color};
  color: ${(props) =>
    props.color === 'var(--color-white)'
      ? 'var(--color-gray-2)'
      : 'var(--color-white)'};
  border-radius: 5px;
  border: 1px solid silver;
  border-bottom: 15px solid;
  border-bottom-color: ${(props) => props.borderColor};
  min-height: 2rem;
  min-width: 10rem;

  @media screen and (max-width: 32rem) {
    width: 100%;
  }
`

export const StyledTileIcon = styled(Icon)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: var(--fs-xxxl);
  opacity: 0.3;
`

export const StyledTileTitle = styled.h3`
  font-size: var(--fs-xl);
  @media screen and (max-width: 48rem) {
    font-size: var(--fs-lg);
  }
`

export const StyledTileSubtitle = styled.h4<{ color?: string }>`
  display: block;
  font-size: var(--fs-md);
  color: ${(props) => props.color};
  z-index: 10;

  @media screen and (max-width: 48rem) {
    font-size: var(--fs-sm);
  }
`
