import { createGlobalStyle } from 'styled-components'

const ColorsStyles = createGlobalStyle`
  :root {
    --color-white: #fff;
    --color-black: #000;
    --color-red-1: #c4012e;
    --color-red-2: #d85052;
    --color-red-3: #ea7f7a;
    --color-red-4: #f6aaa5;
    --color-red-5: #fdd5d1;
    --color-gray-1: #231f20;
    --color-gray-2: #57585a;
    --color-gray-3: #808285;
    --color-gray-4: #a8a9ad;
    --color-gray-5: #bcbcbe;
    --color-blue-1: #00b4e3;
    --color-blue-2: #04c2e9;
    --color-blue-3: #74d2ee;
    --color-blue-4: #a9dff4;
    --color-blue-5: #d8effa;
    --color-yellow-1: #fec52e;
    --color-yellow-2: #ffd05f;
    --color-yellow-3: #fedb89;
    --color-yellow-4: #ffe7b1;
    --color-yellow-5: #fff2d8;
    --color-pink-1: #fe42b2;
    --color-pink-2: #ff76c2;
    --color-pink-3: #fe9dd1;
    --color-pink-4: #ffbee0;
    --color-pink-5: #ffdfef;
    --color-purple-1: #652766;
    --color-purple-2: #835183;
    --color-purple-3: #a37ba0;
    --color-purple-4: #c2a5bf;
    --color-purple-5: #e2d1df;
    --color-green-1: #4fad2f;
    --color-green-2: #78be5b;
    --color-green-3: #9bce83;
    --color-green-4: #bedead;
    --color-green-5: #cedac3;
  }
`

export default ColorsStyles
