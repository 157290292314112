import React from 'react'
import { Link } from 'react-router-dom'
import ProfileWidget from '../../molecules/ProfileWidget'
import { StyledLogo } from '../../styled/Logo'
import { StyledHeader } from './styled'

type HeaderProps = {
  logo: {
    src: string
    link: string
  }
  profileWidget: {
    name: string
    avatar: string
    link: string
  }
}

const Header = (props: HeaderProps) => {
  return (
    <StyledHeader>
      <Link to={props.logo.link}>
        <StyledLogo src={props.logo.src} />
      </Link>
      <ProfileWidget
        avatar={props.profileWidget.avatar}
        name={props.profileWidget.name}
        link={props.profileWidget.link}
      />
    </StyledHeader>
  )
}

export default Header
