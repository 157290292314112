import Config from '../config/environment'

const { REST_API_URL } = Config

export const getCelulasTypes = async function () {
  const commissionResponse = await fetch(`${REST_API_URL}/celula-types`, {
    method: 'GET',
    credentials: 'include',
  })
  const celulasTypesResponse = await commissionResponse.json()
  if ('error' in celulasTypesResponse) {
    throw new Error(celulasTypesResponse.error.message)
  }
  return celulasTypesResponse
}
