import Heading from '../../components/atoms/Heading'
import Layout from '../../templates/Layout'
import PaidCommissionsTable from '../../templates/PaidCommissionsTable'

const PaidCommissionsPage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        Comisiones Pagadas
      </Heading>
      <PaidCommissionsTable />
    </Layout>
  )
}

export default PaidCommissionsPage
