const Filter: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M13.9.8a.51.51 0 000-.52.51.51 0 00-.4-.28H.5a.51.51 0 00-.45.28A.51.51 0 00.1.8l5.19 6.79a1 1 0 01.21.61v5.3a.5.5 0 00.31.46A.43.43 0 006 14a.47.47 0 00.35-.15l2-2a.47.47 0 00.15-.35V8.2a1 1 0 01.21-.61z"></path>
    </svg>
  )
}

export default Filter
