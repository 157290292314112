import React, { useContext, useState } from 'react'
import Languages from '../i18n'

type I18ContextValue = {
  getTranslation: (key: string) => string
  languages: {
    [key: string]: string
  }
}

export const I18nContext = React.createContext<Partial<I18ContextValue>>({})

type I18nProviderProps = {
  children: React.ReactNode
}

const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
  const [languageSelected] = useState<string>('es')

  const languages = Languages[languageSelected]

  function getTranslation(key: string) {
    return languages[key] || key
  }

  const contextValue = {
    getTranslation,
    languages,
  }

  return (
    <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>
  )
}

export const useTranslation = () => {
  return useContext(I18nContext)
}

export default I18nProvider
