import Config from '../config/environment'
import {
  ClientDocument,
  ClientPayload,
  ClientUpdatePayload,
} from '../types/clients'

const { REST_API_URL } = Config

export const getClients = async function () {
  const clientResponse = await fetch(`${REST_API_URL}/clients`, {
    method: 'GET',
    credentials: 'include',
  })
  const data = await clientResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const getClient = async function (code: string) {
  const clientResponse = await fetch(`${REST_API_URL}/clients/${code}`, {
    method: 'GET',
    credentials: 'include',
  })
  const data = await clientResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const addClient = async function (client: ClientPayload) {
  const clientResponse = await fetch(`${REST_API_URL}/clients`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(client),
  })
  const data = await clientResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const addClientDocument = async function (
  client: number,
  document: ClientDocument
) {
  const clientDocument = await fetch(
    `${REST_API_URL}/clients/${client}/documents`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(document),
    }
  )
  const data = await clientDocument.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const updateClient = async function (
  client: number,
  payload: ClientUpdatePayload
) {
  const updatedClient = await fetch(`${REST_API_URL}/clients/${client}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })

  if (updatedClient.status !== 204) {
    const data = await updatedClient.json()
    if ('error' in data) {
      throw new Error(data.error.message)
    }
  }
  return {
    clientUpdated: true,
  }
}
