const Home: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M13.71 6.29l-6-6a1 1 0 00-1.42 0l-6 6a1 1 0 001.42 1.42L7 2.41l5.29 5.3a1 1 0 001.42 0 1 1 0 000-1.42z"></path>
      <path d="M7.35 5.15a.48.48 0 00-.7 0l-4.5 4.5A.47.47 0 002 10v3.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V10a.47.47 0 00-.15-.35z"></path>
    </svg>
  )
}

export default Home
