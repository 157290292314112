import styled from 'styled-components'

export const StyledListCell = styled.div`
  border: 1px solid silver;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: white;
  font-weight: bold;
  font-size: 15px;

  ul {
    list-style: none;

    li {
      margin-bottom: 0.5rem;
    }
  }
`

export const StyledNegativeMessage = styled.span`
  color: var(--color-red-2);
`

export const StyledPositiveMessage = styled.span`
  color: var(--color-green-2);
`
