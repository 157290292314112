import React from 'react'
import Container from '../../../components/atoms/Container'
import { StyledDescription } from '../../../components/styled/Description'
import { StyledEmoji } from '../../../components/styled/Emoji'

const NoData = () => {
  return (
    <Container align="center" padding>
      <StyledEmoji>🕳️</StyledEmoji>
      <StyledDescription>No hay registros</StyledDescription>
    </Container>
  )
}

export default NoData
