import styled from 'styled-components'

export const StyledIcon = styled.div<{
  actionable?: boolean
}>`
  display: inline-block;
  display: flex;
  color: ${(props) => props.color || 'inherit'};
  cursor: ${(props) => (props.actionable ? 'pointer' : 'inherit')};
`
