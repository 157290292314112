import Config from '../config/environment'
import { BankGetResponse } from '../types/catalogs'

const { REST_API_URL } = Config

export const getNotarias = async function () {
  const authResponse = await fetch(`${REST_API_URL}/notarias`, {
    method: 'GET',
    credentials: 'include',
  })
  const data = (await authResponse.json()) as BankGetResponse
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const getBanks = async function () {
  const authResponse = await fetch(`${REST_API_URL}/banks`, {
    method: 'GET',
    credentials: 'include',
  })
  const data = (await authResponse.json()) as BankGetResponse
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const getContactForms = async function () {
  const authResponse = await fetch(`${REST_API_URL}/contact-forms`, {
    method: 'GET',
    credentials: 'include',
  })
  const data = (await authResponse.json()) as BankGetResponse
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}
