import styled from 'styled-components'

export const StyledColumns = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
`

export const StyledColumn = styled.div`
  display: flex;
  flex: 1;
  gap: 0;
  flex-direction: column;
  align-items: stretch;
`
