import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import Spinner from '../../../../../components/atoms/Spinner'
import DateInput from '../../../../../components/atoms/DateInput'
import { useAlert } from '../../../../../providers/Alert'

const Phase3SignatureDate: React.FC = () => {
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const signatureDateRef = useRef<HTMLInputElement>(null)
  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const [loading, setLoading] = useState<boolean>(false)
  const { getTranslation } = useTranslation()
  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.advisor?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const handleSendToSignatureDate = async () => {
        setLoading(true)

        const hasComment = !!commentRef.current?.value
        const hasDate = !!signatureDateRef.current?.value
        if (!hasComment || !hasDate) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase3,
            step: CreditSteps.Phase3SignatureKit,
            meta: {
              plannedSignatureDate: signatureDateRef.current?.value,
            },
          }).then(async () => {
            const comment = `Avanzando a Etapa 3 - Kit de Firma :: ${commentRef.current?.value}`

            const signatureDate = new Date(
              signatureDateRef.current?.value || ''
            )
            signatureDate.setDate(signatureDate.getDate() + 1)

            const creditHistoryComment = {
              comment: comment,
              type: 'status',
              start: new Date(),
              meta: {
                plannedSignatureDate: new Date(
                  signatureDate
                ).toLocaleDateString('es-MX', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }),
              },
              change: {
                from: {
                  owner: credit.owners.advisor._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: credit.owners.phase3Operator._id,
                  phase: CreditPhases.Phase3,
                  step: CreditSteps.Phase3SignatureKit,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      const handleSendBack = async () => {
        setLoading(true)

        await updateCredit(credit!.code, {
          step: CreditSteps.Phase3ClosingValues,
        }).then(async () => {
          const comment = `Regresando a Etapa 3 - Formalización Cierre de Cifras :: ${commentRef.current?.value}`
          const creditHistoryComment = {
            comment: comment,
            type: 'status',
            start: new Date(),
            change: {
              from: {
                owner: credit.owners.advisor._id,
                step: credit.step,
              },
              to: {
                owner: credit.owners.phase3Operator._id,
                step: CreditSteps.Phase3ClosingValues,
              },
            },
          }

          await addCreditHistory(credit!.code, creditHistoryComment).then(
            () => {
              if (commentRef.current) {
                commentRef.current.value = ''
              }
              refetch().then(() => {
                setLoading(false)
              })
            }
          )
        })
      }

      content = (
        <Fragment>
          <StyledDescription>
            Instrucción Notarial generada y enviada a Notaria, revisa datos y
            confirma elaboración de proyecto y envio a revisión.
          </StyledDescription>
          <DateInput label="Fecha de Firma" ref={signatureDateRef} required />
          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <>
              {user?.role !== Role.Advisor && (
                <Button
                  color={color}
                  priority="secondary"
                  onClick={handleSendBack}
                  loading={loading}
                >
                  Regresar a Cierre de Cifras
                </Button>
              )}
              <Button onClick={handleSendToSignatureDate} color={color}>
                Solicitar Kit de Firma
              </Button>
            </>
          )}
        </Container>
      )
    } else {
      content = <CreditWaitAction owner={credit.owners.phase3Operator} />
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase3SignatureDate
