import React from 'react'
import { ReactContainerElement } from '../../../types/react'
import { StyledContainer } from './styled'

export type ContainerStyles = {
  align?: 'center' | 'left' | 'right'
  direction?: 'column' | 'row'
  padding?: boolean
}

type ContainerProps = ContainerStyles & ReactContainerElement

const Container: React.FC<ContainerProps> = (props) => {
  const alignFlexMap = {
    center: 'center',
    left: 'flex-start',
    right: 'flex-end',
  }
  return (
    <StyledContainer
      justifyContent={alignFlexMap[props.align || 'left']}
      alignItems={alignFlexMap[props.align || 'left']}
      padding={props.padding}
      direction={props.direction || 'column'}
      className={props.className}
    >
      {props.children}
    </StyledContainer>
  )
}

export default Container
