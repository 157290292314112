import { FileWithBuffer } from '../components/molecules/FileDropper'
import Config from '../config/environment'
import { getSignedUrls, putWithSignedUrl } from '../services/s3'
import { Mappeable } from '../types/objects'

const { INTERNAL_URL } = Config

export async function uploadDocuments(files: FileWithBuffer[], path: string) {
  if (!files || files.length === 0) {
    return []
  }

  const documentsMap: Mappeable<FileWithBuffer> = {}
  files.forEach((file: FileWithBuffer) => {
    const key = file.fixedName || file.file.name
    documentsMap[key] = file
  })

  const documentsUrls = {
    documents: {
      path: path,
      keys: Object.keys(documentsMap),
    },
  }

  const signedUrls = await getSignedUrls(documentsUrls)
  const submitDocuments = Object.keys(documentsMap).map((documentKey) => {
    const document = documentsMap[documentKey]
    const signedUrl = signedUrls.documents[documentKey]
    return putWithSignedUrl(signedUrl.url, document.file).then(() => {
      return {
        url: `${INTERNAL_URL}/${signedUrl.path}`,
        name: documentKey,
        uploadedAt: new Date(),
        isActive: true,
      }
    })
  })
  const pushedDocuments = await Promise.all(submitDocuments)
  return pushedDocuments
}
