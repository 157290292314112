import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import { useAlert } from '../../../../../providers/Alert'
import Spinner from '../../../../../components/atoms/Spinner'

const Phase1Assignation: React.FC = () => {
  const commentRef = useRef<HTMLTextAreaElement>(null)

  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const [loading, setLoading] = useState<boolean>(false)
  const { getTranslation } = useTranslation()
  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.phase1Operator?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const handleSendBack = async () => {
        setLoading(true)

        await updateCredit(credit!.code, {
          phase: CreditPhases.Prequalification,
          step: CreditSteps.PrequalificationDocuments,
          owners: {
            prequalificationOperator: credit.owners.phase1Operator._id,
          },
        }).then(async () => {
          const comment = `Regresando a Precalificación - Carga de Expediente :: ${commentRef.current?.value}`
          const creditHistoryComment = {
            comment: comment,
            type: 'status',
            start: new Date(),
            change: {
              from: {
                owner: credit.owners.phase1Operator._id,
                phase: credit.phase,
                step: credit.step,
              },
              to: {
                owner: credit.owners.advisor._id,
                phase: CreditPhases.Prequalification,
                step: CreditSteps.PrequalificationDocuments,
              },
            },
          }

          await addCreditHistory(credit!.code, creditHistoryComment).then(
            () => {
              if (commentRef.current) {
                commentRef.current.value = ''
              }
              refetch().then(() => {
                setLoading(false)
              })
            }
          )
        })
      }

      const handleSendToAnalysis = async () => {
        setLoading(true)

        const hasComment = !!commentRef.current?.value
        if (!hasComment) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase1,
            step: CreditSteps.Phase1Analysis,
          }).then(async () => {
            const comment = `Avanzando a Etapa 1 - Analisis :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              comment: comment,
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  phase: CreditPhases.Phase1,
                  step: CreditSteps.Phase1Analysis,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      content = (
        <Fragment>
          <StyledDescription>
            Es necesario revisar que el expediente este completo, legible,
            cotejado y anexa el certificado broker antes de mandarlo al Banco.
          </StyledDescription>
          <StyledDescription>
            Si el expediente esta completo, dar click en "MANDAR A ANALISIS"
          </StyledDescription>
          <form>
            <Textarea label="Comentarios" ref={commentRef} required />
          </form>
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <>
              {user?.role !== Role.Advisor && (
                <Button
                  color={color}
                  priority="secondary"
                  onClick={handleSendBack}
                  loading={loading}
                >
                  Regresar a Carga de Expediente
                </Button>
              )}
              <Button
                onClick={handleSendToAnalysis}
                color={color}
                loading={loading}
              >
                Mandar a Analisis
              </Button>
            </>
          )}
        </Container>
      )
    } else {
      content = <CreditWaitAction owner={credit.owners.phase1Operator} />
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase1Assignation
