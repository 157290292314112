import { StyledPillCell } from './styled'

type CellProps = {
  data: {
    text: string
    color: string
  }
}

const PillCell = (props: CellProps) => {
  if (!props.data) {
    return <span>--</span>
  }

  return (
    <StyledPillCell color={props.data.color}>{props.data.text}</StyledPillCell>
  )
}

export default PillCell
