import styled from 'styled-components'

export const StyledFileItem = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
`

export const StyledFileInfo = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  p {
    margin: 0;
    margin-bottom: 0;
  }
`

export const StyledFileActions = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: var(--fs-sm);

  span {
    color: var(--color-gray-3);

    &:hover {
      cursor: pointer;
      color: black;
    }
  }
`

export const StyledFileData = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
`
