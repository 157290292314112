import React, { useEffect, useState } from 'react'
import Container from '../../components/atoms/Container'
import { LogoContainer, StyledBackgroundLayout } from './styled'
import Config from '../../config/environment'
import { ReactContainerElement } from '../../types/react'
import { StyledBackground } from '../../components/styled/Background'
import { StyledLogo } from '../../components/styled/Logo'

const { INTERNAL_URL } = Config

const mountedStyle = {
  animation: 'inAnimation 1000ms ease-in',
}

type BackgroundLayoutProps = {
  animate?: boolean
} & ReactContainerElement

const BackgroundLayout = (props: BackgroundLayoutProps) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <StyledBackgroundLayout
      style={isMounted && props.animate ? mountedStyle : {}}
    >
      <StyledBackground src={`${INTERNAL_URL}/assets/site.jpg`} />
      <LogoContainer align="center">
        <StyledLogo src={`${INTERNAL_URL}/assets/logo.svg`} />
      </LogoContainer>
      <Container align="center">{props.children}</Container>
    </StyledBackgroundLayout>
  )
}

export default BackgroundLayout
