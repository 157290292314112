import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  StyledSavingFunds,
  StyledTable,
  StyledTotalForCommission,
} from './styled'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { useQuery } from 'react-query'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import Input from '../../components/atoms/Input'
import { Icons, Role } from '../../types/enums'
import { TableSelectedData } from '../../types/tables'
import { formatCurrency } from '../../utils/currency'
import { Mappeable } from '../../types/objects'
import Select from '../../components/atoms/Select'
import { SelectOption } from '../../types/html'
import { getSavingfunds } from '../../services/saving-funds'
import {
  parseSavingFundsTable,
  getConditionalStyles,
} from '../../utils/saving-funds'

const SavingFunds: React.FC = () => {
  const { user } = useUser()
  const [search, setSearch] = useState('')
  const [localFilters, setLocalFilters] = useState<Mappeable<SelectOption>>({})
  const [stateSavingFunds, setStateSavingFunds] = useState<any[]>()
  const [selectedSavingFunds, setSelectedSavingFunds] =
    useState<TableSelectedData>()
  const navigator = useNavigate()

  const { data: savingFunds, isFetching } = useQuery(
    ['getSavingFunds'],
    () => getSavingfunds(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setStateSavingFunds(data)
      },
    }
  )

  useEffect(() => {
    if (savingFunds) {
      const filteredSavingFunds = savingFunds!.filter((savingFund) => {
        const celulaFilter = localFilters.celula?.value
        if (!celulaFilter || celulaFilter === '--') {
          return true
        }
        if (savingFund.celula) {
          return savingFund.celula._id.toString() === celulaFilter
        } else if (savingFund.owner) {
          return savingFund.owner._id.toString() === celulaFilter
        }
      })

      const searchSavingfunds = filteredSavingFunds!.filter((savingFund) => {
        return (
          savingFund.credit.code.toString().includes(search) ||
          savingFund.credit.client.name.toLowerCase().includes(search) ||
          savingFund.celula.name.toLowerCase().includes(search) ||
          savingFund.contactForm.name.toLowerCase().includes(search) ||
          savingFund.owner.displayName.toLowerCase().includes(search)
        )
      })

      setStateSavingFunds(searchSavingfunds)
    }
  }, [localFilters, search, savingFunds])

  const filters = useMemo(() => {
    const celulas: Mappeable<string> = {}
    savingFunds?.forEach((savingFund) => {
      if (savingFund.celula) {
        celulas[savingFund.celula._id.toString()] = savingFund.celula.name
      } else if (savingFund.owner) {
        celulas[savingFund.owner._id.toString()] = savingFund.owner.displayName
      }
    })

    const celulasList = Object.keys(celulas).map((celulaKey) => ({
      value: celulaKey,
      label: celulas[celulaKey],
    }))

    const sortedCelulas = celulasList.sort((a, b) => {
      return a.label > b.label ? 0 : -1
    })

    return {
      celulas: sortedCelulas,
    }
  }, [savingFunds])

  const parsedSavingFunds = useMemo(() => {
    if (stateSavingFunds && stateSavingFunds.length) {
      return parseSavingFundsTable(stateSavingFunds)
    }
    return []
  }, [stateSavingFunds])

  const totalSelected = useMemo(() => {
    let sum = 0
    if (selectedSavingFunds && selectedSavingFunds?.selectedCount > 0) {
      sum = selectedSavingFunds?.selectedRows.reduce((total, current) => {
        const realSavingFund = savingFunds?.find((c) => c._id === current.id)
        if (realSavingFund) {
          return (
            total + (realSavingFund.granted ? realSavingFund.granted.amount : 0)
          )
        }
        return total
      }, 0)
    }
    return sum
  }, [selectedSavingFunds])

  if (isFetching || !stateSavingFunds) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>
          Cargando lista de fondo de ahorro.
        </StyledDescription>
      </Container>
    )
  }

  const SavingFundsColumns = getRoleResourceColumns('savingFunds', user?.role)
  const SavingFundsData = getTableData(SavingFundsColumns, parsedSavingFunds)

  const handleClick = (savingFund: any) => {
    navigator(`/credito/${savingFund.creditCode}`)
  }

  const handleLocalFilter = (filter: string) => (option: SelectOption) => {
    setLocalFilters({
      ...localFilters,
      [filter]: option,
    })
  }

  const handleSelect = (selected: TableSelectedData) => {
    setSelectedSavingFunds(selected)
  }

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const searchValue = event.currentTarget.value.toLowerCase()
    setSearch(searchValue)
  }

  const conditional = getConditionalStyles()

  const showCommissionsActions = [Role.Admin, Role.Director].includes(
    user?.role as Role
  )

  const showCommissionTotalToUser = [Role.Advisor, Role.Operator].includes(
    user?.role as Role
  )

  return (
    <StyledSavingFunds>
      <StyledTable>
        <Container direction="row">
          {showCommissionsActions && (
            <Select
              label="Celula"
              items={filters.celulas}
              onChange={handleLocalFilter('celula')}
              value={localFilters.celula}
            />
          )}
          <Input
            placeholder="Buscar"
            onChange={handleSearch}
            preIcon={Icons.SEARCH}
            value={search}
          ></Input>
        </Container>
        {showCommissionTotalToUser && (
          <StyledTotalForCommission>
            Total de Fondo de Ahorro Seleccionado :{' '}
            {formatCurrency(totalSelected)}
          </StyledTotalForCommission>
        )}
        <Table
          columns={SavingFundsColumns}
          data={SavingFundsData}
          onClick={handleClick}
          onSelect={handleSelect}
          conditionalRowStyles={conditional}
          fileName="fondo-ahorro"
        />
      </StyledTable>
    </StyledSavingFunds>
  )
}

export default SavingFunds
