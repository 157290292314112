const Bank: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M14 13a1 1 0 00-1-1h-.25a.25.25 0 01-.25-.25V8.5A.5.5 0 0012 8h-1.5a.5.5 0 00-.5.5v3.25a.25.25 0 01-.25.25H8.5a.25.25 0 01-.25-.25V8.5a.5.5 0 00-.5-.5h-1.5a.5.5 0 00-.5.5v3.25a.25.25 0 01-.25.25H4.25a.25.25 0 01-.25-.25V8.5a.5.5 0 00-.5-.5H2a.5.5 0 00-.5.5v3.25a.25.25 0 01-.25.25H1a1 1 0 000 2h12a1 1 0 001-1zM13.57 4.55L7.9.3a1.53 1.53 0 00-1.8 0L.43 4.55a1.09 1.09 0 00.65 2h11.84a1.09 1.09 0 00.65-1.95z"></path>
    </svg>
  )
}

export default Bank
