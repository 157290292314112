import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import Card from '../../../../components/atoms/Card'
import Container from '../../../../components/atoms/Container'
import Heading from '../../../../components/atoms/Heading'
import Icon from '../../../../components/atoms/Icon'
import Input from '../../../../components/atoms/Input'
import Button from '../../../../components/molecules/Button'
import FileDropper, {
  FileWithBuffer,
} from '../../../../components/molecules/FileDropper'
import List from '../../../../components/atoms/List'
import {
  StyledColumn,
  StyledColumns,
} from '../../../../components/styled/Columns'
import { StyledDescription } from '../../../../components/styled/Description'
import { CreditPhases, Icons } from '../../../../types/enums'
import { StyledStepForm } from '../../styled'
import Select from '../../../../components/atoms/Select'
import { useCatalogs } from '../../../../providers/Catalogs'
import { SelectOption } from '../../../../types/html'
import NumericInput from '../../../../components/atoms/NumericInput'
import { BankCredit } from '../../../../types/credits'
import { Mappeable } from '../../../../types/objects'
import { useForm } from '../../../../providers/Form'
import { useAlert } from '../../../../providers/Alert'
import DateInput from '../../../../components/atoms/DateInput'

export type StepFormProps = {
  continue: (data: any) => void
  back: (data: any) => void
  save: (data: any) => void
}

const BankCredits: React.FC<StepFormProps> = (props) => {
  const { banks } = useCatalogs()
  const { setAlert } = useAlert()
  const { newClientForm } = useForm()
  const stepData = newClientForm?.bankCredits as Mappeable<BankCredit>

  const [bankCredits, setBankCredits] = useState<Mappeable<BankCredit>>(
    stepData || {}
  )
  const [selectedBank, setSelectedBank] = useState<SelectOption | null>(null)
  const [selectedCreditType, setSelectedCreditType] =
    useState<SelectOption | null>(null)
  const [selectedProductType, setSelectedProductType] =
    useState<SelectOption | null>(null)
  const [selectedPhase, setSelectedPhase] = useState<SelectOption | null>({
    value: CreditPhases.Prequalification,
    label: 'Precalificación',
  })

  const bankRef = useRef<HTMLInputElement>(null)
  const creditTypeRef = useRef<HTMLInputElement>(null)
  const productTypeRef = useRef<HTMLInputElement>(null)
  const termRef = useRef<HTMLInputElement>(null)
  const signatureDateRef = useRef<HTMLInputElement>(null)
  const houseValueRef = useRef<HTMLInputElement>(null)
  const creditRequestedRef = useRef<HTMLInputElement>(null)
  const etapaRef = useRef<HTMLInputElement>(null)
  const [solicitudesFiles, setSolicitudesFiles] = useState<FileWithBuffer[]>([])
  const [anexosFiles, setAnexosFiles] = useState<FileWithBuffer[]>([])
  const [solicitudesEditableFiles, setSolicitudesEditableFiles] = useState<
    FileWithBuffer[]
  >([])
  const [otherFiles, setOtherFiles] = useState<FileWithBuffer[]>([])
  const [incomeProofFiles, setIncomeProofFiles] = useState<FileWithBuffer[]>([])
  const [paymentStubsFiles, setPaymentStubsFiles] = useState<FileWithBuffer[]>(
    []
  )
  const [altaHaciendaFiles, setAltaHaciendaFiles] = useState<FileWithBuffer[]>(
    []
  )

  const BANK_CREDITS_TOTAL = Object.values(bankCredits).length

  useEffect(() => {
    props.save({ bankCredits })
  }, [bankCredits])

  function getStepFields(): BankCredit {
    const bankOptions = JSON.parse(bankRef.current?.value || '{}')
    const creditTypeOptions = JSON.parse(creditTypeRef.current?.value || '{}')
    const productTypeOptions = JSON.parse(productTypeRef.current?.value || '{}')
    const phaseOptions = JSON.parse(etapaRef.current?.value || '{}')

    return {
      bank: bankOptions,
      creditType: creditTypeOptions,
      productType: productTypeOptions,
      term: termRef.current?.value || '',
      expectedSignatureDate: signatureDateRef.current?.value || '',
      houseValue: parseFloat(houseValueRef.current?.value || ''),
      phase: phaseOptions,
      creditRequested: parseFloat(creditRequestedRef.current?.value || ''),
      solicitudesFiles: solicitudesFiles || [],
      solicitudesEditableFiles: solicitudesEditableFiles || [],
      anexosFiles: anexosFiles || [],
      incomeProofFiles: incomeProofFiles || [],
      paymentStubsFiles: paymentStubsFiles || [],
      altaHaciendaFiles: altaHaciendaFiles || [],
      otherFiles: otherFiles || [],
    }
  }

  function getBankDescription(bankCredit: BankCredit): string {
    const pesosFormatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })
    const bankName = bankCredit.bank.label
    const creditTypeAndProduct = `${bankCredit.creditType.label} / ${bankCredit.productType.label}`
    const bankTerms = bankCredit.term
    const creditRequested = pesosFormatter.format(bankCredit.creditRequested)
    return `${bankName} - ${creditRequested} - ${bankTerms} - ${creditTypeAndProduct}`
  }

  const handleCancelBankCredit = (bank: string) => {
    const currentBankCredits = { ...bankCredits }
    delete currentBankCredits[bank]
    setBankCredits(currentBankCredits)
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    const phase = etapaRef.current?.value
    let hasRequiredFiles =
      solicitudesFiles.length > 0 &&
      anexosFiles.length > 0 &&
      solicitudesEditableFiles.length > 0

    if (phase === CreditPhases.Phase1) {
      const hasIncomeProof =
        incomeProofFiles.length > 0 || paymentStubsFiles.length > 0

      hasRequiredFiles =
        solicitudesFiles.length > 0 &&
        anexosFiles.length > 0 &&
        solicitudesEditableFiles.length > 0 &&
        hasIncomeProof
    }

    if (
      hasRequiredFiles &&
      etapaRef.current?.value &&
      bankRef.current?.value &&
      creditTypeRef.current?.value &&
      productTypeRef.current?.value &&
      termRef.current?.value &&
      houseValueRef.current?.value &&
      creditRequestedRef.current?.value
    ) {
      const bankCredit = getStepFields()
      const currentBankCredits = { ...bankCredits }
      currentBankCredits[bankCredit.bank.value] = bankCredit
      setBankCredits(currentBankCredits)
      setSolicitudesFiles([])
      setSolicitudesEditableFiles([])
      setAnexosFiles([])
      setIncomeProofFiles([])
      setPaymentStubsFiles([])
      setAltaHaciendaFiles([])
      setOtherFiles([])
    } else {
      setAlert!({
        severity: 'error',
        title: 'Error',
        description:
          'Aún falta informacion para poder mandar esta solicitud de banco.',
      })
    }
  }

  const handleContinue = (event: FormEvent) => {
    event.preventDefault()
    if (BANK_CREDITS_TOTAL > 0) {
      props.continue({
        bankCredits,
      })
    } else {
      setAlert!({
        severity: 'error',
        title: 'Error',
        description: 'Por lo menos una solicitud de credito es requerida.',
      })
    }
  }

  const handleBack = (event: FormEvent) => {
    event.preventDefault()
    props.back({
      bankCredits: bankCredits,
    })
  }

  const handleBankChange = (item: SelectOption) => {
    setSelectedBank(item)
    setSelectedCreditType(null)
    setSelectedProductType(null)
  }

  const handleCreditTypeChange = (item: SelectOption) => {
    setSelectedCreditType(item)
    setSelectedProductType(null)
  }

  const handleProductTypeChange = (item: SelectOption) => {
    setSelectedProductType(item)
  }

  const handlePhaseChange = (item: SelectOption) => {
    setSelectedPhase(item)
  }

  const Header = (
    <Container align="left" padding>
      <Heading type="3" color="var(--color-red-2)">
        Solicitudes de Banco
      </Heading>
      <StyledDescription>Paso 2</StyledDescription>
    </Container>
  )
  const Footer = (
    <Container align="right" direction="row" padding>
      <Button priority="secondary" onClick={handleBack}>
        Atras
      </Button>
      <Button type="submit">Agregar Solicitud</Button>
    </Container>
  )

  const HeaderCredits = (
    <Container align="left" padding>
      <Heading type="3" color="var(--color-red-2)">
        {`Numero de Solicitudes: ${BANK_CREDITS_TOTAL}`}
      </Heading>
      <StyledDescription>
        Si deseas agregar mas solicitudes de credito, puedes llenar la forma de
        nuevo.
      </StyledDescription>
    </Container>
  )
  const FooterCredits = (
    <Container align="right" direction="row" padding>
      <Button onClick={handleContinue}>Siguiente</Button>
    </Container>
  )

  const recentlyAddedBanks = Object.values(bankCredits).map((bankCredit) => {
    return {
      id: bankCredit.bank.value,
      content: (
        <Container align="center" direction="row" padding>
          <Icon name={Icons.BANK} />
          <StyledDescription>
            {getBankDescription(bankCredit)}
          </StyledDescription>
          <Icon
            name={Icons.MINUS_CIRCLE}
            color="var(--color-red-2)"
            onClick={() => handleCancelBankCredit(bankCredit.bank.value)}
          />
        </Container>
      ),
    }
  })

  const bankToItems = useMemo(() => {
    return banks?.map((bank) => {
      return {
        value: bank._id,
        label: bank.name,
      }
    })
  }, [banks])

  const creditTypesPerBank = useMemo(() => {
    if (banks && selectedBank) {
      const bankInfo = banks.find((bank) => bank._id === selectedBank.value)
      return bankInfo?.creditTypes.map((creditType) => {
        return {
          value: creditType.type._id,
          label: creditType.type.name,
        }
      })
    }
    return []
  }, [banks, selectedBank])

  const productTypesPerCreditType = useMemo(() => {
    if (banks && selectedBank && selectedCreditType) {
      const bankInfo = banks.find((bank) => bank._id === selectedBank.value)
      const creditTypeInfo = bankInfo?.creditTypes.find(
        (creditType) => creditType.type._id === selectedCreditType.value
      )

      if (creditTypeInfo) {
        return creditTypeInfo.products.map((productType) => {
          return {
            value: productType._id,
            label: productType.name,
          }
        })
      }
      return []
    }
    return []
  }, [banks, selectedCreditType, selectedBank])

  const solicitudFile = solicitudesFiles
    ? solicitudesFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const solicitudEditableFile = solicitudesEditableFiles
    ? solicitudesEditableFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const anexoFile = anexosFiles
    ? anexosFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const incomeProofFile = incomeProofFiles
    ? incomeProofFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const paymentStubFile = paymentStubsFiles
    ? paymentStubsFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const altaHaciendFile = altaHaciendaFiles
    ? altaHaciendaFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const otherFile = otherFiles
    ? otherFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const etapaItems = [
    {
      value: CreditPhases.Prequalification,
      label: 'Precalificación',
    },
    {
      value: CreditPhases.Phase1,
      label: 'Analisis',
    },
  ]

  return (
    <StyledStepForm onSubmit={handleSubmit}>
      {BANK_CREDITS_TOTAL > 0 && (
        <Card header={HeaderCredits} footer={FooterCredits}>
          <List items={recentlyAddedBanks} />
        </Card>
      )}

      <Card header={Header} footer={Footer}>
        <StyledColumns>
          <StyledColumn>
            <Select
              label="Banco"
              ref={bankRef}
              items={bankToItems}
              loading={!bankToItems}
              value={selectedBank}
              onChange={handleBankChange}
              required
            />
            <Select
              label="Tipo de Credito"
              ref={creditTypeRef}
              items={creditTypesPerBank}
              disabled={
                creditTypesPerBank?.length === 0 ||
                creditTypesPerBank === undefined
              }
              required
              value={selectedCreditType}
              onChange={handleCreditTypeChange}
            />
            <Select
              label="Tipo de Producto"
              ref={productTypeRef}
              items={productTypesPerCreditType}
              disabled={
                productTypesPerCreditType?.length === 0 ||
                productTypesPerCreditType === undefined
              }
              required
              value={selectedProductType}
              onChange={handleProductTypeChange}
            />
            <Input
              label="Plazo en años"
              ref={termRef}
              max="25"
              type="number"
              required
            />
            <DateInput
              label="Fecha Probable de Escrituración"
              ref={signatureDateRef}
              required
            />
            <NumericInput label="Valor Vivienda" ref={houseValueRef} required />
            <NumericInput
              label="Monto Credito"
              ref={creditRequestedRef}
              required
            />
          </StyledColumn>
        </StyledColumns>
        <StyledColumns>
          <StyledColumn>
            <StyledDescription>
              En base a la Etapa que deseas ingresar, se pediran un listado de
              documentos:
            </StyledDescription>
            <Select
              label="Etapa"
              required
              items={etapaItems}
              ref={etapaRef}
              value={selectedPhase}
              onChange={handlePhaseChange}
            />
            <FileDropper
              label="Solicitudes"
              onChange={setSolicitudesFiles}
              files={solicitudFile}
            />
            <FileDropper
              label="Solicitud Editable"
              onChange={setSolicitudesEditableFiles}
              files={solicitudEditableFile}
            />
            <FileDropper
              label="Anexos"
              onChange={setAnexosFiles}
              files={anexoFile}
            />
            {selectedPhase?.value === CreditPhases.Phase1 && (
              <>
                <FileDropper
                  label="Estados de Cuenta"
                  onChange={setIncomeProofFiles}
                  files={incomeProofFile}
                />
                <FileDropper
                  label="Recibos de Nomina"
                  onChange={setPaymentStubsFiles}
                  files={paymentStubFile}
                />
                <FileDropper
                  label="Alta de Hacienda"
                  onChange={setAltaHaciendaFiles}
                  files={altaHaciendFile}
                />
              </>
            )}
            <FileDropper
              label="Otros"
              onChange={setOtherFiles}
              files={otherFile}
            />
          </StyledColumn>
        </StyledColumns>
      </Card>
    </StyledStepForm>
  )
}

export default BankCredits
