import React, { useState } from 'react'
import { NumberFormatValues } from 'react-number-format/types/types'
import { Icons } from '../../../types/enums'
import { ReactBaseElement } from '../../../types/react'
import { StyledError } from '../../styled/Error'
import Icon from '../Icon'
import {
  StyledInputContainer,
  StyledTextContainer,
  StyledLabel,
  StyledMask,
} from './styled'

type NumberMaskInputProps = {
  label?: string
  placeholder?: string
  required?: boolean
  autocomplete?: string
  error?: string
  type?: 'text' | 'password' | 'email' | 'number'
  preIcon?: Icons
  afterIcon?: Icons
  maxLength?: number
  mask: string
  value?: string
  onChange?: (value: string) => void
} & ReactBaseElement

const NumberMaskInput = React.forwardRef(
  (props: NumberMaskInputProps, ref: React.Ref<HTMLInputElement>) => {
    const [value, setValue] = useState<string>(props.value || '')

    const handleChange = (values: NumberFormatValues) => {
      if (props.onChange) {
        props.onChange(values.value)
      }
      setValue(values.value)
    }

    return (
      <StyledInputContainer>
        {props.preIcon && <Icon name={props.preIcon} />}
        <StyledLabel>{props.label}</StyledLabel>
        <StyledTextContainer>
          <StyledMask
            format={props.mask}
            mask="_"
            placeholder={props.placeholder || '...'}
            required={props.required}
            autoComplete={props.autocomplete}
            onValueChange={handleChange}
            defaultValue={props.value}
          />
          <input ref={ref} value={value} readOnly hidden />
          {props.error && <StyledError>{props.error}</StyledError>}
        </StyledTextContainer>
      </StyledInputContainer>
    )
  }
)

export default NumberMaskInput
