import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import { StyledActionsContaner, StyledFollowUpTable } from './styled'
import { ReactBaseElement } from '../../types/react'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import { FollowUpRow } from '../../types/follow-up'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { getFollowUp } from '../../services/follow-up'
import { useQuery } from 'react-query'
import { BankCreditListItem } from '../../types/credits'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import {
  getConditionalStyles,
  parseBankCreditForTable,
} from '../../utils/credits'
import Input from '../../components/atoms/Input'
import { CreditPhases, Icons } from '../../types/enums'
import Heading from '../../components/atoms/Heading'
import { TableSelectedData } from '../../types/tables'
import { formatCurrency } from '../../utils/currency'
import { useTranslation } from '../../providers/I18n'
import { useNavigate } from 'react-router-dom'
import Select from '../../components/atoms/Select'
import { SelectOption } from '../../types/html'

type FollowUpTableProps = ReactBaseElement & {
  showAll?: boolean
}

let cachedFollowUp: BankCreditListItem[] | undefined = undefined

const FollowUpTable: React.FC<FollowUpTableProps> = (props) => {
  const { languages } = useTranslation()
  const { user } = useUser()
  const [search, setSearch] = useState('')
  const navigator = useNavigate()
  const [selectedPhase, setSelectedPhase] = useState<SelectOption | null>(null)
  const [stateCredits, setStateCredits] = useState<
    BankCreditListItem[] | undefined
  >(cachedFollowUp)
  const [selectedCredits, setSelectedCredits] = useState<TableSelectedData>()

  const { data: credits, isFetching } = useQuery<BankCreditListItem[]>(
    'getFollowUpCredits',
    () =>
      getFollowUp({
        showAll: props.showAll,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        cachedFollowUp = data
        setStateCredits(data)
      },
    }
  )

  useEffect(() => {
    if (!search && !selectedPhase) {
      setStateCredits(credits)
    } else {
      const newListCredits = credits!.filter((credit) => {
        const matchText =
          credit.code.toString().includes(search) ||
          credit.owners.advisor.displayName.toLowerCase().includes(search) ||
          credit.owners.prequalificationOperator?.displayName
            .toLowerCase()
            .includes(search) ||
          credit.owners.phase1Operator?.displayName
            .toLowerCase()
            .includes(search) ||
          credit.owners.phase2Operator?.displayName
            .toLowerCase()
            .includes(search) ||
          credit.owners.phase3Operator?.displayName
            .toLowerCase()
            .includes(search) ||
          credit.client.name.toLowerCase().includes(search) ||
          credit.bank.name.toLowerCase().includes(search) ||
          credit.meta.creditType.name.toLowerCase().includes(search) ||
          credit.meta.productType.name.toLowerCase().includes(search) ||
          credit.client.meta.contactForm.name.toLowerCase().includes(search)

        const selectedPhaseValue =
          selectedPhase?.value && selectedPhase?.value !== '--'
        const matchPhase = selectedPhaseValue
          ? credit.phase === selectedPhase?.value
          : true

        return matchText && matchPhase
      })
      setStateCredits(newListCredits)
    }
  }, [search, selectedPhase])

  const parsedCredits = useMemo(() => {
    if (stateCredits && stateCredits.length) {
      return parseBankCreditForTable(stateCredits, languages!)
    }
    return []
  }, [stateCredits])

  const totalSelected = useMemo(() => {
    let sum = 0
    if (selectedCredits && selectedCredits?.selectedCount > 0) {
      sum = selectedCredits?.selectedRows.reduce((total, current) => {
        const realCredit = credits?.find(
          (c) => c.code.toString() === current.id
        )
        if (realCredit) {
          let creditAmount
          if (realCredit.meta.finalCreditAmount) {
            creditAmount = parseFloat(realCredit.meta.finalCreditAmount)
          } else if (realCredit.meta.formalizacionMontoCredito) {
            creditAmount = realCredit.meta.formalizacionMontoCredito
          } else if (realCredit.meta.authorizedCredit) {
            creditAmount = realCredit.meta.authorizedCredit
          } else {
            creditAmount = realCredit.meta.creditRequested
          }

          return total + creditAmount
        }
        return total
      }, 0)
    }
    return sum
  }, [selectedCredits])

  if ((isFetching && !cachedFollowUp) || !stateCredits) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando lista de creditos.</StyledDescription>
      </Container>
    )
  }

  const followUpColumns = getRoleResourceColumns('followUp', user?.role)
  const followUpData = getTableData(followUpColumns, parsedCredits)

  const handleClick = (credit: FollowUpRow) => {
    navigator(`/credito/${credit.id}`)
  }

  const handleSelect = (selected: TableSelectedData) => {
    setSelectedCredits(selected)
  }

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const searchValue = event.currentTarget.value.toLowerCase()
    setSearch(searchValue)
  }

  const conditional = getConditionalStyles(user!)

  const phasesOptions = [
    {
      value: CreditPhases.Prequalification,
      label: 'Precalificación',
    },
    {
      value: CreditPhases.Phase1,
      label: 'Etapa 1',
    },
    {
      value: CreditPhases.Phase2,
      label: 'Etapa 2',
    },
    {
      value: CreditPhases.Phase3,
      label: 'Etapa 3',
    },
  ]

  return (
    <StyledFollowUpTable>
      <Container>
        <Heading type="4" color="var(--color-gray-2)">
          {`Total Seleccionado: ${formatCurrency(totalSelected)}`}
        </Heading>
        <StyledActionsContaner>
          <Select
            label="Etapa"
            required
            onChange={setSelectedPhase}
            value={selectedPhase}
            items={phasesOptions}
          />
          <Input
            placeholder="Buscar"
            onChange={handleSearch}
            preIcon={Icons.SEARCH}
            value={search}
          ></Input>
        </StyledActionsContaner>
      </Container>
      <Table
        columns={followUpColumns}
        data={followUpData}
        onClick={handleClick}
        onSelect={handleSelect}
        conditionalRowStyles={conditional}
        fileName="seguimiento"
      />
    </StyledFollowUpTable>
  )
}

export default FollowUpTable
