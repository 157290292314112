const PercentageCircle: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M7 0a7 7 0 107 7 7 7 0 00-7-7zM4.75 3.75a1 1 0 11-1 1 1 1 0 011-1zm.53 6a.75.75 0 01-1.06 0 .75.75 0 010-1.06l4.5-4.5a.75.75 0 011.06 1.09zm4 .47a1 1 0 111-1 1 1 0 01-1.03 1.03z"></path>
    </svg>
  )
}

export default PercentageCircle
