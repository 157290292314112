import Config from '../config/environment'

const { REST_API_URL } = Config

export const clientExists = async function (curp: string) {
  const clientExistsResponse = await fetch(
    `${REST_API_URL}/util/client-exists?curp=${curp}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await clientExistsResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}
