import { createGlobalStyle } from 'styled-components'

const BaselineStyles = createGlobalStyle`
  @keyframes inAnimation {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
`

export default BaselineStyles
