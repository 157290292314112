import { createGlobalStyle } from 'styled-components'

const TypographiesStyles = createGlobalStyle`
  :root {
    --heading-fs-h1: 2rem;
    --heading-fs-h2: 1.5rem;
    --heading-fs-h3: 1.25rem;
    --heading-fs-h4: 1rem;
    --heading-fs-h5: 0.75rem;
    --heading-fs-h6: 0.5rem;

    --fs-xs: 0.75rem;
    --fs-sm: 0.875rem;
    --fs-md: 1rem;
    --fs-lg: 1.25rem;
    --fs-xl: 1.5rem;
    --fs-xxl: 1.75rem;
    --fs-xxxl: 2.5rem;

    --ff-myriad: 'MyriadPro', serif;
    --ff-helvetica: 'HelveticaNeue', serif;
  }

  body {
    font-family: 'HelveticaNeue';
    font-weight: lighter;
  }
`

export default TypographiesStyles
