import React, { FormEvent, useEffect, useRef, useState } from 'react'
import Card from '../../../../components/atoms/Card'
import Container from '../../../../components/atoms/Container'
import Heading from '../../../../components/atoms/Heading'
import Icon from '../../../../components/atoms/Icon'
import List from '../../../../components/atoms/List'
import Textarea from '../../../../components/atoms/Textarea'
import Button from '../../../../components/molecules/Button'
import FileDropper, {
  FileWithBuffer,
} from '../../../../components/molecules/FileDropper'
import {
  StyledColumn,
  StyledColumns,
} from '../../../../components/styled/Columns'
import { StyledDescription } from '../../../../components/styled/Description'
import { useAlert } from '../../../../providers/Alert'
import { useForm } from '../../../../providers/Form'
import { ClientInformation } from '../../../../types/clients'
import { BankCredit } from '../../../../types/credits'
import { CreditPhases, Icons } from '../../../../types/enums'
import { StyledStepForm } from '../../styled'

export type StepFormProps = {
  continue: (data: any) => void
  back: (data: any) => void
  save: (data: any) => void
}

const OtherDocuments: React.FC<StepFormProps> = (props) => {
  const { setAlert } = useAlert()
  const { newClientForm } = useForm()
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const stepData = newClientForm
  const personalInformation =
    newClientForm?.personalInformation as ClientInformation

  const clientIsMarried =
    personalInformation && personalInformation.maritalStatus.value === 'married'

  const someAnalisisCredit = Object.values(newClientForm?.bankCredits).some(
    (bank) => {
      const bankData = bank as BankCredit
      return bankData.phase.value === CreditPhases.Phase1
    }
  )

  const [addressProofFile, setAddressProofFile] = useState<FileWithBuffer[]>(
    stepData?.analisisDocuments?.addressProofFile || []
  )
  const [maritalFile, setMaritalFile] = useState<FileWithBuffer[]>(
    stepData?.analisisDocuments?.maritalFile || []
  )

  const handleClickContinue = (event: FormEvent) => {
    event.preventDefault()

    let hasRequiredFiles = true
    if (someAnalisisCredit) {
      hasRequiredFiles = addressProofFile.length > 0
    }

    const hasComment = !!commentRef.current?.value
    if (hasComment && hasRequiredFiles) {
      props.continue({
        analisisDocuments: {
          addressProofFile,
          maritalFile,
          comments: commentRef.current?.value,
        },
      })
    } else {
      setAlert!({
        severity: 'error',
        title: 'Error',
        description:
          'Aún falta informacion para poder mandar la solicitud a Analisis.',
      })
    }
  }

  const handleBack = (event: FormEvent) => {
    event.preventDefault()
    props.back({
      analisisDocuments: {
        addressProofFile,
        maritalFile,
        comments: commentRef.current?.value,
      },
    })
  }

  useEffect(() => {
    props.save({
      analisisDocuments: {
        addressProofFile,
        maritalFile,
        comments: commentRef.current?.value,
      },
    })
  }, [])

  const Header = (
    <Container align="left" padding>
      <Heading type="3" color="var(--color-red-2)">
        Otros Documentos
      </Heading>
      <StyledDescription>Paso 3</StyledDescription>
    </Container>
  )
  const Footer = (
    <Container align="right" direction="row" padding>
      <Button priority="secondary" onClick={handleBack}>
        Atras
      </Button>
      <Button onClick={handleClickContinue}>Continuar</Button>
    </Container>
  )

  const requiredDocumentsForAnalisis = []

  if (someAnalisisCredit) {
    requiredDocumentsForAnalisis.push('Comprobante de Domicilio')
  }

  const stepDataAddressProofFiles = addressProofFile
    ? addressProofFile.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const stepDataMaritalFile = maritalFile
    ? maritalFile.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const missingFiles = requiredDocumentsForAnalisis.map((document) => {
    return {
      id: document,
      content: (
        <Container align="left" direction="row">
          <Icon name={Icons.FILE_PLUS} />
          <StyledDescription>{document}</StyledDescription>
        </Container>
      ),
    }
  })

  const clientStatus =
    missingFiles.length > 0 ? (
      <p>
        Tu cliente esta listo, solo que detectamos que aun faltan algunos
        documentos para poder mandar algunos creditos directamente a Analisis.
      </p>
    ) : (
      <p>
        Tu cliente esta listo y todos los creditos estan completados, si deseas
        agregar algun otro documento auxiliar, favor de ingresarlo aqui.
      </p>
    )

  return (
    <StyledStepForm>
      <Card header={Header} footer={Footer}>
        <StyledColumns>
          <StyledColumn>
            <StyledDescription>{clientStatus}</StyledDescription>
            {missingFiles.length > 0 && <List items={missingFiles} />}

            {someAnalisisCredit && (
              <>
                <FileDropper
                  label="Comprobante de Domicilio"
                  onChange={setAddressProofFile}
                  files={stepDataAddressProofFiles}
                />
              </>
            )}
            {clientIsMarried && (
              <FileDropper
                label="Acta de Matrimonio"
                onChange={setMaritalFile}
                files={stepDataMaritalFile}
              />
            )}
            <StyledDescription>
              Si tienes algun comentario extra, puedes ingresarlo aqui.
            </StyledDescription>
            <Textarea label="Comentarios" ref={commentRef} required />
          </StyledColumn>
        </StyledColumns>
      </Card>
    </StyledStepForm>
  )
}

export default OtherDocuments
