import styled from 'styled-components'

export const StyledTableContainer = styled.div`
  width: 100%;
  border: 1px solid var(--color-gray-2);
  border-radius: 5px;

  .rdt_Table {
    border-bottom: 1px solid var(--color-gray-2);
    border-radius: 5px;
  }

  .rdt_TableHeadRow {
    background-color: var(--color-gray-3);
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .rdt_TableCol div {
    text-align: center;
    justify-content: center;
  }

  .rdt_TableCol,
  .rdt_TableCell {
    border-left: 1px dotted var(--color-gray-5);
  }

  .rdt_TableHeader {
    div:nth-child(2) {
      display: none;
    }
  }

  .rdt_TableRow {
    min-height: 30px;
  }

  .rdt_TableCol,
  .rdt_TableCell {
    padding-left: 5px;
    padding-right: 5px;
  }
`

export const StyledTableHeader = styled.div`
  position: relative;
  background-color: var(--color-white);
  z-index: 20;
`

export const StyledTableFooter = styled.div`
  padding: 0.5rem;
`
