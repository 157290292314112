const PersonPlus: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <circle cx="4.75" cy="2.75" r="2.75"></circle>
      <path d="M8.5 13.25a.25.25 0 00-.25-.25 2.25 2.25 0 01-1-4.28.26.26 0 00.14-.21.24.24 0 00-.11-.23 4.58 4.58 0 00-2.1-.76A4.75 4.75 0 000 12.25V13a1 1 0 001 1h7.3a.22.22 0 00.19-.09.27.27 0 00.06-.21 2.3 2.3 0 01-.05-.45z"></path>
      <path d="M14 10.75a.76.76 0 00-.75-.75h-1.5a.25.25 0 01-.25-.25v-1.5a.75.75 0 00-1.5 0v1.5a.25.25 0 01-.25.25h-1.5a.75.75 0 000 1.5h1.5a.25.25 0 01.25.25v1.5a.75.75 0 001.5 0v-1.5a.25.25 0 01.25-.25h1.5a.76.76 0 00.75-.75z"></path>
    </svg>
  )
}

export default PersonPlus
