import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthPage from './pages/Auth'
import LoadingPage from './pages/Loading'
import LogoutPage from './pages/Logout'
import NotFoundPage from './pages/NotFound'
import ProfilePage from './pages/Profile'
import { useAuth } from './providers/Auth'
import { useUser } from './providers/User'
import { getRolePages } from './utils/pages'

const Router = () => {
  const { isAuth, isLoggingOut } = useAuth()
  const { user, isFetchingUser, errorFetchingUser } = useUser()
  const [routes, setRoutes] = useState<React.ReactNode>()

  useEffect(() => {
    if (!isAuth || errorFetchingUser) {
      setRoutes([<Route key="*" path="*" element={<AuthPage />} />])
    } else if (isLoggingOut) {
      setRoutes([<Route key="*" path="*" element={<LogoutPage />} />])
    } else if (isAuth && (isFetchingUser || !user)) {
      setRoutes([<Route key="*" path="*" element={<LoadingPage />} />])
    } else if (isAuth && user) {
      const routesMap = []

      const userRole = user?.role || 'advisor'
      const allowedRoutes = getRolePages(userRole, true)
      allowedRoutes.forEach((route) => {
        let Component = NotFoundPage
        if (route.component) {
          Component = route.component
        }
        routesMap.push(
          <Route key={route.path} path={route.path} element={<Component />} />
        )
      })
      routesMap.push(
        <Route key="profile" path="/perfil" element={<ProfilePage />} />,
        <Route key="logout" path="/logout" element={<LogoutPage />} />,
        <Route key="notFound" path="*" element={<NotFoundPage />} />
      )

      setRoutes(routesMap)
    }
  }, [isAuth, user, isFetchingUser, isLoggingOut, errorFetchingUser])

  return (
    <BrowserRouter>
      <Routes>{routes}</Routes>
    </BrowserRouter>
  )
}

export default Router
