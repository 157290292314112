import React from 'react'
import Container from '../../../components/atoms/Container'
import { StyledDescription } from '../../../components/styled/Description'
import { StyledEmoji } from '../../../components/styled/Emoji'

type Props = {
  text?: string
  owner: {
    displayName: string
  }
}

const CreditWaitAction: React.FC<Props> = (props) => {
  let defaultText = `El tramite esta en proceso de asignación, favor de revisar con generencía.`
  if (props.owner) {
    defaultText = `El tramite esta siendo revisado por ${props.owner.displayName}, no hay nada mas que revisar por
    el momento, gracias.`
  }

  return (
    <Container align="center" padding>
      <StyledEmoji>🕑</StyledEmoji>
      <StyledDescription>{props.text || defaultText}</StyledDescription>
    </Container>
  )
}

export default CreditWaitAction
