const PlusCircle: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M7 0a7 7 0 107 7 7 7 0 00-7-7zm3 8H8.25a.25.25 0 00-.25.25V10a1 1 0 01-2 0V8.25A.25.25 0 005.75 8H4a1 1 0 010-2h1.75A.25.25 0 006 5.75V4a1 1 0 012 0v1.75a.25.25 0 00.25.25H10a1 1 0 010 2z"></path>
    </svg>
  )
}

export default PlusCircle
