import Config from '../config/environment'
import { SignedUrlRequest } from '../types/s3'

const { REST_API_URL } = Config

export const getSignedUrls = async function (payload: SignedUrlRequest) {
  const signedUrlResponse = await fetch(`${REST_API_URL}/s3`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = await signedUrlResponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const putWithSignedUrl = async function (url: string, file: File) {
  const signedUrlResponse = await fetch(url, {
    method: 'PUT',
    body: file,
  })
  return signedUrlResponse
}
