import styled from 'styled-components'
import { StyledLogo } from '../../styled/Logo'

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - var(--spc-sm) - var(--spc-sm));
  gap: 1rem;
  font-size: var(--fs-xl);
  border-bottom: 1px solid;
  border-color: var(--color-gray-5);
  padding: var(--spc-xs) var(--spc-sm);
  background-color: var(--color-white);

  ${StyledLogo} {
    width: 5rem;
    height: 3rem;
    background-position: left;
    background-size: 100% 100%;
  }
`
