const LoginCircle: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M9.7 9.25a.24.24 0 00-.24.2 2.25 2.25 0 01-.62 1.14 2.25 2.25 0 01-3.18 0l-2-2a2.25 2.25 0 010-3.18l2-2a2.25 2.25 0 013.18 0 2.25 2.25 0 01.62 1.14.24.24 0 00.24.2h2.53a.26.26 0 00.21-.11.26.26 0 000-.24 6.5 6.5 0 100 5.2.26.26 0 000-.24.26.26 0 00-.21-.11z"></path>
      <path d="M14 7a.76.76 0 00-.75-.75H7.66a.25.25 0 01-.23-.15.26.26 0 01.06-.28l.29-.29a.75.75 0 000-1.06.75.75 0 00-1.06 0l-2 2a.75.75 0 000 1.06l2 2a.75.75 0 001.06 0 .75.75 0 000-1.06l-.29-.29a.26.26 0 01-.06-.28.25.25 0 01.23-.15h5.59A.76.76 0 0014 7z"></path>
    </svg>
  )
}

export default LoginCircle
