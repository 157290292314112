import React from 'react'
import { ReactContainerElement } from '../../../types/react'
import {
  StyledCard,
  StyledCardContent,
  StyledCardFooter,
  StyledCardHeader,
} from './styled'

type CardProps = {
  header?: React.ReactNode
  footer?: React.ReactNode
} & ReactContainerElement

export const Card: React.FC<CardProps> = (props) => {
  return (
    <StyledCard>
      {props.header && <StyledCardHeader>{props.header}</StyledCardHeader>}
      <StyledCardContent>{props.children}</StyledCardContent>
      {props.footer && <StyledCardFooter>{props.footer}</StyledCardFooter>}
    </StyledCard>
  )
}

export default Card
