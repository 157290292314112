import React from 'react'
import Heading from '../../components/atoms/Heading'
import AllocationsTable from '../../templates/AllocationsTable'
import Layout from '../../templates/Layout'

const AllocationPage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        Colocación
      </Heading>
      <AllocationsTable />
    </Layout>
  )
}

export default AllocationPage
