import React, { Fragment, useMemo, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import Select from '../../../../../components/atoms/Select'
import Input from '../../../../../components/atoms/Input'
import NumericInput from '../../../../../components/atoms/NumericInput'
import DateInput from '../../../../../components/atoms/DateInput'
import { SelectOption } from '../../../../../types/html'
import { useCatalogs } from '../../../../../providers/Catalogs'
import { formatCurrency } from '../../../../../utils/currency'
import { useAlert } from '../../../../../providers/Alert'
import Spinner from '../../../../../components/atoms/Spinner'

const Phase1Authorized: React.FC = () => {
  const { banks } = useCatalogs()
  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const [loading, setLoading] = useState<boolean>(false)
  const { getTranslation } = useTranslation()
  const [selectedCreditType, setSelectedCreditType] =
    useState<SelectOption | null>(null)
  const [selectedProductType, setSelectedProductType] =
    useState<SelectOption | null>(null)

  const authorizedCreditTypeRef = useRef<HTMLInputElement>(null)
  const authorizedProductTypeRef = useRef<HTMLInputElement>(null)
  const authorizedCreditRef = useRef<HTMLInputElement>(null)
  const authorizedTermsInYearsRef = useRef<HTMLInputElement>(null)
  const authorizedProductSubtypeRef = useRef<HTMLInputElement>(null)
  const authorizedTasaRef = useRef<HTMLInputElement>(null)
  const authorizedAforoRef = useRef<HTMLInputElement>(null)
  const authorizedExpDateRef = useRef<HTMLInputElement>(null)
  const authorizedPromotionRef = useRef<HTMLInputElement>(null)
  const authorizedOriginalesRef = useRef<HTMLInputElement>(null)
  const authorizedPromotionTypeRef = useRef<HTMLTextAreaElement>(null)
  const authorizedCommentsRef = useRef<HTMLTextAreaElement>(null)

  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  const handleCreditTypeChange = (item: SelectOption) => {
    setSelectedCreditType(item)
    setSelectedProductType(null)
  }

  const handleProductTypeChange = (item: SelectOption) => {
    setSelectedProductType(item)
  }

  const creditTypesPerBank = useMemo(() => {
    if (banks && credit?.bank) {
      const bankInfo = banks.find((bank) => bank._id === credit?.bank._id)
      return bankInfo?.creditTypes.map((creditType) => {
        return {
          value: creditType.type._id,
          label: creditType.type.name,
        }
      })
    }
    return []
  }, [banks, credit?.bank])

  const productTypesPerCreditType = useMemo(() => {
    if (banks && credit?.bank && selectedCreditType) {
      const bankInfo = banks.find((bank) => bank._id === credit?.bank._id)
      const creditTypeInfo = bankInfo?.creditTypes.find(
        (creditType) => creditType.type._id === selectedCreditType.value
      )

      if (creditTypeInfo) {
        return creditTypeInfo.products.map((productType) => {
          return {
            value: productType._id,
            label: productType.name,
          }
        })
      }
      return []
    }
    return []
  }, [banks, selectedCreditType, credit?.bank])

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.phase1Operator?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const handleSendToAppraisalDocuments = async () => {
        setLoading(true)

        const creditTypeOptions = JSON.parse(
          authorizedCreditTypeRef.current?.value || '{}'
        )
        const productTypeOptions = JSON.parse(
          authorizedProductTypeRef.current?.value || '{}'
        )
        const promotionOptions = JSON.parse(
          authorizedPromotionRef.current?.value || '{}'
        )
        const originalesOptions = JSON.parse(
          authorizedOriginalesRef.current?.value || '{}'
        )

        const authorizedInfo = {
          authorizedCreditType: creditTypeOptions.value,
          authorizedProductType: productTypeOptions.value,
          authorizedCredit: parseFloat(
            authorizedCreditRef.current?.value || ''
          ),
          authorizedProductSubtype:
            authorizedProductSubtypeRef.current?.value || '',
          authorizedTerms: authorizedTermsInYearsRef.current?.value || '',
          authorizedTasa: authorizedTasaRef.current?.value || '',
          authorizedAforo: authorizedAforoRef.current?.value || '',
          authorizedExpDate: authorizedExpDateRef.current?.value || '',
          authorizedOriginales: originalesOptions.value,
          authorizedPromotion: promotionOptions.value,
          authorizedPromotionType:
            authorizedPromotionTypeRef.current?.value || '',
        }

        let step = CreditSteps.Phase1AppraisalDocuments
        let commentStep = `Avanzando a Etapa 1 - Carga Documentos Avalúo`
        if (originalesOptions.value === 'No') {
          step = CreditSteps.Phase1MissingOriginales
          commentStep = `Avanzando a Etapa 1 - Pendiente Carga de Originales`
        }

        const hasComment = !!authorizedCommentsRef.current?.value
        const hasCreditType = !!creditTypeOptions.value
        const hasProductType = !!productTypeOptions.value
        const hasAuthCredit = !!authorizedCreditRef.current?.value
        const hasTerms = !!authorizedTermsInYearsRef.current?.value
        const hasAforo = !!authorizedAforoRef.current?.value
        const hasExpirationDate = !!authorizedExpDateRef.current?.value
        const hasPromotion = !!promotionOptions.value
        const hasOriginales = !!originalesOptions.value
        if (
          !hasComment ||
          !hasCreditType ||
          !hasProductType ||
          !hasAuthCredit ||
          !hasTerms ||
          !hasAforo ||
          !hasExpirationDate ||
          !hasPromotion ||
          !hasOriginales
        ) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase1,
            step: step,
            meta: authorizedInfo,
          }).then(async () => {
            const comment = `${commentStep} :: ${authorizedCommentsRef.current?.value}`
            const expirationDate = new Date(
              authorizedExpDateRef.current?.value || ''
            )
            expirationDate.setDate(expirationDate.getDate() + 1)
            const creditHistoryComment = {
              comment: comment,
              type: 'status',
              start: new Date(),
              meta: {
                authorizedCreditType: creditTypeOptions.label,
                authorizedProductType: productTypeOptions.label,
                authorizedProductSubtype:
                  authorizedProductSubtypeRef.current?.value || '',
                authorizedCredit: formatCurrency(
                  parseFloat(authorizedCreditRef.current?.value || '')
                ),
                authorizedTerms: authorizedTermsInYearsRef.current?.value || '',
                authorizedTasa: authorizedTasaRef.current?.value || '',
                authorizedAforo: authorizedAforoRef.current?.value || '',
                authorizedExpDate: expirationDate.toLocaleDateString('es-MX', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }),
                authorizedPromotion: promotionOptions.value,
                authorizedOriginales: originalesOptions.value,
                authorizedPromotionType:
                  authorizedPromotionTypeRef.current?.value || '',
              },
              change: {
                from: {
                  owner: credit.owners.phase1Operator._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: credit.owners.advisor._id,
                  phase: CreditPhases.Phase1,
                  step: step,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (authorizedCommentsRef.current) {
                  authorizedCommentsRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      const handleSendBack = async () => {
        setLoading(true)

        await updateCredit(credit!.code, {
          step: CreditSteps.Phase1Analysis,
        }).then(async () => {
          const comment = `Regresando a Etapa 1 - Analisis :: ${authorizedCommentsRef.current?.value}`
          const creditHistoryComment = {
            comment: comment,
            type: 'status',
            start: new Date(),
            change: {
              from: {
                owner: credit.owners.advisor._id,
                step: credit.step,
              },
              to: {
                owner: credit.owners.phase1Operator._id,
                step: CreditSteps.Phase1Analysis,
              },
            },
          }

          await addCreditHistory(credit!.code, creditHistoryComment).then(
            () => {
              if (authorizedCommentsRef.current) {
                authorizedCommentsRef.current.value = ''
              }
              refetch().then(() => {
                setLoading(false)
              })
            }
          )
        })
      }

      const yesNoItems = [
        {
          value: 'Si',
          label: 'Si',
        },
        {
          value: 'No',
          label: 'No',
        },
      ]

      content = (
        <Fragment>
          <StyledDescription>
            Nuestras felicitaciones al cliente! Su Credito esta autorizado.
            Favor de Ingresar los datos de autorización y cargar pantalla o
            correo de autorización.
          </StyledDescription>
          <Select
            label="Tipo de Credito"
            required
            onChange={handleCreditTypeChange}
            value={selectedCreditType}
            items={creditTypesPerBank}
            ref={authorizedCreditTypeRef}
          />
          <Select
            label="Tipo de Producto"
            required
            onChange={handleProductTypeChange}
            value={selectedProductType}
            items={productTypesPerCreditType}
            ref={authorizedProductTypeRef}
          />
          <Input
            label="Subtipo de Producto (en caso de aplicar)"
            ref={authorizedProductSubtypeRef}
            type="text"
          />
          <NumericInput
            label="Credito Autorizado"
            required
            ref={authorizedCreditRef}
          />
          <Input
            label="Plazo en años"
            required
            ref={authorizedTermsInYearsRef}
            type="number"
            max="25"
          />
          <Input label="Tasa %" required ref={authorizedTasaRef} />
          <Input label="Aforo %" required ref={authorizedAforoRef} />
          <DateInput
            label="Fecha de Vigencia"
            required
            ref={authorizedExpDateRef}
          />
          <Select
            label="Promocion?"
            required
            items={yesNoItems}
            ref={authorizedPromotionRef}
          />
          <Textarea
            label="Tipo de Promocion"
            ref={authorizedPromotionTypeRef}
          />
          <Select
            label="Se entregaron Originales?"
            required
            items={yesNoItems}
            ref={authorizedOriginalesRef}
          />
          <Textarea label="Comentarios" ref={authorizedCommentsRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <>
              {user?.role !== Role.Advisor && (
                <Button
                  color={color}
                  priority="secondary"
                  onClick={handleSendBack}
                  loading={loading}
                >
                  Regresar a Analisis
                </Button>
              )}
              <Button
                onClick={handleSendToAppraisalDocuments}
                color={color}
                loading={loading}
              >
                Mandar a Carga de Documentos Avalúo
              </Button>
            </>
          )}
        </Container>
      )
    } else {
      content = <CreditWaitAction owner={credit.owners.phase1Operator} />
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase1Authorized
