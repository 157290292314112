import { ModalProps } from '@mui/material'
import React, { useRef } from 'react'
import { useQuery } from 'react-query'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import Select from '../../components/atoms/Select'
import Spinner from '../../components/atoms/Spinner'
import Button from '../../components/molecules/Button'
import { StyledDescription } from '../../components/styled/Description'
import { useTranslation } from '../../providers/I18n'
import { getOperators } from '../../services/operators'
import { CreditPhases } from '../../types/enums'
import { SelectOption } from '../../types/html'
import { OperatorGetAllItem } from '../../types/operators'
import { StyledDialog, StyledDialogContent } from './styled'

type EditCreditOperatorModalProps = {
  open: boolean
  onClose: ModalProps['onClose']
  onSubmit: (operator: SelectOption) => void
  field: string
}

const EditCreditOperatorModal = (props: EditCreditOperatorModalProps) => {
  const { getTranslation } = useTranslation()
  const operatorRef = useRef<HTMLInputElement>(null)
  const fieldText = getTranslation!(`editable.${props.field}.label`)
  const description = getTranslation!(`editable.${props.field}.description`)

  const { data: operators, isLoading } = useQuery<OperatorGetAllItem[]>(
    ['getOperators'],
    () => getOperators(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const filteredOptions = operators?.reduce<SelectOption[]>(
    (options, currentOp) => {
      if (currentOp.phase === CreditPhases.Phase1) {
        options.push({
          value: currentOp.user._id,
          label: currentOp.user.displayName,
        })
      }
      return options
    },
    []
  )

  const handleChangeOperator = () => {
    const operator =
      operatorRef.current && JSON.parse(operatorRef.current.value || '{}')
    if (operator.value) {
      props.onSubmit(operator)
      if (props.onClose) props.onClose({}, 'backdropClick')
    }
  }

  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <StyledDialogContent>
        <Heading type="4" border>
          {`Editar ${fieldText}`}
        </Heading>
        <StyledDescription>{description}</StyledDescription>
        {isLoading ? (
          <Container>
            <StyledDescription>Cargando Operadoras</StyledDescription>
            <Spinner type="bar" />
          </Container>
        ) : (
          <Container>
            <Select
              ref={operatorRef}
              label="Operadora"
              items={filteredOptions}
            />
            <Button onClick={handleChangeOperator}>Cambiar</Button>
          </Container>
        )}
      </StyledDialogContent>
    </StyledDialog>
  )
}

export default EditCreditOperatorModal
