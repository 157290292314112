import React, { useEffect, useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { Link } from 'react-router-dom'
import Card from '../../../../components/atoms/Card'
import Container from '../../../../components/atoms/Container'
import Button from '../../../../components/molecules/Button'
import { StyledDescription } from '../../../../components/styled/Description'
import { StyledEmoji } from '../../../../components/styled/Emoji'
import { addClient, addClientDocument } from '../../../../services/clients'
import { transformClientInput } from '../../../../utils/clients'
import { useForm } from '../../../../providers/Form'
import { transformBankCreditInput } from '../../../../utils/credits'
import { ClientResponse } from '../../../../types/clients'
import {
  BankCredit,
  BankCreditPayload,
  BankCreditResponse,
} from '../../../../types/credits'
import { addCredit, addCreditDocument } from '../../../../services/credits'
import { FileWithBuffer } from '../../../../components/molecules/FileDropper'
import { Mappeable } from '../../../../types/objects'
import { getSignedUrls, putWithSignedUrl } from '../../../../services/s3'
import Config from '../../../../config/environment'
import { useAlert } from '../../../../providers/Alert'
import { getNextOperator } from '../../../../services/operators'
import { CreditPhases } from '../../../../types/enums'

const { INTERNAL_URL } = Config

const Completed: React.FC = () => {
  const { setAlert } = useAlert()
  const { newClientForm, setNewClientForm } = useForm()
  const [client, setClient] = useState<ClientResponse>()
  const [credits, setCredits] = useState<BankCreditResponse[]>()
  const [step, setStep] = useState(1)

  useEffect(() => {
    const phaseSubmissions = async () => {
      if (step === 1) {
        const clientPayload = transformClientInput(
          newClientForm?.personalInformation
        )
        addClient(clientPayload)
          .then((clientResponse) => {
            setClient(clientResponse)
            if (clientResponse._id) {
              setStep(2)
            }
          })
          .catch((err) => {
            if (err.message.includes('duplicate')) {
              setAlert!({
                severity: 'error',
                title: 'Error',
                description:
                  'Cliente ya esta dado de alta, porfavor revisa con gerencía.',
              })
            }
          })
      } else if (step === 2 && newClientForm) {
        const clientDocuments: Mappeable<FileWithBuffer> = {}
        newClientForm.personalInformation.files.forEach(
          (file: FileWithBuffer) => {
            clientDocuments['identifications'] = file
          }
        )
        if (newClientForm.personalInformation.secondParticipantMeta) {
          newClientForm.personalInformation.secondParticipantMeta.files.forEach(
            (file: FileWithBuffer) => {
              clientDocuments['secondParticipantIdentifications'] = file
            }
          )
        }
        if (newClientForm.analisisDocuments) {
          newClientForm.analisisDocuments.addressProofFile.forEach(
            (file: FileWithBuffer) => {
              clientDocuments['addressProof'] = file
            }
          )
        }
        if (newClientForm.analisisDocuments) {
          newClientForm.analisisDocuments.maritalFile.forEach(
            (file: FileWithBuffer) => {
              clientDocuments['marital'] = file
            }
          )
        }

        const clientDocumentUrls = {
          documents: {
            path: `client/${client!.code.toString()}`,
            keys: Object.keys(clientDocuments),
          },
        }
        const urls = await getSignedUrls(clientDocumentUrls)
        const submitDocuments = Object.keys(clientDocuments).map(
          (documentKey) => {
            const document = clientDocuments[documentKey]
            const signedUrl = urls.documents[documentKey]
            return putWithSignedUrl(signedUrl.url, document.file).then(() => {
              return addClientDocument(client!.code!, {
                url: `${INTERNAL_URL}/${signedUrl.path}`,
                name: documentKey,
                isActive: true,
              })
            })
          }
        )
        await Promise.all(submitDocuments)
        setStep(3)
      } else if (step === 3 && client?._id) {
        const bankCreditsArray = Object.values(
          newClientForm?.bankCredits
        ) as BankCredit[]
        const phase1Operator = await getNextOperator(CreditPhases.Phase1)
        const bankCreditsPayloads = bankCreditsArray.map(
          (bankCredit: BankCredit) => {
            return transformBankCreditInput(
              client?._id,
              bankCredit,
              phase1Operator.user
            )
          }
        )

        const bankCreditsPayloadsResponses = await Promise.all(
          bankCreditsPayloads
        )

        const bankPromises = bankCreditsPayloadsResponses.map(
          (bankCreditPayload: BankCreditPayload) => {
            bankCreditPayload.comments =
              newClientForm!.analisisDocuments.comments
            return addCredit(bankCreditPayload)
          }
        )

        await Promise.all<BankCreditResponse>(bankPromises).then(
          (creditsResponse) => {
            setCredits(creditsResponse)
            setStep(4)
          }
        )
      } else if (step === 4 && newClientForm && credits) {
        const submitCreditDocuments = credits.map(
          (credit: BankCreditResponse) => {
            const bankCreditDocuments = newClientForm.bankCredits[credit.bank]
            const bankDocuments: Mappeable<FileWithBuffer> = {}

            if (bankCreditDocuments.anexosFiles) {
              bankCreditDocuments.anexosFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['anexo'] = file
                }
              )
            }

            if (bankCreditDocuments.incomeProofFiles) {
              bankCreditDocuments.incomeProofFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['incomeProof'] = file
                }
              )
            }

            if (bankCreditDocuments.paymentStubsFiles) {
              bankCreditDocuments.paymentStubsFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['paymentStubs'] = file
                }
              )
            }

            if (bankCreditDocuments.altaHaciendaFiles) {
              bankCreditDocuments.altaHaciendaFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['altaHacienda'] = file
                }
              )
            }

            if (bankCreditDocuments.solicitudesFiles) {
              bankCreditDocuments.solicitudesFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['solicitudes'] = file
                }
              )
            }

            if (bankCreditDocuments.solicitudesEditableFiles) {
              bankCreditDocuments.solicitudesEditableFiles.forEach(
                (file: FileWithBuffer) => {
                  bankDocuments['solicitudesEditable'] = file
                }
              )
            }

            if (bankCreditDocuments.otherFiles) {
              bankCreditDocuments.otherFiles.forEach((file: FileWithBuffer) => {
                bankDocuments['otherInitial'] = file
              })
            }

            const bankDocumentUrls = {
              documents: {
                path: `credit/${credit!.code.toString()}`,
                keys: Object.keys(bankDocuments),
              },
            }
            return getSignedUrls(bankDocumentUrls).then(async (urls) => {
              const submitDocuments = Object.keys(bankDocuments).map(
                (documentKey) => {
                  const document = bankDocuments[documentKey]
                  const signedUrl = urls.documents[documentKey]
                  return putWithSignedUrl(signedUrl.url, document.file).then(
                    () => {
                      return addCreditDocument(credit!.code, {
                        url: `${INTERNAL_URL}/${signedUrl.path}`,
                        name: documentKey,
                        isActive: true,
                      })
                    }
                  )
                }
              )
              return submitDocuments
            })
          }
        )

        const creditDocumentsSubmitted = await Promise.all(
          submitCreditDocuments
        )

        if (creditDocumentsSubmitted) {
          setStep(5)
          setNewClientForm!({})
        }
      }
    }
    phaseSubmissions()
  }, [step, newClientForm])

  const phases = [
    {
      emoji: '⌛',
      feedback: 'Creando Cliente...',
    },
    {
      emoji: '📄',
      feedback: 'Subiendo Documentos de Cliente...',
    },
    {
      emoji: '🏦',
      feedback: 'Creando Solicitudes a Banco...',
    },
    {
      emoji: '📄',
      feedback: 'Subiendo Documentos de Banco...',
    },
    {
      emoji: '🎉',
      feedback: 'Listo!',
    },
  ]

  const stepInfo = phases[step - 1]
  const completed = step === phases.length
  const progress = (100 / phases.length) * step
  const colorProgress = progress < 100 ? 'error' : 'success'

  return (
    <Card>
      <Container align="center">
        <StyledEmoji>{stepInfo.emoji}</StyledEmoji>
        <LinearProgress
          variant="determinate"
          color={colorProgress}
          value={progress}
          style={{ width: '100%' }}
        />
        <StyledDescription>{stepInfo.feedback}</StyledDescription>
        {completed && (
          <Link to={`/cliente/${client!.code}`}>
            <Button>Ver Cliente</Button>
          </Link>
        )}
      </Container>
    </Card>
  )
}

export default Completed
