import React from 'react'
import { Icons } from '../../../types/enums'
import Avatar from '../../atoms/Avatar'
import Icon from '../../atoms/Icon'
import {
  StyledComment,
  StyledCommentTitle,
  StyledCommentDate,
  StyledCommentData,
  StyledCommentImage,
  StyledAttachment,
  StyledCommentUser,
} from './styled'

type CommentProps = {
  title: string
  img: string
  user: string
  date: Date
  files?: {
    name: string
    url: string
  }[]
}

const Comment: React.FC<CommentProps> = (props) => {
  return (
    <StyledComment>
      <StyledCommentImage>
        <Avatar src={props.img} />
      </StyledCommentImage>
      <StyledCommentData>
        <StyledCommentUser>{props.user}</StyledCommentUser>
        <StyledCommentTitle>{props.title}</StyledCommentTitle>
        <StyledCommentDate>
          {new Date(props.date).toLocaleString('es-MX', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
        </StyledCommentDate>
        {props.files && (
          <div>
            {props.files.map((file) => {
              return (
                <a href={file.url} key={file.name} target="_blank">
                  <StyledAttachment>
                    <Icon name={Icons.FILE_ATTACHMENT} />
                    <span>{file.name}</span>
                  </StyledAttachment>
                </a>
              )
            })}
          </div>
        )}
      </StyledCommentData>
    </StyledComment>
  )
}

export default Comment
