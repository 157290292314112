import Config from '../config/environment'
import { SavingFunds } from '../types/savingFunds'

const { REST_API_URL } = Config

export const getSavingfunds = async function () {
  const savingFunds = await fetch(`${REST_API_URL}/saving-funds`, {
    method: 'GET',
    credentials: 'include',
  })
  const savingFundsData = await savingFunds.json()
  if ('error' in savingFunds) {
    throw new Error(savingFundsData.error.message)
  }
  return savingFundsData as SavingFunds[]
}
