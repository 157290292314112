import styled from 'styled-components'

export const StyledUserData = styled.div`
  position: relative;
  width: 100%;
  max-width: var(--wd-card);
`

export const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
`
