import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditDocument,
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import Spinner from '../../../../../components/atoms/Spinner'
import FileDropper, {
  FileWithBuffer,
} from '../../../../../components/molecules/FileDropper'
import { uploadDocuments } from '../../../../../utils/documents'
import { useAlert } from '../../../../../providers/Alert'

const Phase2Analysis: React.FC = () => {
  const commentRef = useRef<HTMLTextAreaElement>(null)

  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const [loading, setLoading] = useState<boolean>(false)
  const { getTranslation } = useTranslation()
  const [appraisalResultFile, setAppraisalResultFile] = useState<
    FileWithBuffer[]
  >([])
  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.phase2Operator?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const appraisalResultBuffer = appraisalResultFile
        ? appraisalResultFile.map((fileWithBuffer) => {
            fileWithBuffer.fixedName = 'appraisalResult'
            return fileWithBuffer.file
          })
        : []

      const handleBackInconsistencyAppraisal = async () => {
        setLoading(true)

        const hasComment = !!commentRef.current?.value
        if (!hasComment) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase1,
            step: CreditSteps.Phase1AppraisalDocuments,
          }).then(async () => {
            const documents: FileWithBuffer[] = []
            if (appraisalResultBuffer.length > 0) {
              documents.push(...appraisalResultFile)
            }
            const pushedCreditDocuments = await uploadDocuments(
              documents,
              `credit/${credit!.code.toString()}`
            )

            pushedCreditDocuments.forEach((document) => {
              addCreditDocument(credit.code, document!)
            })

            const comment = `Regresando a Etapa 2 - Asignacion Avalúo :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              comment: comment,
              documents: pushedCreditDocuments,
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  owner: credit.owners.phase2Operator._id,
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  owner: credit.owners.advisor._id,
                  phase: CreditPhases.Phase1,
                  step: CreditSteps.Phase1AppraisalDocuments,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      const handleCloseAppraisal = async () => {
        setLoading(true)

        const hasComment = !!commentRef.current?.value
        const hasFile = appraisalResultFile.length > 0
        if (!hasComment || !hasFile) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            phase: CreditPhases.Phase2,
            step: CreditSteps.Phase2AppraisalClosed,
          }).then(async () => {
            const documents: FileWithBuffer[] = []
            if (appraisalResultBuffer.length > 0) {
              documents.push(...appraisalResultFile)
            }
            const pushedCreditDocuments = await uploadDocuments(
              documents,
              `credit/${credit!.code.toString()}`
            )

            pushedCreditDocuments.forEach((document) => {
              addCreditDocument(credit.code, document!)
            })

            const comment = `Avanzando a Etapa 2 - Avalúo Cerrado :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              comment: comment,
              documents: pushedCreditDocuments,
              type: 'status',
              start: new Date(),
              change: {
                from: {
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  phase: CreditPhases.Phase2,
                  step: CreditSteps.Phase2AppraisalClosed,
                },
              },
            }
            await addCreditHistory(credit.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      content = (
        <Fragment>
          <StyledDescription>
            El tramite se ha mandado al banco para Avalúo, una vez que se tenga
            el avalúo cerrado, favor de cargarlo aqui.
          </StyledDescription>
          <FileDropper
            label="Resultado de Avalúo Cerrado"
            onChange={setAppraisalResultFile}
            files={appraisalResultBuffer}
          />
          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <>
              <Button
                onClick={handleBackInconsistencyAppraisal}
                priority="secondary"
              >
                Regresar a Asignacion Avalúo
              </Button>
              <Button onClick={handleCloseAppraisal} color={color}>
                Mandar Resultado Avalúo
              </Button>
            </>
          )}
        </Container>
      )
    } else {
      content = <CreditWaitAction owner={credit.owners.phase2Operator} />
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase2Analysis
