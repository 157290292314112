import Heading from '../../components/atoms/Heading'
import CelulasPerformanceTable from '../../templates/CelulasPerformanceTable'
import Layout from '../../templates/Layout'

const CelulasPage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        Celulas Asesoras
      </Heading>
      <CelulasPerformanceTable />
    </Layout>
  )
}

export default CelulasPage
