import React from 'react'
import Card from '../../components/atoms/Card'
import DescriptionList from '../../components/molecules/DataList'
import { StyledContent, StyledCelulaTypesData } from './styled'
import { ReactBaseElement } from '../../types/react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import { StyledDescription } from '../../components/styled/Description'
import { StyledColumn, StyledColumns } from '../../components/styled/Columns'
import { useQuery } from 'react-query'
import { getCelulasTypes } from '../../services/celulas-types'
import { CelulaType } from '../../types/celula'
import Spinner from '../../components/atoms/Spinner'
import { formatCurrency } from '../../utils/currency'

type CelulaTypesDataProps = {
  headline: string
  description?: string
} & ReactBaseElement

const CelulaTypesData: React.FC<CelulaTypesDataProps> = (props) => {
  const { data: celulasTypes } = useQuery<CelulaType[]>(
    ['getCelulaTypes'],
    () => getCelulasTypes(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const Header = (
    <Container align="left" padding>
      <Heading type="3">{props.headline}</Heading>
      {props.description && (
        <StyledDescription>{props.description}</StyledDescription>
      )}
    </Container>
  )

  if (!celulasTypes || celulasTypes.length === 0) {
    return <Spinner type="fade" />
  }

  return (
    <StyledCelulaTypesData>
      <Card header={Header}>
        <StyledContent>
          <StyledColumns>
            {celulasTypes.map((celulaType) => {
              const celulaData = celulaType.categories.map(
                (celulaTypeCategory) => {
                  const friendlyDate = new Date(
                    celulaTypeCategory.effectiveFrom
                  ).toLocaleString('es-MX', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })

                  const fromFormatted = formatCurrency(celulaTypeCategory.from)
                  const toFormatted = formatCurrency(celulaTypeCategory.to)
                  const percentage =
                    (celulaTypeCategory.commission * 100).toFixed(4) + '%'
                  return {
                    label: (
                      <div>
                        <div>{celulaTypeCategory.name}</div>
                        <StyledDescription>
                          {`Efectivo ${friendlyDate}`}
                        </StyledDescription>
                        <StyledDescription>
                          {`${fromFormatted} - ${toFormatted}`}
                        </StyledDescription>
                      </div>
                    ),
                    value: <b>{percentage}</b>,
                  }
                }
              )
              return (
                <StyledColumn>
                  <Heading type="4">{celulaType.name}</Heading>
                  <DescriptionList list={celulaData} />
                </StyledColumn>
              )
            })}
          </StyledColumns>
        </StyledContent>
      </Card>
    </StyledCelulaTypesData>
  )
}

export default CelulaTypesData
