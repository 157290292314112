import styled from 'styled-components'
import { StyledIcon } from '../../atoms/Icon/styled'

export const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spc-sm);
  background-color: var(--color-gray-1);
  color: var(--color-white);
  padding: var(--spc-sm);

  @media screen and (min-width: 48rem) {
    width: 15rem;
  }
`

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-white) !important;
  width: 100%;
  gap: 0.5rem;

  &:hover {
    ${StyledIcon} {
      color: var(--color-red-1);
    }
  }

  ${StyledIcon} {
    flex: 1;
    font-size: 1.5rem;
  }
`

export const StyledLabel = styled.span`
  flex: 5;
  font-size: var(--fs-md);

  @media screen and (max-width: 48rem) {
    display: none;
  }
`

export const StyledItems = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  a {
    text-decoration: none !important;
  }
`

export const StyledBottomSection = styled.div`
  position: relative;
  width: 100%;
`
