import styled from 'styled-components'

export const StyledAllocationsTable = styled.div`
  position: relative;
  width: 100%;
`

export const StyledFilters = styled.div`
  margin-top: 1rem;
`
