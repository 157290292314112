const DownloadCircle: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M7 14a7 7 0 10-7-7 7 7 0 007 7zM4.22 7.22a.75.75 0 011.06 0l1 1V4a.75.75 0 011.5 0v4.19l1-1a.75.75 0 011 1.09l-2.25 2.25a.75.75 0 01-1.06 0L4.22 8.28a.75.75 0 010-1.06z"></path>
    </svg>
  )
}

export default DownloadCircle
