import React, { useMemo } from 'react'
import { StyledCelulasPerformanceTable } from './styled'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { useQuery } from 'react-query'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import { getCelulasPerformance } from '../../services/celulas'
import { CelulaPerformanceListItem } from '../../types/celula'
import { parseCelulaPerformance } from '../../utils/celulas'
import Heading from '../../components/atoms/Heading'

const CelulasPerformanceTable: React.FC = () => {
  const { user } = useUser()
  const currentYear = new Date().getFullYear().toString()
  const { data: performance, isFetching } = useQuery<
    CelulaPerformanceListItem[]
  >(['getCelulasPerformance'], () => getCelulasPerformance(currentYear), {
    refetchOnWindowFocus: false,
  })

  const parsedCelulasPerformance = useMemo(() => {
    if (performance && performance.length) {
      return parseCelulaPerformance(performance, currentYear)
    }
    return []
  }, [performance])

  const celulasColumns = getRoleResourceColumns(
    'celulasPerformance',
    user?.role
  )
  const celulasPerformanceData = getTableData(
    celulasColumns,
    parsedCelulasPerformance
  )

  const sortedCelulasPerf = useMemo(() => {
    return celulasPerformanceData.sort((a, b) => {
      return a.celula > b.celula ? 1 : -1
    })
  }, [celulasPerformanceData])

  if (isFetching || !performance) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando metricas de celulas.</StyledDescription>
      </Container>
    )
  }

  return (
    <StyledCelulasPerformanceTable>
      <Heading type="2" border margin>
        Rendimiento {new Date().getFullYear()}
      </Heading>
      <Table
        columns={celulasColumns}
        data={sortedCelulasPerf}
        hideSelect
        onClick={console.log}
        fileName="celulaPerformance"
      />
    </StyledCelulasPerformanceTable>
  )
}

export default CelulasPerformanceTable
