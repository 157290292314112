const PersonQuestion: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M9.15 4.75v-.19A.28.28 0 009 4.31a2.24 2.24 0 01-1.35-2.06 3.74 3.74 0 01.4-1.67.28.28 0 000-.2.28.28 0 00-.14-.15A3.5 3.5 0 106.65 7a3.48 3.48 0 002.61-1.17.25.25 0 00.05-.26 2.22 2.22 0 01-.16-.82zM6.28 9.25a.24.24 0 000-.23.22.22 0 00-.28-.09 6.64 6.64 0 00-5.64 4.4.47.47 0 00.06.46.49.49 0 00.43.21h4.4a.22.22 0 00.21-.12.23.23 0 000-.24l-.27-.55a.23.23 0 010-.18zM12.93 13.33a6.64 6.64 0 00-5.64-4.4.25.25 0 00-.22.07.24.24 0 000 .23l1.1 3.66a.28.28 0 010 .18l-.28.55a.26.26 0 000 .24.22.22 0 00.21.12h4.4a.51.51 0 00.41-.21.5.5 0 00.02-.44zM12.15 4.75a.57.57 0 01.31-.51 2.25 2.25 0 10-3.31-2 .75.75 0 001.5 0 .75.75 0 011.5 0 .75.75 0 01-.39.66 2.1 2.1 0 00-1.11 1.84.75.75 0 001.5 0z"></path>
      <circle cx="11.4" cy="7.5" r="1"></circle>
    </svg>
  )
}

export default PersonQuestion
