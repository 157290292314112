import React from 'react'
import { StyledListContainer, StyledList, StyledListItem } from './styled'

type ListProps = {
  items: {
    id: string
    content: React.ReactNode
  }[]
}

const List: React.FC<ListProps> = (props) => {
  return (
    <StyledListContainer>
      <StyledList>
        {props.items.map((item) => (
          <StyledListItem key={item.id}>{item.content}</StyledListItem>
        ))}
      </StyledList>
    </StyledListContainer>
  )
}

export default List
