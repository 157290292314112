const Percentage: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <circle cx="2" cy="2" r="2"></circle>
      <circle cx="12" cy="12" r="2"></circle>
      <rect
        x="-1.65"
        y="5.43"
        width="17.3"
        height="3.15"
        rx="0.56"
        transform="rotate(-45 7 7)"
      ></rect>
    </svg>
  )
}

export default Percentage
