import styled from 'styled-components'

export const StyledDescriptionList = styled.div`
  display: flex;
  flex: 1;
`

export const StyledDl = styled.dl`
  position: relative;
  width: 100%;
`

export const StyledDc = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: var(--spc-sm);
  border-bottom: 1px solid silver;
  margin-bottom: 0.2rem;
`

export const StyledDt = styled.dt`
  flex: 2;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: var(--fs-md);
  margin-bottom: 0.3rem;
`
export const StyledDd = styled.dd`
  font-weight: lighter;
  flex: 2;
  font-size: var(--fs-md);
  white-space: pre-wrap;
`
