import React, { Fragment, useRef, useState } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import Button from '../../../../../components/molecules/Button'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'
import { useUser } from '../../../../../providers/User'
import CreditWaitAction from '../../../shared/CreditWaitAction'
import Textarea from '../../../../../components/atoms/Textarea'
import Spinner from '../../../../../components/atoms/Spinner'
import { CreditPhases, CreditSteps, Role } from '../../../../../types/enums'
import Select from '../../../../../components/atoms/Select'
import { useAlert } from '../../../../../providers/Alert'

const Phase1MissingOriginales: React.FC = () => {
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const authorizedOriginalesRef = useRef<HTMLInputElement>(null)

  const { code } = useParams()
  const { user } = useUser()
  const { setAlert } = useAlert()
  const { getTranslation } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)

  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  const yesNoItems = [
    {
      value: 'Si',
      label: 'Si',
    },
  ]

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const isActiveOwner = user!.code === credit.owners.phase1Operator?.code
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    if (isActiveOwner || user?.role !== Role.Advisor) {
      const handleSendToAppraisalDocuments = async () => {
        setLoading(true)

        const originalesOptions = JSON.parse(
          authorizedOriginalesRef.current?.value || '{}'
        )

        const hasComment = !!commentRef.current?.value
        const hasOriginales = !!originalesOptions.value
        if (!hasComment || !hasOriginales) {
          setAlert!({
            severity: 'error',
            title: 'Error',
            description: 'Hacen falta campos requeridos',
          })
          setLoading(false)
        } else {
          await updateCredit(credit!.code, {
            step: CreditSteps.Phase1AppraisalDocuments,
            meta: {
              authorizedOriginales: originalesOptions.value,
            },
          }).then(async () => {
            const comment = `Avanzando a Etapa 1 - Carga Documentos Avalúo :: ${commentRef.current?.value}`
            const creditHistoryComment = {
              comment: comment,
              type: 'status',
              start: new Date(),
              meta: {
                authorizedOriginales: originalesOptions.value,
              },
              change: {
                from: {
                  phase: credit.phase,
                  step: credit.step,
                },
                to: {
                  phase: CreditPhases.Phase1,
                  step: CreditSteps.Phase1AppraisalDocuments,
                },
              },
            }

            await addCreditHistory(credit!.code, creditHistoryComment).then(
              () => {
                if (commentRef.current) {
                  commentRef.current.value = ''
                }
                refetch().then(() => {
                  setLoading(false)
                })
              }
            )
          })
        }
      }

      content = (
        <Fragment>
          <StyledDescription>
            Es necesario confirmar que ya se mandaron los originales para poder
            continuar con el tramite.
          </StyledDescription>
          <Select
            label="Se entregaron Originales?"
            required
            items={yesNoItems}
            ref={authorizedOriginalesRef}
          />
          <Textarea label="Comentarios" ref={commentRef} required />
        </Fragment>
      )

      footer = (
        <Container align="right" direction="row" padding>
          {loading ? (
            <Spinner type="bar" />
          ) : (
            <Fragment>
              <Button
                color={color}
                priority="secondary"
                onClick={handleSendToAppraisalDocuments}
              >
                Mandar a Carga de Documentos Avalúo
              </Button>
            </Fragment>
          )}
        </Container>
      )
    } else {
      content = (
        <CreditWaitAction
          owner={credit.owners.phase1Operator}
          text="El crédito esta autorizado, tienes pendiente entregar originales para avanzar a Etapa 2 (Solicitud de Avalúo)"
        />
      )
    }
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase1MissingOriginales
