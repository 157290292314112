const List: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <circle cx="1.5" cy="2" r="1.5"></circle>
      <path d="M13 3H5.5a1 1 0 010-2H13a1 1 0 010 2z"></path>
      <circle cx="1.5" cy="7" r="1.5"></circle>
      <path d="M13 8H5.5a1 1 0 010-2H13a1 1 0 010 2z"></path>
      <circle cx="1.5" cy="12" r="1.5"></circle>
      <path d="M13 13H5.5a1 1 0 010-2H13a1 1 0 010 2z"></path>
    </svg>
  )
}

export default List
