import styled from 'styled-components'

export const StyledCreditProgress = styled.div`
  position: relative;
  width: 100%;
`

export const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div {
    flex: 1;
  }
`
