import styled from 'styled-components'

export const StyledFollowUpTable = styled.div`
  position: relative;
  width: 100%;
`

export const StyledActionsContaner = styled.div`
  display: flex;
  gap: 1rem;
`
