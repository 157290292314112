import Heading from '../../components/atoms/Heading'
import Layout from '../../templates/Layout'
import OperatorsWorkload from '../../templates/OperatorsWorkload'

const OperatorsPage = () => {
  return (
    <Layout>
      <Heading type="1" border margin>
        Operadoras
      </Heading>
      <OperatorsWorkload />
    </Layout>
  )
}

export default OperatorsPage
