import Config from '../config/environment'

const { REST_API_URL } = Config

export const getAllocations = async function (userFilter: {
  from?: string
  to?: string
}) {
  const filter = {
    isCollocated: 'true',
    isActive: 'true',
    ...(userFilter.from ? { afrom: userFilter.from } : {}),
    ...(userFilter.to ? { ato: userFilter.to } : {}),
  }

  const queryParams = new URLSearchParams(filter).toString()
  const creditsReponse = await fetch(
    `${REST_API_URL}/credits/?${queryParams}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await creditsReponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const getMonthAllocations = async function () {
  const filter = {
    isCompleted: 'true',
    currentMonth: 'true',
  }

  const queryParams = new URLSearchParams(filter).toString()
  const creditsReponse = await fetch(
    `${REST_API_URL}/credits/?${queryParams}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await creditsReponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}

export const getAuthExpiredCredits = async function () {
  const filter = {
    authToExpire: 'true',
  }

  const queryParams = new URLSearchParams(filter).toString()
  const creditsReponse = await fetch(
    `${REST_API_URL}/credits/?${queryParams}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const data = await creditsReponse.json()
  if ('error' in data) {
    throw new Error(data.error.message)
  }
  return data
}
