import styled from 'styled-components'

export const StyledCommissionsTable = styled.div`
  position: relative;
  width: 100%;
`

export const StyledCommissionsFooter = styled.div`
  position: relative;
  margin-top: 1rem;
`

export const StyledTotalForCommission = styled.div`
  font-weight: bold;
  font-size: var(--fs-lg);
  color: var(--color-red-2);
  margin-bottom: 1rem;
  text-decoration: underline;
  text-transform: uppercase;
`

export const StyledTable = styled.div`
  margin-bottom: 2rem;
`

export const StyledFilters = styled.div`
  margin-top: 1rem;
`

export const StyledActionsContainer = styled.div`
  display: flex;
  gap: 1rem;
`
