import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledAllocationsTable, StyledFilters } from './styled'
import { ReactBaseElement } from '../../types/react'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import { AllocationRow } from '../../types/allocations'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { getAllocations } from '../../services/allocation'
import { useMutation } from 'react-query'
import { BankCreditListItem } from '../../types/credits'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import Input from '../../components/atoms/Input'
import { Icons } from '../../types/enums'
import Heading from '../../components/atoms/Heading'
import { TableSelectedData } from '../../types/tables'
import { formatCurrency } from '../../utils/currency'
import {
  getConditionalStyles,
  parseAllocationForTable,
} from '../../utils/allocations'
import { Mappeable } from '../../types/objects'
import Select from '../../components/atoms/Select'
import { SelectOption } from '../../types/html'
import DateInput from '../../components/atoms/DateInput'
import Button from '../../components/molecules/Button'

type AllocationsTableProps = ReactBaseElement

const AllocationsTable: React.FC<AllocationsTableProps> = () => {
  const { user } = useUser()
  const [search, setSearch] = useState('')
  const fromDateRef = useRef<HTMLInputElement>(null)
  const toDateRef = useRef<HTMLInputElement>(null)
  const [localFilters, setLocalFilters] = useState<Mappeable<SelectOption>>({})
  const [stateCredits, setStateCredits] = useState<
    BankCreditListItem[] | undefined
  >(undefined)
  const [selectedCredits, setSelectedCredits] = useState<TableSelectedData>()
  const navigator = useNavigate()

  const {
    data: credits,
    isLoading,
    mutate: fetchCredits,
  } = useMutation(getAllocations, {
    onSuccess: (data) => {
      setStateCredits(data)
    },
  })

  const filters = useMemo(() => {
    const advisors: Mappeable<string> = {}
    const banks: Mappeable<string> = {}
    const contactForms: Mappeable<string> = {}
    credits?.forEach((credit: BankCreditListItem) => {
      advisors[credit.owners.advisor.code.toString()] =
        credit.owners.advisor.displayName
      banks[credit.bank._id] = credit.bank.name
      contactForms[credit.client.meta.contactForm._id] =
        credit.client.meta.contactForm.name
    })

    return {
      advisors: Object.keys(advisors).map((advisorKey) => ({
        value: advisorKey,
        label: advisors[advisorKey],
      })),
      banks: Object.keys(banks).map((bankKey) => ({
        value: bankKey,
        label: banks[bankKey],
      })),
      contactForms: Object.keys(contactForms).map((contactFormKey) => ({
        value: contactFormKey,
        label: contactForms[contactFormKey],
      })),
    }
  }, [credits])

  useEffect(() => {
    if (credits) {
      const filteredCredits = credits!.filter((credit: BankCreditListItem) => {
        const advisorFilter = localFilters.advisor?.value
        if (!advisorFilter || advisorFilter === '--') {
          return true
        }
        return credit.owners.advisor.code.toString() === advisorFilter
      })

      const searchCredits = filteredCredits!.filter(
        (credit: BankCreditListItem) => {
          return (
            credit.code.toString().includes(search) ||
            credit.client.name.toLowerCase().includes(search) ||
            credit.owners.advisor.displayName.toLowerCase().includes(search) ||
            credit.bank.name.toLowerCase().includes(search) ||
            credit.client.meta.contactForm.name.toLowerCase().includes(search)
          )
        }
      )

      setStateCredits(searchCredits)
    }
  }, [localFilters, search, credits])

  const parsedCredits = useMemo(() => {
    if (stateCredits && stateCredits.length) {
      return parseAllocationForTable(stateCredits)
    }
    return []
  }, [stateCredits])

  const totalSelected = useMemo(() => {
    let sum = 0
    if (selectedCredits && selectedCredits?.selectedCount > 0) {
      sum = selectedCredits?.selectedRows.reduce((total, current) => {
        const realCredit = credits?.find(
          (c: BankCreditListItem) => c.code.toString() === current.id
        )
        if (realCredit) {
          let creditAmount
          if (realCredit.meta.finalCreditAmount) {
            creditAmount = parseFloat(realCredit.meta.finalCreditAmount)
          } else if (realCredit.meta.formalizacionMontoCredito) {
            creditAmount = realCredit.meta.formalizacionMontoCredito
          } else if (realCredit.meta.authorizedCredit) {
            creditAmount = realCredit.meta.authorizedCredit
          } else {
            creditAmount = realCredit.meta.creditRequested
          }

          return total + creditAmount
        }
        return total
      }, 0)
    }
    return sum
  }, [selectedCredits])

  const AllocationsColumns = getRoleResourceColumns('allocation', user?.role)
  const AllocationsData = getTableData(AllocationsColumns, parsedCredits)

  const handleFetchCredits = () => {
    const fromDate = fromDateRef.current?.value
    const toDate = toDateRef.current?.value
    fetchCredits({
      from: fromDate,
      to: toDate,
    })
  }

  const handleClick = (credit: AllocationRow) => {
    navigator(`/credito/${credit.id}`)
  }

  const handleSelect = (selected: TableSelectedData) => {
    setSelectedCredits(selected)
  }

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const searchValue = event.currentTarget.value.toLowerCase()
    setSearch(searchValue)
  }

  const handleLocalFilter = (filter: string) => (option: SelectOption) => {
    setLocalFilters({
      ...localFilters,
      [filter]: option,
    })
  }

  const conditional = getConditionalStyles()

  return (
    <StyledAllocationsTable>
      <StyledFilters>
        <StyledDescription>Selecciona Fechas:</StyledDescription>
        <Container direction="row">
          <DateInput label="Desde" ref={fromDateRef} />
          <DateInput label="Hasta" ref={toDateRef} />
          <Button onClick={handleFetchCredits}>Buscar</Button>
        </Container>
      </StyledFilters>
      {AllocationsData.length > 0 && (
        <div>
          <StyledFilters>
            <StyledDescription>Selecciona filtros:</StyledDescription>
            <Container direction="row">
              <Select
                label="Asesora"
                items={filters.advisors}
                onChange={handleLocalFilter('advisor')}
                value={localFilters.advisor}
              />
              <Input
                placeholder="Buscar"
                onChange={handleSearch}
                preIcon={Icons.SEARCH}
                value={search}
              ></Input>
            </Container>
          </StyledFilters>
          <Container>
            <Heading type="4" color="var(--color-gray-2)">
              {`Total Seleccionado: ${formatCurrency(totalSelected)}`}
            </Heading>
          </Container>
          <Table
            columns={AllocationsColumns}
            data={AllocationsData}
            onClick={handleClick}
            onSelect={handleSelect}
            conditionalRowStyles={conditional}
            fileName="colocaciones"
          />
        </div>
      )}

      {isLoading && (
        <Container align="center">
          <Spinner type="dot" />
          <StyledDescription>Cargando lista de creditos.</StyledDescription>
        </Container>
      )}
    </StyledAllocationsTable>
  )
}

export default AllocationsTable
