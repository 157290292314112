import React from 'react'
import { Icons } from '../../../types/enums'
import { ReactBaseElement } from '../../../types/react'
import {
  StyledTile,
  StyledTileIcon,
  StyledTileTitle,
  StyledTileSubtitle,
} from './styled'

export type TileStyles = {
  color?: string
  borderColor?: string
}

export type TileProps = {
  icon: Icons
  title: string
  subtitle: string
  onClick?: React.MouseEventHandler
} & ReactBaseElement &
  TileStyles

const Tile: React.FC<TileProps> = (props) => {
  return (
    <StyledTile color={props.color} borderColor={props.borderColor}>
      <StyledTileIcon name={props.icon} />
      <StyledTileTitle>{props.title}</StyledTileTitle>
      <StyledTileSubtitle color={props.borderColor}>
        {props.subtitle}
      </StyledTileSubtitle>
    </StyledTile>
  )
}

export default Tile
