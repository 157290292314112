import React from 'react'
import AnimationStyles from '../design/foundations/animations'
import BaselineStyles from '../design/foundations/baseline'
import ColorsStyles from '../design/foundations/colors'
import SpacingStyles from '../design/foundations/spacing'
import TypographiesStyles from '../design/foundations/typographies'

export const DesignContext = React.createContext({})

type DesignProviderProps = {
  children: React.ReactNode
}

const DesignProvider: React.FC<DesignProviderProps> = ({ children }) => {
  const contextValue = {
    theme: 'default',
  }

  return (
    <DesignContext.Provider value={contextValue}>
      <AnimationStyles />
      <BaselineStyles />
      <ColorsStyles />
      <SpacingStyles />
      <TypographiesStyles />
      {children}
    </DesignContext.Provider>
  )
}

export default DesignProvider
