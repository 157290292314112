import styled from 'styled-components'
import { HeadingStyles } from './index'

export const StyledHeading = styled.div<HeadingStyles>`
  display: block;
  color: ${(props) => props.color};
  font-size: ${(props) => `var(--heading-fs-h${props.type})`};
  text-align: ${(props) => props.align};

  ${(props) =>
    props.margin
      ? `
    margin-top: var(--spc-sm);
    margin-bottom: var(--spc-xs);
  `
      : ''}

  ${(props) =>
    props?.border
      ? `
    border-bottom: 3px solid var(--color-gray-3);
    padding-bottom var(--spc-xs);
    width: 100%;
    `
      : ''}

  @media screen and (max-width: 48rem) {
    font-size: ${(props) => `var(--heading-fs-h${props.type + 2})`};
  }
`
