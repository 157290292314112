import React from 'react'
import DataTable from 'react-data-table-component'
import { Mappeable } from '../../../types/objects'
import { downloadCSV } from '../../../utils/csv'
import Button from '../../molecules/Button'
import NoData from './NoData'
import { Icons } from '../../../types/enums'
import {
  StyledTableContainer,
  StyledTableFooter,
  StyledTableHeader,
} from './styled'
import Container from '../../atoms/Container'

type TableProps = {
  columns: Mappeable<any>[]
  data: Mappeable<any>[]
  hideSelect?: boolean
  title?: React.ReactNode
  subheaderComponent?: React.ReactNode
  expandableComponent?: any
  expandable?: boolean
  conditionalRowStyles?: any[]
  fileName?: string
  onClick: (data: any) => void
  onSelect?: (data: any) => void
}

const Table: React.FC<TableProps> = (props) => {
  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const HeaderSection = <StyledTableHeader>{props.title}</StyledTableHeader>

  const Export = ({ onExport }: { onExport: () => void }) => (
    <Button preIcon={Icons.DOWNLOAD_CIRCLE} onClick={() => onExport()}>
      {' '}
    </Button>
  )

  const columnsToExport = props.columns.reduce((columns, currentColumn) => {
    const tempColums = columns
    if (!currentColumn.omitCSV && !currentColumn.cell) {
      tempColums[currentColumn.key] = currentColumn.name
    }
    return tempColums
  }, {})

  const dataToExport = props.data.map((data) => {
    const newObject: Mappeable<any> = {}
    Object.keys(data).forEach((dataKey) => {
      if (columnsToExport[dataKey]) {
        let value = data[dataKey]
        value = value.replace(/[,|$]/g, '')
        newObject[dataKey] = value
      }
    })
    return newObject
  })

  return (
    <StyledTableContainer>
      <DataTable
        title={HeaderSection}
        // Options
        subHeader={props.subheaderComponent}
        subHeaderComponent={props.subheaderComponent}
        noHeader={!props.title}
        // Data
        columns={props.columns}
        data={props.data}
        fixedHeader
        fixedHeaderScrollHeight="1000px"
        // Pagination
        pagination={props.data.length > 25}
        paginationComponentOptions={paginationComponentOptions}
        paginationPerPage={25}
        // Select
        selectableRows={!props.hideSelect}
        selectableRowsHighlight
        onSelectedRowsChange={props.onSelect}
        // Table State
        progressPending={false}
        highlightOnHover
        pointerOnHover
        onRowDoubleClicked={props.onClick}
        // Conditionals
        conditionalRowStyles={props.conditionalRowStyles}
        noDataComponent={<NoData />}
        // Expandable
        expandableRows={props.expandable}
        expandableRowsComponent={props.expandableComponent}
      />
      <StyledTableFooter>
        <Container align="right">
          <Export
            onExport={() =>
              downloadCSV(columnsToExport, dataToExport, props.fileName)
            }
          />
        </Container>
      </StyledTableFooter>
    </StyledTableContainer>
  )
}

export default Table
