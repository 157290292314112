import styled from 'styled-components'
import Container from '../../components/atoms/Container'
import { StyledLogo } from '../../components/styled/Logo'

export const StyledBackgroundLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  font-size: var(--fs-xl);
`

export const LogoContainer = styled(Container)`
  margin-top: 5rem;

  ${StyledLogo} {
    width: 150px;
    height: 150px;
  }
`
