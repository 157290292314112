import { useMutation, useQuery } from 'react-query'
import {
  BarChart,
  Bar,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
} from 'recharts'
import { Toggle } from 'react-toggle-component'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import { StyledDescription } from '../../components/styled/Description'
import { StyledGhostWrapper } from '../../components/styled/GhostWrapper'
import Spinner from '../../components/atoms/Spinner'
import { getOperatorsWorkload, updateOperator } from '../../services/operators'
import { CreditPhases } from '../../types/enums'
import { Mappeable } from '../../types/objects'
import { OperatorWorkload } from '../../types/operators'
import {
  StyledOperatorItem,
  StyledOperatorsList,
  StyledOperatorsWorkload,
} from './styled'
import { StyledColumn, StyledColumns } from '../../components/styled/Columns'

const TOTAL_KEY = 'Otros'
const ASSIGNATION_KEY = 'En Asignacion'

type PhaseWorkload = {
  id: string
  user: string
  name: string
  phase: string
  isActive: boolean
  [TOTAL_KEY]: number
  [ASSIGNATION_KEY]: number
}

const OperatorsWorkload = () => {
  const {
    data: workload,
    isFetching,
    refetch: refetchOperatorsWorkload,
  } = useQuery<OperatorWorkload[]>(
    ['getOperatorsWorkload'],
    () => getOperatorsWorkload(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { mutate: updateOperatorHandler } = useMutation(updateOperator, {
    onSuccess: () => {
      refetchOperatorsWorkload()
    },
  })

  const loading = isFetching || !workload

  if (loading) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>
          Cargando información de carga de trabajo operadoras.
        </StyledDescription>
      </Container>
    )
  }

  const workloadPerPhase = workload?.reduce<
    Mappeable<Mappeable<PhaseWorkload>>
  >((phasesWorkload, currentOperator) => {
    const workloadItem = {
      id: currentOperator._id,
      user: currentOperator.user._id,
      phase: currentOperator.phase,
      name: currentOperator.user.displayName,
      isActive: currentOperator.isActive,
      [TOTAL_KEY]:
        currentOperator.totalCredits - currentOperator.totalInAssignation,
      [ASSIGNATION_KEY]: currentOperator.totalInAssignation,
    }

    let currentPhase = currentOperator.phase
    if (currentPhase === CreditPhases.Prequalification) {
      currentPhase = CreditPhases.Phase1
    }
    const user = currentOperator.user._id

    if (phasesWorkload[currentPhase]) {
      const userData = phasesWorkload[currentPhase][user]
      if (userData) {
        phasesWorkload[currentPhase][user] = {
          [TOTAL_KEY]: userData[TOTAL_KEY] + workloadItem[TOTAL_KEY],
          [ASSIGNATION_KEY]:
            userData[ASSIGNATION_KEY] + workloadItem[ASSIGNATION_KEY],
          name: workloadItem.name,
          user: workloadItem.user,
          id: workloadItem.id,
          phase: workloadItem.phase,
          isActive: workloadItem.isActive,
        }
      } else {
        phasesWorkload[currentPhase][user] = workloadItem
      }
    } else {
      phasesWorkload[currentPhase] = {
        [user]: workloadItem,
      }
    }

    return phasesWorkload
  }, {})

  const dataPhase1 = Object.values(workloadPerPhase[CreditPhases.Phase1])
  const renderLineChartPhase1 = (
    <BarChart width={300} height={150} data={dataPhase1}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tickSize={12} tick={{ fontSize: '12px' }} />
      <YAxis
        interval={1}
        padding={{ top: 10 }}
        width={20}
        tick={{ fontSize: '12px' }}
      />
      <Legend wrapperStyle={{ fontSize: '12px' }} />
      <Tooltip wrapperStyle={{ fontSize: '12px' }} />
      <Bar
        stackId="phase1"
        dataKey={ASSIGNATION_KEY}
        fill="var(--color-yellow-1)"
      />
      <Bar stackId="phase1" dataKey={TOTAL_KEY} fill="var(--color-yellow-5)" />
    </BarChart>
  )

  const dataPhase2 = Object.values(workloadPerPhase[CreditPhases.Phase2])
  const renderLineChartPhase2 = (
    <BarChart width={300} height={150} data={dataPhase2}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tickSize={12} tick={{ fontSize: '12px' }} />
      <YAxis
        interval={1}
        padding={{ top: 10 }}
        width={20}
        tick={{ fontSize: '12px' }}
      />
      <Legend wrapperStyle={{ fontSize: '12px' }} />
      <Tooltip wrapperStyle={{ fontSize: '12px' }} />
      <Bar
        stackId="phase2"
        dataKey={ASSIGNATION_KEY}
        fill="var(--color-blue-1)"
      />
      <Bar stackId="phase2" dataKey={TOTAL_KEY} fill="var(--color-blue-5)" />
    </BarChart>
  )

  const dataPhase3 = Object.values(workloadPerPhase[CreditPhases.Phase3])
  const renderLineChartPhase3 = (
    <BarChart width={300} height={150} data={dataPhase3}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tickSize={12} tick={{ fontSize: '12px' }} />
      <YAxis
        interval={1}
        padding={{ top: 10 }}
        width={20}
        tick={{ fontSize: '12px' }}
      />
      <Legend wrapperStyle={{ fontSize: '12px' }} />
      <Tooltip wrapperStyle={{ fontSize: '12px' }} />
      <Bar
        stackId="phase3"
        dataKey={ASSIGNATION_KEY}
        fill="var(--color-green-1)"
      />
      <Bar stackId="phase3" dataKey={TOTAL_KEY} fill="var(--color-green-5)" />
    </BarChart>
  )

  const operatorsPhase1 = dataPhase1.map((op) => {
    return {
      id: op.id,
      name: op.name,
      user: op.user,
      phase: op.phase,
      isActive: op.isActive,
      key: `${op.phase}-${op.id}`,
    }
  })

  const operatorsPhase2 = dataPhase2.map((op) => {
    return {
      id: op.id,
      name: op.name,
      user: op.user,
      phase: op.phase,
      isActive: op.isActive,
      key: `${op.phase}-${op.id}`,
    }
  })

  // const operatorsPhase3 = dataPhase3.map((op) => {
  //   return {
  //     id: op.id,
  //     name: op.name,
  //     user: op.user,
  //     phase: op.phase,
  //     isActive: op.isActive,
  //     key: `${op.phase}-${op.id}`,
  //   }
  // })

  const togglePrequalificationOperator = (
    phase1Operator: string,
    active: boolean
  ) => {
    const prequalificationOperator = workload.find(
      (wl) => wl.user._id === phase1Operator
    )
    if (prequalificationOperator) {
      updateOperatorHandler({
        operator: prequalificationOperator._id,
        isActive: active,
      })
    }
  }

  return (
    <StyledGhostWrapper ghost={loading}>
      <StyledOperatorsWorkload>
        <StyledColumns>
          <StyledColumn>
            <Heading type="4" color="var(--color-yellow-2)">
              Precalificación & Etapa 1
            </Heading>
            {renderLineChartPhase1}
            <StyledOperatorsList>
              <StyledDescription>Listado de Operadoras:</StyledDescription>
              {operatorsPhase1.map((op) => (
                <StyledOperatorItem key={op.key}>
                  <Toggle
                    name={op.key}
                    checked={op.isActive}
                    onToggle={() => {
                      togglePrequalificationOperator(op.user, !op.isActive)
                      updateOperatorHandler({
                        operator: op.id,
                        isActive: !op.isActive,
                      })
                    }}
                    rightKnobColor="var(--color-yellow-1)"
                    rightBorderColor="var(--color-yellow-2)"
                    leftBorderColor="var(--color-gray-4)"
                    leftKnobColor="var(--color-gray-5)"
                  />
                  <span>{op.name}</span>
                </StyledOperatorItem>
              ))}
            </StyledOperatorsList>
          </StyledColumn>
          <StyledColumn>
            <Heading type="4" color="var(--color-blue-2)">
              Etapa 2
            </Heading>
            {renderLineChartPhase2}
            <StyledOperatorsList>
              <StyledDescription>Listado de Operadoras:</StyledDescription>
              {operatorsPhase2.map((op) => (
                <StyledOperatorItem key={op.key}>
                  <Toggle
                    name={op.key}
                    checked={op.isActive}
                    onToggle={() =>
                      updateOperatorHandler({
                        operator: op.id,
                        isActive: !op.isActive,
                      })
                    }
                    rightKnobColor="var(--color-blue-1)"
                    rightBorderColor="var(--color-blue-2)"
                    leftBorderColor="var(--color-gray-4)"
                    leftKnobColor="var(--color-gray-5)"
                  />
                  <span>{op.name}</span>
                </StyledOperatorItem>
              ))}
            </StyledOperatorsList>
          </StyledColumn>
          <StyledColumn>
            <Heading type="4" color="var(--color-green-2)">
              Etapa 3
            </Heading>
            {renderLineChartPhase3}
            <StyledOperatorsList>
              <StyledDescription>Listado de Operadoras:</StyledDescription>
              {operatorsPhase2.map((op) => (
                <StyledOperatorItem key={op.key}>
                  {/* <Toggle
                    name={op.key}
                    checked={op.isActive}
                    onToggle={() =>
                      updateOperatorHandler({
                        operator: op.id,
                        isActive: !op.isActive,
                      })
                    }
                    rightKnobColor="var(--color-green-1)"
                    rightBorderColor="var(--color-green-2)"
                    leftBorderColor="var(--color-gray-4)"
                    leftKnobColor="var(--color-gray-5)"
                  /> */}
                  <span>{op.name}</span>
                </StyledOperatorItem>
              ))}
            </StyledOperatorsList>
          </StyledColumn>
        </StyledColumns>
      </StyledOperatorsWorkload>
    </StyledGhostWrapper>
  )
}

export default OperatorsWorkload
