import React, { Fragment } from 'react'
import Card from '../../../../../components/atoms/Card'
import Container from '../../../../../components/atoms/Container'
import Heading from '../../../../../components/atoms/Heading'
import { useTranslation } from '../../../../../providers/I18n'
import { BankCreditDetail } from '../../../../../types/credits'
import { StyledDescription } from '../../../../../components/styled/Description'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getCredit } from '../../../../../services/credits'
import { getCreditColor } from '../../../../../utils/credits'

const Phase3Completed: React.FC = () => {
  const { code } = useParams()
  const { getTranslation } = useTranslation()

  const { data: credit, isLoading } = useQuery<BankCreditDetail>(
    ['getCredit', code],
    () => getCredit(code!),
    {
      refetchOnWindowFocus: false,
    }
  )

  let header
  let footer
  let content
  if (!credit && isLoading) {
    content = (
      <Container>
        <StyledDescription>Cargando credito...</StyledDescription>
      </Container>
    )
  } else if (credit) {
    const color = getCreditColor(credit.phase)
    const creditPhaseStep = `${getTranslation!(
      `phases.${credit.phase}.title`
    )} - ${getTranslation!(`phases.${credit.phase}.steps.${credit.step}`)}`

    header = (
      <Container padding>
        <Heading type="3" color={color}>
          {creditPhaseStep}
        </Heading>
      </Container>
    )

    content = (
      <Fragment>
        <StyledDescription>
          Tramite Completado Exitosamente, enviado para cobro
        </StyledDescription>
      </Fragment>
    )

    footer = (
      <Container align="right" direction="row" padding>
        <span>🎉</span>
      </Container>
    )
  }

  return (
    <div>
      <Card header={header} footer={footer}>
        {content}
      </Card>
    </div>
  )
}

export default Phase3Completed
