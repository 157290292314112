import { createContext, useContext, useState } from 'react'
import { Mappeable } from '../types/objects'

type FormContextValue = {
  newClientForm: Mappeable<any>
  setNewClientForm: (state: React.SetStateAction<Mappeable<any>>) => void
  newCreditForm: Mappeable<any>
  setNewCreditForm: (state: React.SetStateAction<Mappeable<any>>) => void
}

export const FormContext = createContext<Partial<FormContextValue>>({})

type ProviderProps = {
  children: React.ReactNode
}

const FormProvider: React.FC<ProviderProps> = ({ children }) => {
  const [newClientForm, setNewClientForm] = useState({})
  const [newCreditForm, setNewCreditForm] = useState({})

  const formValue: FormContextValue = {
    newClientForm,
    newCreditForm,
    setNewClientForm,
    setNewCreditForm,
  }

  return (
    <FormContext.Provider value={formValue}>{children}</FormContext.Provider>
  )
}

export const useForm = () => {
  return useContext(FormContext)
}

export default FormProvider
