import styled from 'styled-components'

export const StyledFileDropperContainer = styled.div`
  position: relative;
`

export const StyledDropZone = styled.div<{
  isFocused?: boolean
  hasFiles?: boolean
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border-color: var(--color-gray-2);
  padding: var(--spc-xs) var(--spc-sm);
  border-width: 2px;
  border-radius: 5px;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 0.5rem;

  ${(props) => (props.hasFiles ? 'border-color: var(--color-green-1);' : '')}

  &:hover {
    font-weight: bold;
    svg {
      color: var(--color-gray-2);
    }
    border-color: var(--color-gray-2);
    cursor: pointer;
  }
`

export const StyledFilesDropped = styled.div`
  font-size: var(--fs-xs);
  padding-left: 1rem;
`

export const StyledFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
