import React from 'react'
import ClientInformation from '../../templates/ClientInformation'
import Layout from '../../templates/Layout'

const ClientDetailPage = () => {
  return (
    <Layout>
      <ClientInformation />
    </Layout>
  )
}

export default ClientDetailPage
