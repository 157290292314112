const File: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M11.25 14h-8.5A1.76 1.76 0 011 12.25V1.75A1.76 1.76 0 012.75 0h4.5a.75.75 0 01.53.22l5 5a.75.75 0 01.22.53v6.5A1.76 1.76 0 0111.25 14zM2.75 1.5a.25.25 0 00-.25.25v10.5a.25.25 0 00.25.25h8.5a.25.25 0 00.25-.25V6.16a.26.26 0 00-.07-.16L7 1.57a.26.26 0 00-.17-.07z"></path>
      <path d="M6.5 4.88H4.75a.63.63 0 010-1.26H6.5a.63.63 0 010 1.26zM9.25 7.88h-4.5a.63.63 0 010-1.26h4.5a.63.63 0 010 1.26zM9.25 10.88h-4.5a.63.63 0 010-1.26h4.5a.63.63 0 010 1.26z"></path>
    </svg>
  )
}

export default File
