const PersonLock: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <circle cx="4.75" cy="2.75" r="2.75"></circle>
      <path d="M5.5 12.5V10a3 3 0 01.69-1.91.27.27 0 00.06-.16.24.24 0 00-.18-.24 4.44 4.44 0 00-1.32-.19A4.75 4.75 0 000 12.25V13a1 1 0 001 1h4.5a.26.26 0 00.21-.11.26.26 0 000-.24 2.91 2.91 0 01-.21-1.15zM7 12.5A1.5 1.5 0 008.5 14h4a1.5 1.5 0 001.5-1.5V10a1.49 1.49 0 00-.64-1.23.25.25 0 01-.11-.21V7.5a2.75 2.75 0 00-5.5 0v1.06a.25.25 0 01-.11.21A1.49 1.49 0 007 10zm2.25-5a1.25 1.25 0 012.5 0v.75a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z"></path>
    </svg>
  )
}

export default PersonLock
