export const BasicClientColumns = [
  {
    name: 'Private ID',
    key: 'id',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'ID',
    key: 'visualId',
    width: '6rem',
  },
  {
    name: 'Asesora',
    key: 'advisor',
    width: '8rem',
  },
  {
    name: 'Cliente',
    key: 'name',
    width: '12rem',
  },
  {
    name: 'Forma de Contacto',
    key: 'contactForm',
    width: '8rem',
  },
  {
    name: 'CURP',
    key: 'curp',
    width: '12rem',
  },
  {
    name: 'Contacto',
    key: 'contact',
    width: '18rem',
  },
  {
    name: 'Desde',
    key: 'createdAt',
    width: '10rem',
  },
]
