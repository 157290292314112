import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ModalProvider } from 'react-modal-hook'
import { TransitionGroup } from 'react-transition-group'
import AuthProvider from './providers/Auth'
import CatalogsProvider from './providers/Catalogs'
import FormProvider from './providers/Form'
import DesignProvider from './providers/Design'
import I18nProvider from './providers/I18n'
import UserProvider from './providers/User'
import Router from './Router'
import AlertProvider from './providers/Alert'

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <DesignProvider>
          <UserProvider>
            <AuthProvider>
              <I18nProvider>
                <ModalProvider rootComponent={TransitionGroup}>
                  <CatalogsProvider>
                    <FormProvider>
                      <AlertProvider>
                        <Router />
                      </AlertProvider>
                    </FormProvider>
                  </CatalogsProvider>
                </ModalProvider>
              </I18nProvider>
            </AuthProvider>
          </UserProvider>
        </DesignProvider>
      </QueryClientProvider>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
