import styled from 'styled-components'

export const StyledCreditInformation = styled.div`
  position: relative;
  width: 100%;
`

export const StyledEditButton = styled.sup`
  font-size: 8px;
  font-weight: bold;
  cursor: pointer;
  padding-left: 10px;
`
