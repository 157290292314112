const PersonScreening: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M1 5a1 1 0 01-1-1V2a2 2 0 012-2h2a1 1 0 010 2H2v2a1 1 0 01-1 1zM13 5a1 1 0 01-1-1V2h-2a1 1 0 010-2h2a2 2 0 012 2v2a1 1 0 01-1 1zM4 14H2a2 2 0 01-2-2v-2a1 1 0 012 0v2h2a1 1 0 010 2zM12 14h-2a1 1 0 010-2h2v-2a1 1 0 012 0v2a2 2 0 01-2 2z"></path>
      <circle cx="7" cy="5" r="2"></circle>
      <path d="M10.12 10.5a.5.5 0 00.45-.28.49.49 0 00-.06-.53 4.49 4.49 0 00-7 0 .49.49 0 00-.06.53.5.5 0 00.45.28z"></path>
    </svg>
  )
}

export default PersonScreening
