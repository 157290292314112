import { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import { StyledDescription } from '../../components/styled/Description'
import { useAuth } from '../../providers/Auth'

const LogoutHandler = () => {
  const { isLoggingOut, logout } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    logout!()
    navigate('/', {
      replace: false,
    })
  }, [])
  return (
    <Fragment>
      <BounceLoader
        loading={isLoggingOut}
        color="var(--color-red-2)"
        size={50}
      />
      <StyledDescription>
        Hasta pronto! Desconectando tu sesión del CRM.
      </StyledDescription>
    </Fragment>
  )
}

export default LogoutHandler
