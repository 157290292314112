import React, { Fragment, useEffect, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { StyledCreditInformation, StyledEditButton } from './styled'
import { ReactBaseElement } from '../../types/react'
import Heading from '../../components/atoms/Heading'
import Container from '../../components/atoms/Container'
import DescriptionList from '../../components/molecules/DataList'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import {
  addCreditDocument,
  addCreditHistory,
  getCredit,
  updateCredit,
} from '../../services/credits'
import Spinner from '../../components/atoms/Spinner'
import { StyledDescription } from '../../components/styled/Description'
import { StyledColumn, StyledColumns } from '../../components/styled/Columns'
import List from '../../components/atoms/List'
import {
  CreditPhases,
  CreditSteps,
  Icons,
  Owners,
  Role,
} from '../../types/enums'
import { useTranslation } from '../../providers/I18n'
import { BankCreditDetail } from '../../types/credits'
import PrequalificationRequest from './steps/Prequalification/Request'
import { getCreditColor } from '../../utils/credits'
import { Mappeable } from '../../types/objects'
import PrequalificationDocuments from './steps/Prequalification/Documents'
import Phase1Authorized from './steps/Phase1/Authorized'
import Phase1Analysis from './steps/Phase1/Analysis'
import Phase1Assignation from './steps/Phase1/Assignation'
import History from '../../components/molecules/History'
import CreditProgress from '../CreditProgress'
import NewComment from '../NewComment'
import FileItem from '../../components/molecules/FileItem'
import { FileWithBuffer } from '../../components/molecules/FileDropper'
import { uploadDocuments } from '../../utils/documents'
import { addClientDocument } from '../../services/clients'
import Phase2Assignation from './steps/Phase2/Assignation'
import Phase1Inconsistency from './steps/Phase1/Inconsistency'
import Phase1Rejected from './steps/Phase1/Rejected'
import Phase1AppraisalDocuments from './steps/Phase1/AppraisalDocuments'
import Phase2Analysis from './steps/Phase2/Analysis'
import Phase2AppraisalClosed from './steps/Phase2/Closed'
import Phase3Formalization from './steps/Phase3/Formalization'
import Button from '../../components/molecules/Button'
import Phase1MissingOriginales from './steps/Phase1/MissingOriginales'
import Phase3ClosingValues from './steps/Phase3/ClosingValues'
import Phase3SignatureDate from './steps/Phase3/SignatureDate'
import Phase3SignatureKit from './steps/Phase3/SignatureKit'
import Phase3SignatureReady from './steps/Phase3/SignatureReady'
import Phase3Escriturado from './steps/Phase3/Escriturado'
import Phase3Fondeo from './steps/Phase3/Fondeo'
import Phase3Completed from './steps/Phase3/Completed'
import { formatCurrency } from '../../utils/currency'
import { StyledPill } from '../../components/styled/Pill'
import EditCreditOperatorModal from '../../modals/EditCreditOperator'
import { STEPS_OWNERS } from '../../utils/steps'
import CircleImage from '../../components/atoms/CircleIcon'
import BankAnalysisImage from '../../design/assets/images/bank-analysis-icon.png'
import BankAuthorizationImage from '../../design/assets/images/bank-authorization-icon.png'
import BankFilesImage from '../../design/assets/images/bank-files-icon.png'
import AppraisalImage from '../../design/assets/images/appraisal-icon.png'
import SignatureKitImage from '../../design/assets/images/signature-kit-icon.png'
import { ClientDocument } from '../../types/clients'
import { useUser } from '../../providers/User'

type CreditInformationProps = ReactBaseElement

const PhaseSteps = {
  [CreditPhases.Prequalification]: {
    [CreditSteps.PrequalificationRequest]: PrequalificationRequest,
    [CreditSteps.PrequalificationDocuments]: PrequalificationDocuments,
  },
  [CreditPhases.Phase1]: {
    [CreditSteps.Phase1Assignation]: Phase1Assignation,
    [CreditSteps.Phase1Analysis]: Phase1Analysis,
    [CreditSteps.Phase1Inconsistency]: Phase1Inconsistency,
    [CreditSteps.Phase1Rejected]: Phase1Rejected,
    [CreditSteps.Phase1Authorized]: Phase1Authorized,
    [CreditSteps.Phase1MissingOriginales]: Phase1MissingOriginales,
    [CreditSteps.Phase1AppraisalDocuments]: Phase1AppraisalDocuments,
  },
  [CreditPhases.Phase2]: {
    [CreditSteps.Phase2AppraisalRequest]: Phase2Assignation,
    [CreditSteps.Phase2AppraisalInProgress]: Phase2Analysis,
    [CreditSteps.Phase2AppraisalClosed]: Phase2AppraisalClosed,
  },
  [CreditPhases.Phase3]: {
    [CreditSteps.Phase3FormalizationRequest]: Phase3Formalization,
    [CreditSteps.Phase3ClosingValues]: Phase3ClosingValues,
    [CreditSteps.Phase3SignatureDate]: Phase3SignatureDate,
    [CreditSteps.Phase3SignatureKit]: Phase3SignatureKit,
    [CreditSteps.Phase3SignatureReady]: Phase3SignatureReady,
    [CreditSteps.Phase3Escriturado]: Phase3Escriturado,
    [CreditSteps.Phase3Fondeo]: Phase3Fondeo,
    [CreditSteps.Phase3Completed]: Phase3Completed,
  },
}

const CreditInformation: React.FC<CreditInformationProps> = () => {
  const { code } = useParams()
  const { user } = useUser()
  const { getTranslation } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [editField, setEditField] = useState('')

  const {
    data: credit,
    isLoading,
    refetch,
  } = useQuery<BankCreditDetail>(['getCredit', code], () => getCredit(code!), {
    refetchOnWindowFocus: false,
  })

  const [showEditModal, hideEditModal] = useModal(
    ({ in: open }) => (
      <EditCreditOperatorModal
        open={open}
        onSubmit={async (newOperator) => {
          if (credit) {
            const prequalificationOp = credit.owners['prequalificationOperator']
            const phase1Op = credit.owners['phase1Operator']

            const currentOperator = prequalificationOp || phase1Op

            const newOwner =
              credit.currentOwner === Owners.Phase1Operator ||
              credit.currentOwner === Owners.PrequalificationOperator
                ? newOperator.value
                : undefined

            await updateCredit(credit.code, {
              step: credit.step,
              owners: {
                ...credit.owners,
                ...(prequalificationOp
                  ? { prequalificationOperator: newOperator.value }
                  : {}),
                ...(phase1Op ? { phase1Operator: newOperator.value } : {}),
              },
            })

            if (currentOperator._id !== newOperator.value) {
              const creditHistoryComment = {
                comment: `Reasignación de credito de ${currentOperator.displayName} a ${newOperator.label}`,
                type: 'comment',
                start: new Date(),
                ...(newOwner
                  ? {
                      change: {
                        from: {
                          owner: currentOperator._id,
                        },
                        to: {
                          owner: newOperator.value,
                        },
                      },
                    }
                  : {}),
              }
              await addCreditHistory(credit.code, creditHistoryComment)
            }
            refetch()
          }
        }}
        onClose={() => {
          setEditField('')
          hideEditModal()
        }}
        field={editField}
      />
    ),
    [editField]
  )

  useEffect(() => {
    if (credit && !credit.currentOwner) {
      // const lastOwnerChange = credit.history.find((ch) => ch.change?.to?.owner)
      // const lastOwnerUser = lastOwnerChange?.change?.to?.owner
      const roleOwner = STEPS_OWNERS[credit.step]
      updateCredit(credit.code, {
        currentOwner: roleOwner,
      }).then(() => {
        refetch()
      })
    }
  }, [credit])

  if (isLoading || !credit) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando información de credito.</StyledDescription>
      </Container>
    )
  }

  const handleOpenEditModal = (field: string) => () => {
    setEditField(field)
    showEditModal()
  }

  const color = getCreditColor(credit.phase)
  const clientData = []
  const creditData = []
  if (credit) {
    const client = credit?.client
    clientData.push(
      {
        label: 'Folio Cliente',
        value: (
          <Link to={`/cliente/${client.code}`}>
            {'CL-' + client.code!.toString() || ''}
          </Link>
        ),
      },
      {
        label: 'Fecha Creación',
        value: new Date(client.createdAt!).toLocaleDateString('es-MX', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      },
      {
        label: 'Nombre',
        value: client.name || '',
      },
      {
        label: 'Forma de Contacto',
        value: client.meta.contactForm.name || '',
      },
      {
        label: 'Email',
        value: client.meta.email || '',
      },
      {
        label: 'Teléfono',
        value: client.meta.phone || '',
      },
      {
        label: 'Estado Civil',
        value: client.meta.maritalStatus || '',
      }
    )

    if (client.meta.maritalStatus === 'Casado') {
      clientData.push({
        label: 'Regimen Civil',
        value: client.meta.maritalRegimen || '',
      })
    }

    let creditText = 'Credito Solicitado'
    let creditAmount = formatCurrency(credit.meta.creditRequested)

    if (credit.meta.finalCreditAmount) {
      creditText = 'Credito Ejercido'
      creditAmount = formatCurrency(credit.meta.finalCreditAmount)
    } else if (credit.meta.formalizacionMontoCredito) {
      creditText = 'Credito Formalizado'
      creditAmount = formatCurrency(credit.meta.formalizacionMontoCredito)
    } else if (credit.meta.authorizedCredit) {
      creditText = 'Credito Autorizado'
      creditAmount = formatCurrency(credit.meta.authorizedCredit)
    }

    const creditLastOwnerKey = credit.currentOwner as Owners
    const creditLastOwner = credit.owners[creditLastOwnerKey]
    const advisor =
      creditLastOwner?._id === credit.owners.advisor?._id ? (
        <StyledPill color={color}>
          {credit.owners.advisor?.displayName}
        </StyledPill>
      ) : (
        credit.owners.advisor?.displayName
      )

    const EditPhase1Operator = [
      CreditPhases.Prequalification,
      CreditPhases.Phase1,
    ].includes(credit.phase) &&
      user!.role !== Role.Advisor &&
      user!.role !== Role.Operator && (
        <StyledEditButton onClick={handleOpenEditModal('operatorPhase1')}>
          Editar
        </StyledEditButton>
      )

    const phase1andPrequalificationOperator =
      credit.owners.phase1Operator || credit.owners.prequalificationOperator
    const operatorPhase1 =
      (credit.phase === CreditPhases.Phase1 ||
        credit.phase === CreditPhases.Prequalification) &&
      (creditLastOwner?._id === credit.owners.prequalificationOperator?._id ||
        creditLastOwner?._id === credit.owners.phase1Operator?._id) ? (
        <StyledPill color={color}>
          {phase1andPrequalificationOperator?.displayName}
          {EditPhase1Operator}
        </StyledPill>
      ) : (
        <div>
          {phase1andPrequalificationOperator?.displayName}
          {EditPhase1Operator}
        </div>
      )

    const operatorPhase2 =
      credit.phase === CreditPhases.Phase2 &&
      creditLastOwner?._id === credit.owners.phase2Operator?._id ? (
        <StyledPill color={color}>
          {credit.owners.phase2Operator?.displayName}
        </StyledPill>
      ) : (
        credit.owners.phase2Operator?.displayName
      )

    const operatorPhase3 =
      credit.phase === CreditPhases.Phase3 &&
      credit.step !== CreditSteps.Phase3Completed &&
      creditLastOwner?._id === credit.owners.phase3Operator?._id ? (
        <StyledPill color={color}>
          {credit.owners.phase3Operator?.displayName}
        </StyledPill>
      ) : (
        credit.owners.phase3Operator?.displayName
      )

    creditData.push(
      {
        label: 'Folio Credito',
        value: 'CR-' + credit.code!.toString() || '',
      },
      {
        label: 'Fecha Creación',
        value: new Date(credit.createdAt!).toLocaleDateString('es-MX', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
      },
      {
        label: 'Asesora',
        value: advisor,
      },
      {
        label: 'Operadora de Analisis',
        value: operatorPhase1,
      },
      {
        label: 'Operadora Avalúo',
        value: operatorPhase2 || '--',
      },
      {
        label: 'Operadora Formalizacion',
        value: operatorPhase3 || '--',
      },
      {
        label: creditText,
        value: creditAmount,
      }
    )
  }

  const submitCreditDocument = (files: FileWithBuffer[]) => {
    uploadDocuments(files, `credit/${credit!.code.toString()}`).then(
      (documents) => {
        const document = documents[0]
        addCreditDocument(credit.code, documents[0]).then(async () => {
          const documentName = getTranslation!(`files.${document.name}`)
          const comment = `Modificando Archivo de Credito - ${documentName}`
          const creditHistoryComment = {
            documents: [document],
            comment: comment,
            type: 'comment',
            start: new Date(),
          }
          await addCreditHistory(credit.code, creditHistoryComment).then(() => {
            refetch()
          })
        })
      }
    )
  }

  const submitClientDocument = (files: FileWithBuffer[]) => {
    uploadDocuments(files, `client/${credit!.client.code.toString()}`).then(
      (documents) => {
        const document = documents[0]
        addClientDocument(credit.client.code, documents[0]).then(async () => {
          const documentName = getTranslation!(`files.${document.name}`)
          const comment = `Modificando Archivo de Cliente - ${documentName}`
          const creditHistoryComment = {
            documents: [document],
            comment: comment,
            type: 'comment',
            start: new Date(),
          }
          await addCreditHistory(credit.code, creditHistoryComment).then(() => {
            refetch()
          })
        })
      }
    )
  }

  const handleSendTrash = async () => {
    setLoading(true)
    await updateCredit(credit!.code, {
      isActive: false,
    }).then(async () => {
      const comment = `Mandado a Congeladora`
      const creditHistoryComment = {
        comment: comment,
        type: 'status',
        start: new Date(),
      }
      await addCreditHistory(credit.code, creditHistoryComment).then(() => {
        refetch().then(() => {
          setLoading(false)
        })
      })
    })
  }

  const handleOutOfTrash = async () => {
    setLoading(true)
    await updateCredit(credit!.code, {
      isActive: true,
    }).then(async () => {
      const comment = `Sacado de Congeladora`
      const creditHistoryComment = {
        comment: comment,
        type: 'status',
        start: new Date(),
      }
      await addCreditHistory(credit.code, creditHistoryComment).then(() => {
        refetch().then(() => {
          setLoading(false)
        })
      })
    })
  }

  const uploadedCreditDocuments = credit.documents.reduce<
    Mappeable<ClientDocument>
  >((map, document) => {
    map[document.name] = document
    return map
  }, {})

  const creditDocuments = credit.documents.reduce<
    { id: string; content: JSX.Element }[]
  >((documents, document) => {
    if (document.isActive) {
      documents.push({
        id: document.name,
        content: (
          <FileItem
            id={document.name}
            url={document.url}
            uploadedAt={document.uploadedAt}
            onChange={submitCreditDocument}
          />
        ),
      })
    }

    return documents
  }, [])

  const authorizedCreditStep = credit.history.find(
    (ch) => ch.change?.from.step === CreditSteps.Phase1Authorized
  )
  if (authorizedCreditStep) {
    creditDocuments.push({
      id: 'authorization-card',
      content: (
        <FileItem
          id="authorization-card"
          url={`/autorizacion/${credit.code}?layout=false`}
          uploadedAt={authorizedCreditStep?.start}
        />
      ),
    })
  }

  const clientDocuments = credit.client.documents.reduce<
    { id: string; content: JSX.Element }[]
  >((documents, document) => {
    if (document.isActive) {
      documents.push({
        id: document.name,
        content: (
          <FileItem
            id={document.name}
            url={document.url}
            uploadedAt={document.uploadedAt}
            onChange={submitClientDocument}
          />
        ),
      })
    }
    return documents
  }, [])

  const bankAndClientName =
    `${credit.bank.name} - ${credit.client.name}`.toUpperCase()
  const currentPhaseSteps: Mappeable<any> = PhaseSteps[credit.phase]
  const CurrentStep = currentPhaseSteps[credit.step]

  let lastPhaseColor = color
  const creditHistory = credit.history.map((creditHistory) => {
    if (creditHistory.change?.from?.phase) {
      lastPhaseColor = getCreditColor(
        creditHistory.change?.from.phase as CreditPhases
      )
    }
    const files = creditHistory.documents
      ? creditHistory.documents.reduce<{ name: string; url: string }[]>(
          (documents, historyDocument) => {
            const temp = documents
            if (historyDocument) {
              let documentName = historyDocument.name
              if (!historyDocument.name.includes('.')) {
                documentName =
                  getTranslation!(`files.${historyDocument.name}`) ||
                  historyDocument.name
              }
              temp.push({
                url: historyDocument.url,
                name: documentName,
              })
            }
            return temp
          },
          []
        )
      : []

    const toPhaseColor = getCreditColor(
      creditHistory.change?.to?.phase as CreditPhases
    )

    return {
      type: creditHistory.type === 'comment' ? 'secondary' : 'primary',
      color: toPhaseColor || lastPhaseColor,
      title: creditHistory.comment,
      user: creditHistory.createdBy!.displayName,
      img: creditHistory.createdBy!.avatar,
      date: new Date(creditHistory.start),
      meta: creditHistory.meta,
      files,
    }
  })

  const accessToSendCongeladora = ![Role.Advisor, Role.Operator].includes(
    user!.role as Role
  )
  const showCongeladoraAction = !credit.isCollocated && !credit.isCompleted

  try {
    return (
      <StyledCreditInformation>
        {showCongeladoraAction && (
          <Container direction="row" align="right">
            {credit.isActive && accessToSendCongeladora && (
              <Button
                color={color}
                preIcon={Icons.TRASH}
                onClick={handleSendTrash}
                loading={loading}
              >
                Mandar a Congeladora
              </Button>
            )}
            {!credit.isActive && (
              <Button
                color={color}
                preIcon={Icons.DOWNLOAD_CIRCLE}
                onClick={handleOutOfTrash}
                loading={loading}
              >
                Sacar de Congeladora
              </Button>
            )}
          </Container>
        )}
        <Heading type="2" border margin color={color}>
          {bankAndClientName}
        </Heading>
        <Container>
          <StyledColumns>
            <StyledColumn>
              <DescriptionList list={clientData} />
            </StyledColumn>
            <StyledColumn>
              <DescriptionList list={creditData} />
            </StyledColumn>
          </StyledColumns>
        </Container>
        <Container>
          <StyledColumns>
            <StyledColumn>
              <CircleImage
                src={
                  uploadedCreditDocuments['solicitudes'] &&
                  uploadedCreditDocuments['solicitudes'].url
                }
                disabled={!uploadedCreditDocuments['solicitudes']}
                icon={BankFilesImage}
                text="Solicitud a Banco"
                color="var(--color-pink-5)"
              />
            </StyledColumn>
            <StyledColumn>
              <CircleImage
                src={
                  uploadedCreditDocuments['analysisResult'] &&
                  uploadedCreditDocuments['analysisResult'].url
                }
                disabled={!uploadedCreditDocuments['analysisResult']}
                icon={BankAnalysisImage}
                text="Resultado de Analisis"
                color="var(--color-yellow-5)"
              />
            </StyledColumn>
            <StyledColumn>
              <CircleImage
                src={
                  authorizedCreditStep
                    ? `/autorizacion/${credit.code}?layout=false`
                    : ''
                }
                disabled={!authorizedCreditStep}
                icon={BankAuthorizationImage}
                text="Carta de Autorización"
                color="var(--color-yellow-5)"
              />
            </StyledColumn>
            <StyledColumn>
              <CircleImage
                src={
                  uploadedCreditDocuments['appraisalResult'] &&
                  uploadedCreditDocuments['appraisalResult'].url
                }
                disabled={!uploadedCreditDocuments['appraisalResult']}
                icon={AppraisalImage}
                text="Resultado de Avaluo"
                color="var(--color-blue-5)"
              />
            </StyledColumn>
            <StyledColumn>
              <CircleImage
                src={
                  uploadedCreditDocuments['kitFirma'] &&
                  uploadedCreditDocuments['kitFirma'].url
                }
                disabled={!uploadedCreditDocuments['kitFirma']}
                icon={SignatureKitImage}
                text="Kit de Firma"
                color="var(--color-green-5)"
              />
            </StyledColumn>
          </StyledColumns>
        </Container>
        <CurrentStep refetch={refetch} />
        <Container>
          <StyledColumns>
            <StyledColumn>
              <CreditProgress credit={credit} />
            </StyledColumn>

            <StyledColumn>
              <NewComment credit={credit} refetch={refetch} />
            </StyledColumn>
          </StyledColumns>
        </Container>

        <Container>
          <Heading type="3" border margin color={color}>
            Bitacora
          </Heading>
          <History items={creditHistory} />
        </Container>

        <Container>
          <Heading type="3" border margin color={color}>
            Documentos de Credito
          </Heading>
          <List items={creditDocuments} />
        </Container>
        {clientDocuments.length > 0 && (
          <Fragment>
            <Heading type="3" border margin color={color}>
              Documentos de Cliente
            </Heading>
            <List items={clientDocuments} />
          </Fragment>
        )}
      </StyledCreditInformation>
    )
  } catch (e) {
    console.log(e)
    return <div>hola</div>
  }
}

export default CreditInformation
