import Heading from '../../components/atoms/Heading'
import Layout from '../../templates/Layout'
import CommissionsTable from '../../templates/CommissionsTable'
import { useUser } from '../../providers/User'
import { Role } from '../../types/enums'

const CommissionsPage = () => {
  const { user } = useUser()
  return (
    <Layout>
      {[Role.Admin, Role.Director].includes(user!.role as Role) && (
        <>
          <Heading type="1" border margin>
            Comisiones Pendientes Asesoras
          </Heading>
          <CommissionsTable type="advisor" />
          <Heading type="1" border margin>
            Comisiones Pendientes Operadoras
          </Heading>
          <CommissionsTable type="operator" />
        </>
      )}
      {[Role.Advisor, Role.Operator].includes(user!.role as Role) && (
        <>
          <Heading type="1" border margin>
            Mis Comisiones Pendientes
          </Heading>
          <CommissionsTable type="all" />
        </>
      )}
    </Layout>
  )
}

export default CommissionsPage
