import React, { Fragment, useState } from 'react'
import Step from '@mui/material/Step'
import { StyledNewClientFlow } from './styled'
import PersonalInformation from './sections/PersonalInformation'
import {
  StyledStepper,
  StyledStepButton,
} from '../../components/styled/Stepper'
import BankCredits from './sections/BankCredits'
import OtherDocuments from './sections/OtherDocuments'
import Completed from './sections/Completed'
import { useForm } from '../../providers/Form'

type StepElement = {
  text: string
  component: React.FC<any>
}

type NewClientFormProps = {
  headline?: string
}

const NewClientForm: React.FC<NewClientFormProps> = () => {
  const { newClientForm, setNewClientForm } = useForm()
  const [activeStep, setStep] = useState(0)
  const [flowCompleted, setFlowCompleted] = useState(false)
  const [completed, setCompleted] = React.useState<{
    [s: number]: boolean
  }>({})

  const steps: StepElement[] = [
    { text: 'Informacion de Cliente', component: PersonalInformation },
    { text: 'Solicitudes de Banco', component: BankCredits },
    { text: 'Otros Documentos', component: OtherDocuments },
  ]

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true

    if (
      newClientForm?.personalInformation &&
      newClientForm?.bankCredits &&
      newClientForm?.analisisDocuments
    ) {
      setFlowCompleted(true)
      setCompleted(newCompleted)
    }
  }

  const handleChangeStep = (step: number) => {
    setStep(step)
  }

  const saveData = (data: any) => {
    setNewClientForm!({
      ...(newClientForm ? newClientForm : {}),
      ...data,
    })
  }

  const handleNextStep = (data: any) => {
    saveData(data)
    if (activeStep === steps.length - 1) {
      handleComplete()
    } else if (activeStep < steps.length - 1) {
      setStep(activeStep + 1)
    }
  }

  const handlePrevStep = (data: any) => {
    saveData(data)
    if (activeStep > 0) {
      handleChangeStep(activeStep - 1)
    }
  }

  const CurrentStep = steps[activeStep].component

  return (
    <StyledNewClientFlow>
      {flowCompleted ? (
        <Completed />
      ) : (
        <Fragment>
          <StyledStepper activeStep={activeStep}>
            {steps.map((stepData: StepElement, index: number) => {
              return (
                <Step key={stepData.text} completed={completed[index]}>
                  <StyledStepButton onClick={() => handleChangeStep(index)}>
                    {stepData.text}
                  </StyledStepButton>
                </Step>
              )
            })}
          </StyledStepper>
          <CurrentStep
            continue={handleNextStep}
            back={handlePrevStep}
            save={saveData}
          />
        </Fragment>
      )}
    </StyledNewClientFlow>
  )
}

export default NewClientForm
