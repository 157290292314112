import React, { FormEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StyledTrashTable } from './styled'
import { ReactBaseElement } from '../../types/react'
import { getRoleResourceColumns, getTableData } from '../../utils/tables'
import { AllocationRow } from '../../types/allocations'
import Table from '../../components/organisms/Table'
import { useUser } from '../../providers/User'
import { useQuery } from 'react-query'
import { BankCreditListItem } from '../../types/credits'
import Spinner from '../../components/atoms/Spinner'
import Container from '../../components/atoms/Container'
import { StyledDescription } from '../../components/styled/Description'
import Input from '../../components/atoms/Input'
import { Icons } from '../../types/enums'
import Heading from '../../components/atoms/Heading'
import { TableSelectedData } from '../../types/tables'
import { formatCurrency } from '../../utils/currency'
import { parseTrashTable, getConditionalStyles } from '../../utils/trash'
import { useTranslation } from '../../providers/I18n'
import { getTrash } from '../../services/trash'

type TrashTableProps = ReactBaseElement

const TrashTable: React.FC<TrashTableProps> = () => {
  const { user } = useUser()
  const { languages } = useTranslation()
  const [stateCredits, setStateCredits] = useState<
    BankCreditListItem[] | undefined
  >(undefined)
  const [selectedCredits, setSelectedCredits] = useState<TableSelectedData>()
  const navigator = useNavigate()

  const { data: credits, isFetching } = useQuery<BankCreditListItem[]>(
    ['getTrashCredits'],
    () => getTrash(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setStateCredits(data)
      },
    }
  )

  const parsedCredits = useMemo(() => {
    if (stateCredits && stateCredits.length) {
      return parseTrashTable(stateCredits, languages!)
    }
    return []
  }, [stateCredits])

  const totalSelected = useMemo(() => {
    let sum = 0
    if (selectedCredits && selectedCredits?.selectedCount > 0) {
      sum = selectedCredits?.selectedRows.reduce((total, current) => {
        const realCredit = credits?.find(
          (c) => c.code.toString() === current.id
        )
        if (realCredit) {
          let creditAmount
          if (realCredit.meta.finalCreditAmount) {
            creditAmount = parseFloat(realCredit.meta.finalCreditAmount)
          } else if (realCredit.meta.formalizacionMontoCredito) {
            creditAmount = realCredit.meta.formalizacionMontoCredito
          } else if (realCredit.meta.authorizedCredit) {
            creditAmount = realCredit.meta.authorizedCredit
          } else {
            creditAmount = realCredit.meta.creditRequested
          }
          return total + creditAmount
        }
        return total
      }, 0)
    }
    return sum
  }, [selectedCredits])

  if (isFetching || !stateCredits) {
    return (
      <Container align="center">
        <Spinner type="dot" />
        <StyledDescription>Cargando lista de creditos.</StyledDescription>
      </Container>
    )
  }

  const AllocationsColumns = getRoleResourceColumns('followUp', user?.role)
  const AllocationsData = getTableData(AllocationsColumns, parsedCredits)

  const handleClick = (credit: AllocationRow) => {
    navigator(`/credito/${credit.id}`)
  }

  const handleSelect = (selected: TableSelectedData) => {
    setSelectedCredits(selected)
  }

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const search = event.currentTarget.value.toLowerCase()
    if (!search) {
      setStateCredits(credits)
    } else {
      const newListCredits = stateCredits.filter((credit) => {
        return (
          credit.code.toString().includes(search) ||
          credit.client.name.toLowerCase().includes(search) ||
          credit.owners.advisor.displayName.toLowerCase().includes(search)
        )
      })
      setStateCredits(newListCredits)
    }
  }

  const conditional = getConditionalStyles()

  return (
    <StyledTrashTable>
      <Container>
        <Heading type="4" color="var(--color-gray-2)">
          {`Total Seleccionado: ${formatCurrency(totalSelected)}`}
        </Heading>
        <Input
          placeholder="Buscar"
          onChange={handleSearch}
          preIcon={Icons.SEARCH}
        ></Input>
      </Container>
      <Table
        columns={AllocationsColumns}
        data={AllocationsData}
        onClick={handleClick}
        onSelect={handleSelect}
        conditionalRowStyles={conditional}
        fileName="congeladora"
      />
    </StyledTrashTable>
  )
}

export default TrashTable
