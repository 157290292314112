import React from 'react'
import { Icons } from '../../../types/enums'
import { StyledAvatar, StyledDefaultAvatar } from './styled'

export type AvatarProps = {
  src: string
  title?: string
}

const Avatar: React.FC<AvatarProps> = (props) => {
  if (props.src) {
    return <StyledAvatar src={props.src} title={props.title} />
  }
  return <StyledDefaultAvatar name={Icons.DEFAULT_AVATAR} title={props.title} />
}

export default Avatar
