export const BasicCelulasPerformance = [
  {
    name: 'Celula',
    key: 'celula',
    width: '8rem',
    styles: {
      backgroundColor: 'var(--color-red-5)',
    },
  },
  {
    name: 'Tipo',
    key: 'type',
    width: '8rem',
    styles: {
      backgroundColor: 'var(--color-red-5)',
      borderRight: '1px solid gray',
    },
  },
  {
    name: 'Enero',
    key: 'period01',
    width: '6rem',
  },
  {
    name: 'Febrero',
    key: 'period02',
    width: '6rem',
  },
  {
    name: 'Marzo',
    key: 'period03',
    width: '6rem',
  },
  {
    name: 'Total 1er Cuarto',
    key: 'quarter1',
    width: '7rem',
    styles: {
      backgroundColor: 'var(--color-red-5)',
      borderRight: '1px solid var(--color-red-3)',
      borderLeft: '1px solid var(--color-red-3) !important',
      fontWeight: 'bold',
    },
  },
  {
    name: 'Abril',
    key: 'period04',
    width: '6rem',
  },
  {
    name: 'Mayo',
    key: 'period05',
    width: '6rem',
  },
  {
    name: 'Junio',
    key: 'period06',
    width: '6rem',
  },
  {
    name: 'Total 2do Cuarto',
    key: 'quarter2',
    width: '7rem',
    styles: {
      backgroundColor: 'var(--color-red-5)',
      borderRight: '1px solid var(--color-red-3)',
      borderLeft: '1px solid var(--color-red-3) !important',
      fontWeight: 'bold',
    },
  },
  {
    name: 'Julio',
    key: 'period07',
    width: '6rem',
  },
  {
    name: 'Agosto',
    key: 'period08',
    width: '6rem',
  },
  {
    name: 'Septiembre',
    key: 'period09',
    width: '6rem',
  },
  {
    name: 'Total 3er Cuarto',
    key: 'quarter3',
    width: '7rem',
    styles: {
      backgroundColor: 'var(--color-red-5)',
      borderRight: '1px solid var(--color-red-3)',
      borderLeft: '1px solid var(--color-red-3) !important',
      fontWeight: 'bold',
    },
  },
  {
    name: 'Octubre',
    key: 'period10',
    width: '6rem',
  },
  {
    name: 'Noviembre',
    key: 'period11',
    width: '6rem',
  },
  {
    name: 'Diciembre',
    key: 'period12',
    width: '6rem',
  },
  {
    name: 'Total 4to Cuarto',
    key: 'quarter4',
    width: '7rem',
    styles: {
      backgroundColor: 'var(--color-red-5)',
      borderRight: '1px solid var(--color-red-3)',
      borderLeft: '1px solid var(--color-red-3) !important',
      fontWeight: 'bold',
    },
  },
]
