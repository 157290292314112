const Books: React.FC = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height="1em"
      width="1em"
      className=""
    >
      <path d="M13.5 11.56L9.91 2.23a1 1 0 00-1.29-.57l-2.8 1.07a.26.26 0 01-.24 0 .26.26 0 01-.11-.21V1a1 1 0 00-1-1h-3a1 1 0 00-1 1v12a1 1 0 001 1h3a1 1 0 001-1V5.85a.27.27 0 01.21-.25.25.25 0 01.27.16l2.93 7.6a1 1 0 00.93.64.92.92 0 00.36-.07l2.8-1.07a1 1 0 00.53-1.3zM2.93 11.5a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zm7.7-.44a1.25 1.25 0 11.72-1.61 1.24 1.24 0 01-.72 1.61z"></path>
    </svg>
  )
}

export default Books
