const SECOND = 1000,
  MINUTE = 60 * SECOND,
  HOUR = 60 * MINUTE,
  DAY = 24 * HOUR,
  WEEK = 7 * DAY,
  YEAR = DAY * 365,
  MONTH = YEAR / 12

const formats: [number, string, number?][] = [
  [0.7 * MINUTE, 'justo ahora'],
  [1.5 * MINUTE, 'hace un minuto'],
  [60 * MINUTE, 'minutos atras', MINUTE],
  [1.5 * HOUR, 'hace una hora'],
  [DAY, 'horas atras', HOUR],
  [2 * DAY, 'ayer'],
  [7 * DAY, 'días atras', DAY],
  [1.5 * WEEK, 'la semana pasada'],
  [MONTH, 'semanas atras', WEEK],
  [1.5 * MONTH, 'el mes pasado'],
  [YEAR, 'meses atras', MONTH],
  [1.5 * YEAR, 'el año pasado'],
  [Number.MAX_VALUE, 'años atras', YEAR],
]

export function relativeDate(input: Date) {
  const reference = new Date().getTime()
  const delta = reference - input.getTime()

  let format = formats[0]
  for (let i = -1, len = formats.length; ++i < len; ) {
    format = formats[i] as [number, string, number?]
    if (delta < format[0]) {
      return format[2] === undefined
        ? format[1]
        : Math.round(delta / format[2]) + ' ' + format[1]
    }
  }
}

export function getDateFromCurp(curp: string) {
  const match = curp.match(/^\w{4}(\w{2})(\w{2})(\w{2})/)
  if (match) {
    let year = parseInt(match[1], 10) + 1900
    if (year < 1950) year += 100
    const month = match[2].padStart(2, '0')
    const day = match[3].padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  return ''
}
