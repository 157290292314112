import styled from 'styled-components'
import { AvatarProps } from './index'
import Icon from '../Icon'

export const StyledAvatar = styled.div<AvatarProps>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 1px solid var(--color-gray-5);
  background-color: white;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
`

export const StyledDefaultAvatar = styled(Icon)`
  cursor: pointer;
  font-size: 2.5rem;
  color: var(--color-gray-2);
  background-color: var(--color-white);
  border-radius: 50%;
  z-index: 10;
`
