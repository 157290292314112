import React, { Fragment, useRef, useState } from 'react'
import Card from '../../components/atoms/Card'
import { StyledNewComment } from './styled'
import { ReactBaseElement } from '../../types/react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import Button from '../../components/molecules/Button'
import Comment from '../../components/molecules/Comment'
import { BankCreditDetail } from '../../types/credits'
import { StyledDescription } from '../../components/styled/Description'
import Textarea from '../../components/atoms/Textarea'
import FileDropper, {
  FileWithBuffer,
} from '../../components/molecules/FileDropper'
import { addCreditHistory } from '../../services/credits'
import { uploadDocuments } from '../../utils/documents'
import { useTranslation } from '../../providers/I18n'
import { ClientDocument } from '../../types/clients'
import { getCreditColor } from '../../utils/credits'
import { useAlert } from '../../providers/Alert'

type NewCommentProps = {
  credit: BankCreditDetail
  refetch: () => Promise<any>
} & ReactBaseElement

const NewComment: React.FC<NewCommentProps> = (props) => {
  const { getTranslation } = useTranslation()
  const { setAlert } = useAlert()
  const [commentFiles, setCommentFiles] = useState<FileWithBuffer[]>([])
  const [addingComment, setAddingComment] = useState<boolean>(false)
  const commentsRef = useRef<HTMLTextAreaElement>(null)

  const lastComment = props.credit.history[0]
  const color = getCreditColor(props.credit.phase)

  const lastCommentFiles = lastComment?.documents?.reduce<ClientDocument[]>(
    (documents, historyDocument) => {
      const temp = documents
      if (historyDocument) {
        let documentName = historyDocument.name
        if (!historyDocument.name.includes('.')) {
          documentName =
            getTranslation!(`files.${historyDocument.name}`) ||
            historyDocument.name
        }
        temp.push({
          url: historyDocument.url,
          name: documentName,
          isActive: true,
        })
      }
      return temp
    },
    []
  )

  const handleAddComment = async () => {
    setAddingComment(true)
    const pushedDocuments = await uploadDocuments(
      commentFiles,
      `credit/${props.credit!.code.toString()}`
    )

    const comment = `Comentario: ${commentsRef.current?.value}`
    const creditHistoryComment = {
      documents: pushedDocuments,
      comment: comment,
      type: 'comment',
      start: new Date(),
    }

    const hasComment = !!commentsRef?.current?.value
    if (!hasComment) {
      setAlert!({
        severity: 'error',
        title: 'Error',
        description: 'No se puede enviar un comentario vacio.',
      })
      setAddingComment(false)
    } else {
      addCreditHistory(props.credit.code, creditHistoryComment).then(() => {
        setCommentFiles([])
        if (commentsRef.current) {
          commentsRef.current.value = ''
        }

        props.refetch().then(() => {
          setAddingComment(false)
        })
      })
    }
  }

  const localCommentFiles = commentFiles
    ? commentFiles.map((fileWithBuffer) => fileWithBuffer.file)
    : []

  const Header = (
    <Container align="left" padding>
      <Heading type="3" color={color}>
        💬 Ultimo Comentario
      </Heading>
    </Container>
  )

  const Footer = (
    <Container align="right" padding>
      <Button color={color} onClick={handleAddComment} loading={addingComment}>
        Comentar
      </Button>
    </Container>
  )

  return (
    <StyledNewComment>
      <Card header={Header} footer={Footer}>
        {lastComment! && (
          <Fragment>
            <Comment
              date={lastComment.start}
              user={lastComment.createdBy!.displayName}
              title={lastComment.comment}
              img={lastComment.createdBy!.avatar}
              files={lastCommentFiles}
            />
          </Fragment>
        )}
        <StyledDescription>Respuesta:</StyledDescription>
        <Textarea required ref={commentsRef} />
        <FileDropper
          label="Adjuntar Archivo"
          onChange={setCommentFiles}
          files={localCommentFiles}
          multiple
        />
      </Card>
    </StyledNewComment>
  )
}

export default NewComment
