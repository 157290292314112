import styled from 'styled-components'
import { PatternFormat } from 'react-number-format'

export const StyledInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spc-sm);
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
`

export const StyledTextContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: var(--spc-xs);
`

export const StyledMask = styled(PatternFormat)`
  padding: var(--spc-xs) var(--spc-sm);
  font-size: var(--fs-sm);
  min-height: var(--spc-xs);
  border: 1px solid;
  border-color: var(--color-gray-5);
  border-radius: 3px;
  outline: none;
  border-bottom: 3px solid;
  border-bottom-color: var(--color-gray-5);

  &:required {
    border-left: 2px solid;
    border-left-color: var(--color-red-2);
  }

  &:required:valid {
    border-left: 2px solid;
    border-left-color: var(--color-green-2);
  }

  &:focus {
    border-bottom-color: var(--color-gray-2);
  }
`

export const StyledLabel = styled.span`
  padding: var(--spc-xs) 0;
  line-height: var(--spc-sm);
  font-size: var(--fs-sm);
  font-weight: bold;
  flex: 1;
`
