import React from 'react'
import Card from '../../components/atoms/Card'
import DescriptionList from '../../components/molecules/DataList'
import { StyledContent, StyledUserData } from './styled'
import { ReactBaseElement } from '../../types/react'
import Container from '../../components/atoms/Container'
import Heading from '../../components/atoms/Heading'
import AvatarInput from '../../components/molecules/AvatarInput'
import { useTranslation } from '../../providers/I18n'
import { StyledDescription } from '../../components/styled/Description'
import { useUser } from '../../providers/User'

type UserDataProps = {
  headline: string
  description?: string
} & ReactBaseElement

const UserData: React.FC<UserDataProps> = (props) => {
  const { user, changeAvatar, isUpdatingUser } = useUser()
  const { getTranslation } = useTranslation()

  const roleKey = `roles.${user?.role}`
  const userData = []
  if (user) {
    userData.push({
      label: 'ID',
      value: user?.code.toString(),
    })
    userData.push({
      label: 'Puesto',
      value: getTranslation!(roleKey),
    })
    userData.push({
      label: 'Fecha Entrada',
      value: new Date(user.admissionDate || '').toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    })
    userData.push({
      label: 'Usuario',
      value: user?.username,
    })
    userData.push({
      label: 'Nombre',
      value: user?.name,
    })
    userData.push({
      label: 'Correo',
      value: user?.email,
    })
    userData.push({
      label: 'Telefono',
      value: user?.phone,
    })
  }

  const Header = (
    <Container align="left" padding>
      <Heading type="3">{props.headline}</Heading>
      {props.description && (
        <StyledDescription>{props.description}</StyledDescription>
      )}
    </Container>
  )

  const handleChangeAvatar = (file: File) => {
    changeAvatar!(file)
  }

  return (
    <StyledUserData>
      <Container align="center">
        <AvatarInput
          src={user?.avatar || ''}
          loading={isUpdatingUser}
          onChange={handleChangeAvatar}
        />
      </Container>
      <Card header={Header}>
        <StyledContent>
          <DescriptionList list={userData} />
        </StyledContent>
      </Card>
    </StyledUserData>
  )
}

export default UserData
