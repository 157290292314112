import styled from 'styled-components'

export const StyledCelulaTypesData = styled.div`
  position: relative;
  width: 100%;
`

export const StyledContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
`
