import styled from 'styled-components'

export const StyledProfileWidget = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1rem;
  font-size: var(--fs-xl);
`

export const StyledName = styled.span`
  font-size: var(--fs-md);
  text-align: right;
`
