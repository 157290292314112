export const BasicCommissionsColumns = [
  {
    name: 'Private ID',
    key: 'id',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Payments',
    key: 'payments',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Credit Code',
    key: 'creditCode',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Celula',
    key: 'privateCelula',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Needs Payment',
    key: 'privateNeedsPayment',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'PrivateComisionAmount',
    key: 'commissionAmountNumber',
    width: '6rem',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'PrivateMontoNumber',
    key: 'amountNumber',
    width: '7rem',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Cliente',
    key: 'client',
    width: '12rem',
  },
  {
    name: 'Monto',
    key: 'amount',
    width: '7rem',
  },
  {
    name: 'Banco',
    key: 'bank',
    width: '6rem',
  },
  {
    name: 'Forma de Contacto',
    key: 'contactForm',
    width: '10rem',
  },
  {
    name: '%',
    key: 'commissionPercentage',
    width: '6rem',
  },
  {
    name: '$',
    key: 'commissionAmount',
    width: '6rem',
  },
  {
    name: 'F-Firma',
    key: 'signatureDate',
    width: '6rem',
  },
  {
    name: 'Tipo',
    key: 'commissionType',
    width: '6rem',
    omit: true,
  },
  {
    name: 'Ultimo Pago',
    key: 'lastPayment',
    width: '6rem',
  },
]

export const AdminCommissionsColumns = [
  {
    name: 'Private ID',
    key: 'id',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Payments',
    key: 'payments',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Credit Code',
    key: 'creditCode',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Celula',
    key: 'privateCelula',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Private Needs Payment',
    key: 'privateNeedsPayment',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'PrivateComisionAmount',
    key: 'commissionAmountNumber',
    width: '6rem',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'PrivateMontoNumber',
    key: 'amountNumber',
    width: '7rem',
    omit: true,
    omitCSV: true,
  },
  {
    name: 'Comisionado',
    key: 'user',
    width: '7rem',
  },
  {
    name: 'Cliente',
    key: 'client',
    width: '12rem',
  },
  {
    name: 'Monto',
    key: 'amount',
    width: '7rem',
  },
  {
    name: 'Banco',
    key: 'bank',
    width: '6rem',
  },
  {
    name: 'Forma de Contacto',
    key: 'contactForm',
    width: '10rem',
  },
  {
    name: '%',
    key: 'commissionPercentage',
    width: '6rem',
  },
  {
    name: '$',
    key: 'commissionAmount',
    width: '6rem',
  },
  {
    name: 'F-Firma',
    key: 'signatureDate',
    width: '6rem',
  },
  {
    name: 'F-Fondeo',
    key: 'fundingDate',
    width: '6rem',
  },
  {
    name: 'F-Documentos',
    key: 'documentsDate',
    width: '6rem',
  },
  {
    name: 'Tipo',
    key: 'commissionType',
    width: '6rem',
  },
  {
    name: 'Ultimo Pago',
    key: 'lastPayment',
    width: '6rem',
  },
]
