import styled from 'styled-components'

export const StyledPrintContainer = styled.div`
  position: relative;
  padding: 1rem;
  width: calc(100% - 4rem);
  margin: auto;
  background-color: white;

  @media screen {
    border: 1px solid gray;
  }
`
